import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import Step from './Step';

const COMPONENT_CNAME = 'stepper';
const SIZE_SMALL = 'sm';

const propsTypes = {
  currentStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
  steps: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        complete: PropTypes.bool,
        incomplete: PropTypes.bool,
        onClick: PropTypes.func,
        href: PropTypes.string,
      })
    ),
  ]),
};

const defaultProps = {
  currentStep: 0,
  size: null,
  steps: null,
};

const defaultStep = {
  title: '',
};

const Stepper = (props) => {
  const normalizeSteps = ({ steps: propSteps, children, currentStep }) => {
    let steps = propSteps;

    if (children && children.length) {
      steps = React.Children.toArray(children).map((child) => {
        let props = Object.assign({}, defaultStep, child.props);
        delete props.active;
        delete props.index;
        return props;
      });
    } else {
      steps = steps.map((s) => {
        const step = typeof s === 'string' ? { title: s } : s;
        return Object.assign({}, defaultStep, step);
      });
    }

    const stepNodes = createChildrenFromSteps(steps, currentStep);

    return stepNodes;
  };

  const createChildrenFromSteps = (steps, currentStep) =>
    steps.map((step, index) => {
      const props = Object.assign(
        {
          index,
          active: currentStep === index,
        },
        step
      );

      if (props.index < currentStep && !props.complete && !props.incomplete) {
        props.complete = true;
      }

      return <Step {...props} key={uuid()} />;
    });

  const getActiveStepTitle = (steps) => {
    const step = steps.find((step) => step.props.active) || steps[0];

    return step ? step.props.title || step.props.children : '';
  };

  const render = ({ size, ...props }) => {
    const steps = normalizeSteps(props);

    const className = `${COMPONENT_CNAME} ${
      size === SIZE_SMALL ? `${COMPONENT_CNAME}-${SIZE_SMALL}` : ''
    }`;

    const activeStep = (
      <span className="active-step title-text">
        {getActiveStepTitle(steps)}
      </span>
    );

    return (
      <div className={className}>
        <ol className="steps">{steps}</ol>
        {activeStep}
      </div>
    );
  };

  return render(props);
};

Stepper.propsTypes = propsTypes;
Stepper.defaultProps = defaultProps;
Stepper.SIZE_SMALL = SIZE_SMALL;

export default Stepper;
