import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useOnClickOutside } from '../Hooks/Hooks';

const propTypes = {
  isOpen: PropTypes.bool,
  placement: PropTypes.string,
  headerText: PropTypes.string,
  maxWidth: PropTypes.string,
};

const defaultProps = {
  placement: 'left',
};

function SlideOutContent({
  children,
  isOpen,
  placement,
  maxWidth,
  toggleClose,
}) {
  const slideOutRef = useRef();

  useOnClickOutside(slideOutRef, () => toggleClose());

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        toggleClose(false);
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEsc);

      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }
  }, [isOpen]);

  return (
    <CSSTransition
      in={isOpen}
      appear={true}
      timeout={250}
      classNames={'slide-out-content--' + placement}
      className={'slide-out-content slide-out-content--' + placement}
      unmountOnExit={true}
    >
      <div style={maxWidth && { maxWidth: `${maxWidth}` }} ref={slideOutRef}>
        <div
          onClick={toggleClose}
          className="slide-out-close icon prism-icon-cross d-flex justify-content-center align-items-center"
        />
        <div className="slide-out-body">{children}</div>
      </div>
    </CSSTransition>
  );
}

function SlideOutCloseBtn({ toggleClose }) {
  return (
    <div
      onClick={toggleClose}
      className="slide-out-close icon prism-icon-cross"
    />
  );
}

SlideOutContent.CloseBtn = SlideOutCloseBtn;
SlideOutContent.propTypes = propTypes;
SlideOutContent.defaultProps = defaultProps;

export default SlideOutContent;
