import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination } from 'react-table';
import { columnFactory } from '../utils';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableTop,
  TableBottom,
  TableFoot,
} from './';

const propTypes = {
  caption: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  condensed: PropTypes.bool,
  contain: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  headerStyle: PropTypes.oneOf(['light', 'dark', 'color']),
  headerSticky: PropTypes.bool,
  height: PropTypes.number,
  pagination: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      label: PropTypes.string,
      page: PropTypes.number,
      pageSize: PropTypes.number,
      pageSizes: PropTypes.arrayOf(PropTypes.number),
    }),
  ]),
  responsive: PropTypes.bool,
  scrollable: PropTypes.bool,
  singleSort: PropTypes.bool,
  sortable: PropTypes.bool,
  manualSortBy: PropTypes.bool,
  onBeforeSort: PropTypes.func,
  onAfterSort: PropTypes.func,
  sortBy: PropTypes.object,
  footer: PropTypes.shape({
    children: PropTypes.node,
    className: PropTypes.string,
  }),
  width: PropTypes.number,
};

const defaultProps = {
  columns: [],
  data: [],
  condensed: false,
  headerSticky: false,
  contain: false,
  responsive: false,
  scrollable: false,
  singleSort: false,
  sortable: false,
  manualSortBy: false,
  footer: {},
  sortBy: {},
};

const findSortable = (columns = [], defaultSortableColumns = false) => {
  let canSort = null;

  if (columns.columns) {
    canSort = findSortable(columns.columns);
  } else {
    canSort =
      columns.find((c) =>
        typeof c === 'object' && 'sortable' in c
          ? c.sortable
          : defaultSortableColumns
      ) || canSort;
  }

  return canSort;
};

const DataTable = ({
  caption,
  columns,
  condensed,
  contain,
  data,
  headerStyle,
  headerSticky,
  height,
  pagination,
  responsive,
  scrollable,
  singleSort,
  sortable: defaultSortableColumns,
  manualSortBy,
  sortBy,
  onBeforeSort,
  onAfterSort,
  footer,
  width,
}) => {
  const canSort = findSortable(columns, defaultSortableColumns);

  const initialSortBy = sortBy.id
    ? sortBy
    : canSort && canSort.accessor
    ? {
        sortBy: {
          id: canSort.accessor,
          desc: false,
        },
      }
    : {};

  const initialPagination = pagination
    ? {
        pageSize: pagination.pageSize || 25,
        pageIndex: pagination.pageIndex || 0,
      }
    : {};

  const initialState = Object.assign(
    {},
    { sortBy: [initialSortBy] },
    initialPagination
  );

  const {
    flatHeaders,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    page,
    pageCount,
    prepareRow,
    rows,
    state,
    setSortBy,
    setPageSize,
  } = useTable(
    {
      columns: useMemo(
        () =>
          columns.map(
            columnFactory({
              sortable: defaultSortableColumns,
              onBeforeSort,
              onAfterSort,
            })
          ),
        [columns]
      ),
      data: useMemo(() => data, [data]),
      defaultCanSort: false,
      disableSortRemove: true,
      manualSortBy: manualSortBy,
      initialState,
    },
    useSortBy,
    usePagination
  );

  const tableTopProps = {
    columns: flatHeaders,
    setSortBy,
    setPageSize,
    singleSort,
    sortBy: state.sortBy[0] || {},
    pagination: pagination && {
      label: pagination.label,
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      pageSizes: pagination.pageSizes,
    },
  };

  const containerProps = {
    contain,
    width,
    height,
    isScrollable: !!scrollable,
  };

  const tableProps = {
    getTableProps,
    headerStyle,
    condensed,
    headerSticky,
    responsive,
  };

  const tableBottomProps = {
    pagination: pagination && {
      pageIndex: state.pageIndex,
      pageCount,
    },
    gotoPage,
  };

  return (
    <>
      <TableTop {...tableTopProps} />
      <TableContainer {...containerProps}>
        {caption ? <caption>{caption}</caption> : null}
        <Table {...tableProps}>
          <TableHead headerGroups={headerGroups} singleSort={singleSort} />
          <TableBody
            rows={page || rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
          />
          <TableFoot {...footer} />
        </Table>
      </TableContainer>
      <TableBottom {...tableBottomProps} />
    </>
  );
};

DataTable.propTypes = propTypes;
DataTable.defaultProps = defaultProps;
DataTable.displayName = 'DataTable';

export default DataTable;
