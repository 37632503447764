import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Tippy from '@tippyjs/react'

import {
  CENTER,
  LEFT,
  RIGHT,
  CNAME_PANEL,
  CNAME_MASK,
} from '../CONSTANTS';
import { useActionsContext } from '../Context';
import PanelTrigger from './PanelTrigger';

const propTypes = {
  align: PropTypes.oneOf([CENTER, LEFT, RIGHT]),
  className: PropTypes.string,
  children: PropTypes.node,
  showPanel: PropTypes.bool,
  panelRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

const defaultProps = {
  align: LEFT,
};

const Panel = ({
  align,
  className: propClassName,
  children,
  showPanel: propShowPanel,
  panelRef: propPanelRef,
}) => {
  const [{
    showPanel: ctxShowPanel,
    panelRef: ctxPanelRef,
  }] = useActionsContext();

  const showPanel = typeof propShowPanel !== 'undefined' ? propShowPanel : ctxShowPanel;
  const panelRef = typeof propPanelRef !== 'undefined' ? propPanelRef : ctxPanelRef;

  const className = classnames(CNAME_PANEL, {
    'dropdown-menu': true,
    'show': showPanel,
    ['align-center']: align === CENTER,
    ['align-left']: align === LEFT,
    ['align-right']: align === RIGHT,
  }, propClassName)

  const getPlacement = () => {
    switch (align) {
      case CENTER:
        return 'bottom';
      case LEFT:
        return 'bottom-start';
      case RIGHT:
        return 'bottom-end';
      default:
        return 'auto';
    }
  }

  const popperOptions = {
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: [CENTER, LEFT, RIGHT].includes(align) ? [
            'bottom-start',
            'bottom-end',
            'top-start',
            'top-end'
          ] : undefined,
        },
      }
    ],
  };

  return (
    <>
      <Tippy
        interactive
        offset={0}
        popperOptions={popperOptions}
        placement={getPlacement()}
        render={attrs => (
          showPanel ? (
            <div
              className={className}
              ref={panelRef}
              tabIndex={0}
              role="group"
              {...attrs}
            >{
              React.Children.toArray(children).map((child, index) => (
                <li key={`actions-panel-${index}`}>{child}</li>
              ))
            }</div>
          ) : null
        )}
        visible={!!showPanel}
      >
        <PanelTrigger />
      </Tippy>
      {showPanel && <span className={CNAME_MASK} />}
    </>
  )
}

Panel.propTypes = propTypes
Panel.defaultProps = defaultProps
Panel.displayName = 'Panel'

export default Panel
