import React from 'react';
import ReactMarkdown from 'react-markdown';

const Description = ({ description }) => {
  function formatDescription(text) {
    if (text.match(/\*.*\*/)) {
      return (
        <ReactMarkdown
          source={text}
          renderers={{
            paragraph: (props) => <div {...props} />,
          }}
        />
      );
    } else {
      return (
        <div>
          <strong>{text}</strong>
        </div>
      );
    }
  }

  return (
    <div className="mediacount-details">{formatDescription(description)}</div>
  );
};

export default Description;
