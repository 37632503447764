import React, { useEffect, useState } from 'react';
import { Image } from 'pure-react-carousel';
import { LoadingScreen } from '@prism/imageviewer';
import { getTextOverlay, isSelected, textOffSet } from './utils';
import { CATEGORIES, TIMEOUT_VALUE_IN_MS } from '../../types/constants';

const errorScreen = (category) => {
  return (
    <div className="thumbnail-not-available">
      <div className="icon-wrapper">
        {category === CATEGORIES.AUDIO ? (
          <i className="icon prism-icon-sound-off" />
        ) : (
          <i className="icon prism-icon-image" />
        )}
      </div>
    </div>
  );
};

const ThumbnailTextOverlay = (props) => {
  const {
    category,
    imageIsSelected,
    selectedFilmstripSlide,
    filmstripCarouselStore,
    prefix,
  } = props;
  return (
    <div
      className={`${prefix}-selected-text ${
        imageIsSelected ? '' : 'text-overlay-hidden'
      } ${textOffSet(selectedFilmstripSlide, filmstripCarouselStore, prefix)}`}
    >
      {getTextOverlay(category)}
    </div>
  );
};

const Thumbnail = (props) => {
  const {
    updateCurrentSlide,
    selectedFilmstripSlide,
    mediaAsset,
    disabled,
    filmstripCarouselStore,
    index,
    prefix,
  } = props;
  const [imageIsLoaded, setImageIsLoaded] = useState(undefined);
  const [maxLoadTimeExceeded, setMaxLoadTimeExceeded] = useState(false);

  useEffect(() => {
    setTimeout(() => timeout(setMaxLoadTimeExceeded), TIMEOUT_VALUE_IN_MS);
  });

  const timeout = (setMaxLoadTimeExceeded) => {
    if (imageIsLoaded === false || imageIsLoaded === undefined) {
      setMaxLoadTimeExceeded(true);
    }
  };

  const ThumbnailIconOverlay = () => {
    if (
      (maxLoadTimeExceeded === true && imageIsLoaded === undefined) ||
      imageIsLoaded === false
    ) {
      return errorScreen(mediaAsset.category);
    } else if (maxLoadTimeExceeded === false && imageIsLoaded === undefined) {
      return <LoadingScreen classNamePrefix={prefix} text={''} />;
    }
    return <div></div>;
  };

  return (
    <>
      <ThumbnailIconOverlay />
      <Image
        className={`${prefix}-image`}
        disabled={disabled}
        hasMasterSpinner={false}
        src={mediaAsset.src.thumbnail}
        onClick={() => {
          updateCurrentSlide(index, ['imageViewerCarouselStore']);
        }}
        onLoad={() => {
          setImageIsLoaded(true);
        }}
        onError={() => {
          setImageIsLoaded(false);
        }}
      />
      <div
        className={`${
          isSelected(selectedFilmstripSlide, index) ? `${prefix}-selected` : ''
        }`}
        id={`${prefix}-${index}`}
      >
        <ThumbnailTextOverlay
          {...{ imageIsLoaded, selectedFilmstripSlide, filmstripCarouselStore }}
          category={mediaAsset.category}
          prefix={prefix}
          imageIsSelected={isSelected(selectedFilmstripSlide, index)}
        />
      </div>
    </>
  );
};

export default Thumbnail;
