import React, { useState, Fragment, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import SlideOutWrapper from './SlideOutWrapper';
import SlideOutContent from './SlideOutContent';
import SlideOutTrigger from './SlideOutTrigger';
import SlideOutHeader from './SlideOutHeader';

const propTypes = {
  open: PropTypes.bool,
  placement: PropTypes.string,
  id: PropTypes.string,
  triggerTag: PropTypes.string,
  triggerText: PropTypes.string,
  triggerHref: PropTypes.string,
  toggle: PropTypes.func,
  actionable: PropTypes.bool,
  headerText: PropTypes.string,
  triggerClassName: PropTypes.string,
  maxWidth: PropTypes.string,
};
const defaultProps = {
  placement: 'left',
  triggerTag: 'button',
  triggerClassName: 'btn btn-outline',
  actionable: false,
  toggle: null,
  setToggle: null,
};

function SlideOut({
  open,
  headerText,
  placement,
  children,
  triggerTag,
  triggerClassName,
  triggerText,
  triggerHref,
  actionable,
  maxWidth,
  setToggle,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useLayoutEffect(() => {
    controlledToggle(open);
  }, [open]);

  const controlledToggle = (openState) => {
    setIsOpen(openState);
  };

  const toggleOpen = () => {
    setIsOpen(true);
  };

  const toggleClose = () => {
    if (actionable && setToggle) {
      setToggle(false);
    } else {
      setIsOpen(false);
    }
    return;
  };

  return (
    <Fragment>
      <SlideOutWrapper headerText={headerText} isOpen={isOpen} />
      <SlideOutContent
        toggleClose={toggleClose}
        maxWidth={maxWidth && maxWidth}
        isOpen={isOpen}
        placement={placement}
      >
        {headerText && <h1>{headerText}</h1>}
        {children}
      </SlideOutContent>
      {actionable || (
        <SlideOutTrigger
          tag={triggerTag}
          text={triggerText}
          className={triggerClassName}
          onClick={toggleOpen}
          href={triggerHref}
        />
      )}
    </Fragment>
  );
}

SlideOut.Header = SlideOutHeader;

SlideOut.propTypes = propTypes;
SlideOut.defaultProps = defaultProps;

export default SlideOut;
