import Nav from './Nav';
import NavItem from './NavItem';
import NavLink from './NavLink';

Nav.Item = NavItem;
Nav.Link = NavLink;

export { NavItem, NavLink };

export default Nav;
