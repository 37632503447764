/**
 * Merges the `risky` object onto the `safe` object where only the key exists
 * in the `safe` object.
 *
 * @param {object} risky
 * @param {object} safe Object used to match keys
 * @param {object} initial Object onto which the risky object will be
 *  applied. Defaults to the `safe` object supplied. To only get an intersection
 *  of the two objects, provide an empty object (`{}`) as the initial
 * @returns {object} Blended object of the safe and risky objects
 */
export const sanitize = (risky = {}, safe = {}, initial = safe) => {
  const safeKeys = Object.keys(safe);
  return Object.keys(risky)
    .filter((key) => safeKeys.includes(key)) // only keys from `risky` which exist in `safe`
    .reduce(
      (acc, key) => {
        if (risky[key] !== 'undefined') {
          acc[key] = risky[key]; // copy keys
        }
        return acc;
      },
      { ...initial }
    );
};

/**
 * Binds the provided function to the window's click event. Any click that
 * bubbles to the window will trigger this function. Trap any click event from
 * within a container and all others that reach the window object will be events
 * which a 'outside'
 * @param {function} fn callback function
 */
export const bindOutsideClick = (fn) => {
  fn && window && window.addEventListener('click', fn);
};

/**
 * Removes the bound callback function. This needs to be a reference to the
 * callback function provided to a previous `bindOutsideClick` call.
 * @param {function} fn callback function
 */
export const unbindOutsideClick = (fn) => {
  fn && window && window.removeEventListener('click', fn);
};

/**
 * Places the callback function in the next available rendering queue position
 * @param {function} fn callback to be called on next 'frame'
 */
export const nextFrame = (fn) => {
  if (window && window.requestAnimationFrame) {
    window.requestAnimationFrame(fn);
  } else {
    setTimeout(fn, 0);
  }
};
