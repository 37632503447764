import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

const defaultProps = {};

function SlideOutHeader({ children }) {
  return <div>{children}</div>;
}

SlideOutHeader.propTypes;
SlideOutHeader.defaultProps;

export default SlideOutHeader;
