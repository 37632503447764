export const clamp = (value, min, max) => {
  if (value < min) return min;
  if (value > max) return max;
  return value;
};

export const roundValueToStep = (value, step) => {
  return Math.round(value / step) * step; // Note: This does not work for small values 0.00000001. JS switches to 1e-8 notation.
};

export const valueToPercent = (value, min, max) => {
  return ((value - min) * 100) / (max - min);
};

export const percentToValue = (percent, min, max) => {
  return (max - min) * percent + min;
};

export const findClosest = (values, currentValue) => {
  const { index: closestIndex } = values.reduce((acc, value, index) => {
    const distance = Math.abs(currentValue - value);

    if (acc === null || distance < acc.distance || distance === acc.distance) {
      return {
        distance,
        index,
      };
    }

    return acc;
  }, null);
  return closestIndex;
};

export const hasTypeArrayOfLength2 = (props, propName, componentName) => {
  const arrayPropLength = props[propName].length;

  if (arrayPropLength !== 2) {
    return new Error(
      `Invalid array length ${arrayPropLength} (expected ${expectedLength}) for prop ${propName} supplied to ${componentName}. Validation failed.`
    );
  }
};

export const setValueIndex = ({ values, source, newValue, index }) => {
  if (values[index] === newValue) {
    return source;
  }

  const output = [...values];
  output[index] = newValue;
  return output;
};

export const asc = (a, b) => a - b;
