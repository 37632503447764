import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from '@prism/spinner';
import { IconSvg } from '@prism/icon';

import {
  CNAME,
  COLOR_DARK,
  COLOR_LIGHT,
  STATE_STOPPED,
  STATE_RUNNING,
  STATE_TIMED_OUT,
  STATE_ANIMATED,
  STATE_STATIC,
  LABEL,
  LABEL_ADA,
} from '../CONSTANTS';

const propTypes = {
  adaTimeoutAfter: PropTypes.number,
  adaTimeoutGlyph: PropTypes.string,
  adaTimeoutLabel: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf([COLOR_DARK, COLOR_LIGHT]),
  glyph: PropTypes.string,
  inline: PropTypes.bool,
  isStatic: PropTypes.bool,
  label: PropTypes.string,
  showMask: PropTypes.bool,
  size: PropTypes.string,
}

const defaultProps = {
  adaTimeoutAfter: 5000, // ms
  adaTimeoutGlyph: 'clock',
  adaTimeoutLabel: LABEL_ADA,
  className: '',
  color: COLOR_DARK,
  glyph: 'clock',
  inline: false,
  isStatic: false,
  label: LABEL,
  showMask: false,
}

const Panel = ({
  adaTimeoutAfter,
  adaTimeoutGlyph,
  adaTimeoutLabel,
  className: propClassName,
  color,
  glyph,
  inline,
  isStatic,
  label,
  size,
  showMask,
}) => {
  const dialogRef = useRef(null);
  const timedOutTimerRef = useRef(null);
  const [state, setState] = useState(STATE_STOPPED);
  const [content, setContent] = useState({ glyph, label })

  const clearTimers = () => {
    if (timedOutTimerRef.current) {
      clearTimeout(timedOutTimerRef.current)
    }
    timedOutTimerRef.current = null;
  }

  const startTimeoutTimer = () => {
    clearTimers();

    if (adaTimeoutAfter && adaTimeoutAfter > 0) {
      setState(STATE_RUNNING);

      timedOutTimerRef.current = setTimeout(() => {
        setState(STATE_TIMED_OUT);
      }, adaTimeoutAfter)
    }
  }

  const updateContent = () => {
    const newContent = {
      glyph: state.length === STATE_TIMED_OUT ? adaTimeoutGlyph : glyph,
      label: state.length === STATE_TIMED_OUT ? adaTimeoutLabel : label,
    };
    if (newContent.glyph !== content.glyph || newContent.label !== content.label) {
      setContent(newContent)
    }
  }

  useEffect(() => {
    updateContent()
  }, [setState])

  useEffect(() => {
    if (adaTimeoutAfter && state !== STATE_TIMED_OUT) {
      startTimeoutTimer();
    }

    return () => {
      clearTimers();
    };
  }, [adaTimeoutAfter, startTimeoutTimer, clearTimers, updateContent])

  const panelClassName = classnames(propClassName, {
    [`${CNAME}-panel`]: true,
    [`${CNAME}-panel-dark`]: color === COLOR_DARK,
    [`${CNAME}-panel-light`]: color === COLOR_LIGHT,
    [`${CNAME}-panel-${size}`]: size,
  });

  const maskClassName = classnames({
    [`${CNAME}-mask`]: true,
    [`${CNAME}-mask-dark`]: color === COLOR_LIGHT,
    [`${CNAME}-mask-light`]: color === COLOR_DARK,
  });

  const getIconHeight = (size) => {
    const knownValues = Object.freeze({
      xs: 15,
      sm: 19,
      md: 25,
      lg: 33,
      xl: 47,
      xxl: 47,
    });

    return (knownValues[size] || knownValues.xs) + 'px';
  }

  const getGraphic = () => {
    const height = getIconHeight(size);

    if (state === STATE_TIMED_OUT) {
      return <IconSvg glyph={adaTimeoutGlyph} color={null} height={height} />
    }

    if (isStatic) {
      return <IconSvg glyph={content.glyph} color={null} height={height} />
    }

    return <Spinner color={null} size={size} />
  }

  const getLabel = () => {
    if (inline) { return }

    const label = state === STATE_TIMED_OUT ? adaTimeoutLabel : content.label;

    if (label) {
      return <span className={`${CNAME}-label`}>{label}</span>
    }
  }

  return (
    <>
      { showMask && <span className={maskClassName} /> }
      { state && <span className={panelClassName}>
        {getGraphic()}
        {getLabel()}
      </span>}
    </>
  );
}

Panel.propTypes = propTypes
Panel.defaultProps = defaultProps
Panel.displayName = 'Panel'

export default Panel
