import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CNAME = 'table';

const propTypes = {
  headerStyle: PropTypes.oneOf(['light', 'dark', 'color']),
  condensed: PropTypes.bool,
  responsive: PropTypes.bool,
};
const defaultProps = {};

const Table = ({
  getTableProps,
  headerStyle,
  condensed,
  headerSticky,
  responsive,
  ...props
}) => {
  const className = classNames({
    [CNAME]: CNAME,
    [`${CNAME}-hover`]: true,
    [`${CNAME}-sm`]: !!condensed,
    [`${CNAME}-head-${headerStyle}`]: headerStyle,
    [`${CNAME}-head-sticky`]: !!headerSticky,
    [`${CNAME}-responsive`]: !!responsive,
  });

  return <table className={className} {...getTableProps()} {...props} />;
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;
Table.displayName = 'Table';

export default Table;
