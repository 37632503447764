import React from 'react';
import { ButtonBack } from 'pure-react-carousel';
import { MultiMediaViewerConsumer } from '@prism/multimediaviewer';
import {
  IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS,
  PREVIOUS,
} from '../../types/constants';
import { calculateCurrentSlide } from './utils';

const BackButton = React.forwardRef(
  (
    { carouselStore, controlsHidden, multipleMediaAssetsExist, onClick },
    ref
  ) => (
    <div
      className={`imageviewer-prev ${
        controlsHidden && IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS
      }`}
      onClick={onClick}
    >
      {multipleMediaAssetsExist && (
        <MultiMediaViewerConsumer>
          {({ updateCurrentSlide, currentSlide }) => (
            <ButtonBack
              className="no-button-styling"
              onClick={() =>
                updateCurrentSlide(
                  calculateCurrentSlide(carouselStore, PREVIOUS)
                )
              }
            >
              <span className="icon prism-icon-chevron-left-light" />
            </ButtonBack>
          )}
        </MultiMediaViewerConsumer>
      )}
    </div>
  )
);

export default BackButton;
