import React from 'react';
import PropTypes from 'prop-types';

import TableRow from './TableRow';
import TableHeadCell from './TableHeadCell';

const CNAME = 'table-head';

const propTypes = {
  headerGroups: PropTypes.array,
  singleSort: PropTypes.bool,
};

const defaultProps = {
  headerGroups: [],
  singleSort: false,
};

const TableHead = ({ headerGroups, singleSort }) => {
  const handleHeadCellClick = (header) => (e) => {};

  const className = `${CNAME}`;

  return (
    <thead className={className}>
      {headerGroups.map((headerGroup, i) => (
        <TableRow key={`${CNAME}-tr-${i}`} headerGroup={headerGroup}>
          {headerGroup.headers.map((column, j) => {
            const {
              canSort,
              headerClassName,
              onBeforeSort,
              onAfterSort,
            } = column;

            return (
              <TableHeadCell
                key={`${CNAME}-tr-${i}-th-${j}`}
                onClick={handleHeadCellClick}
                canSort={canSort && !singleSort}
                isSorted={canSort && column.isSorted}
                isSortedDesc={canSort && column.isSortedDesc}
                sortByProps={canSort && column.getSortByToggleProps()}
                onBeforeSort={onBeforeSort}
                onAfterSort={onAfterSort}
                className={headerClassName}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </TableHeadCell>
            );
          })}
        </TableRow>
      ))}
    </thead>
  );
};

TableHead.propTypes = propTypes;
TableHead.defaultProps = defaultProps;
TableHead.displayName = 'TableHead';

export default TableHead;
