import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './AccordionItem';
import { SelectableContext } from './context/SelectableContext';
import AccordionItemProvider from './AccordionItemProvider';

const propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  onClear: PropTypes.func,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  onSubmit: PropTypes.func,
};

function AccordionInput({
  id,
  children,
  onClear,
  title,
  name,
  onChange,
  clearable,
  onSubmit,
}) {
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        onChange: onChange && onChange,
      });
    });
  };

  const formEl = useRef(null);
  const selectContext = useContext(SelectableContext);

  const { ctxClear } = selectContext || false;

  const handleClear = (e) => {
    onClear & onClear;
    e && formEl.current.reset();
  };

  useEffect(() => {
    renderChildren();
  }, []);

  const accordionItemProps = {
    onClear: handleClear,
    onChange: onChange && onChange,
    defaultOpen: true,
    triggerTag: 'div',
    variant: 'filter',
    title: title,
    clearable: clearable,
    deselect: ctxClear,
    children: renderChildren(),
  };

  const formProps = { id: id && id, onSubmit, name: name || title };

  return (
    <AccordionItemProvider>
      <form {...formProps} ref={formEl}>
        <AccordionItem {...accordionItemProps} />
      </form>
    </AccordionItemProvider>
  );
}

AccordionInput.propTypes = propTypes;

export default AccordionInput;
