import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const rangeLength = 5;

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

const defaultProps = {
  currentPage: 1,
  totalPages: 1,
};

const Pagination = ({ currentPage, totalPages, onChange, ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(currentPage);
  useEffect(() => {
    if (currentPage !== currentIndex) {
      setCurrentIndex(currentPage);
    }
  }, [currentPage]);

  const updateIndex = (newIndex) => {
    let updatedIndex = {
      prevIndex: currentIndex,
      index: newIndex,
    };

    setCurrentIndex(newIndex);
    onChange && onChange(updatedIndex);
  };

  const handleFirstPageClick = () => {
    updateIndex(1);
  };

  const handlePrevPageClick = () => {
    updateIndex(currentIndex - 1);
  };

  const handleNextPageClick = () => {
    updateIndex(currentIndex + 1);
  };

  const handlePageClick = (page) => {
    updateIndex(page);
  };

  const getRange = (currentPage) => {
    let leftBound = 1;
    let rightBound = 1;

    if (totalPages <= rangeLength) {
      rightBound = totalPages;
    } else {
      const leftOffset = Math.max(1, currentPage - Math.floor(rangeLength / 2));
      const rightOffset = Math.min(totalPages, leftOffset + rangeLength - 1);

      if (rightOffset == totalPages && totalPages - rangeLength > 0) {
        leftBound = rightOffset - rangeLength + 1;
      } else {
        leftBound = leftOffset;
      }
      rightBound = rightOffset;
    }

    const length = rightBound - leftBound + 1;
    let returnArray = [];
    for (let i = 0; i < length; i++) {
      const pageItem = i + leftBound;

      const pageNumberClasses = classNames({
        pagination__control: true,
        'pagination__control--page': true,
        [`pagination__control--page-${pageItem}`]: true,
        selected: pageItem === currentPage,
        'd-none': totalPages == 1,
      });

      returnArray.push(
        <button
          key={pageItem}
          disabled={pageItem === currentPage}
          title={
            pageItem === currentPage
              ? 'You are on page ' + pageItem
              : 'Go to page ' + pageItem
          }
          className={pageNumberClasses}
          onClick={() => handlePageClick(pageItem)}
        >
          {pageItem}
        </button>
      );
    }
    return returnArray;
  };

  const getControlClasses = (control) => {
    let cNames = {
      'btn btn-textonly-primary btn-icon-only btn-sm': true,
      pagination__control: true,
      'd-none': totalPages == 1,
    };

    if (control) {
      cNames[`pagination__control--${control}`] = true;
    }

    return classNames(cNames);
  };

  return (
    <div className="pagination">
      <button
        disabled={currentIndex === 1}
        onClick={handleFirstPageClick}
        className={getControlClasses('start')}
        title="Go to page 1"
      >
        <i className="icon prism-icon-back-to-start"></i>
      </button>
      <button
        disabled={currentIndex === 1}
        onClick={handlePrevPageClick}
        className={getControlClasses('prev')}
        title="Go to previous page"
      >
        <i className="icon prism-icon-chevron-left-light"></i>
      </button>
      {getRange(currentIndex)}
      <button
        disabled={currentIndex === totalPages}
        onClick={handleNextPageClick}
        className={getControlClasses('next')}
        title="Go to next page"
      >
        <i className="icon prism-icon-chevron-right-light"></i>
      </button>
    </div>
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
