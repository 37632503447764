import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

const propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  indeterminate: PropTypes.any,
  value: PropTypes.any,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
};
const defaultProps = {
  type: 'checkbox',
  innerRef: null,
  required: null,
  id: uuid(),
  checked: false,
  disabled: null,
  label: '',
  name: '',
  value: '',
  className: null,
  cssModule: null,
  indeterminate: null,
  readonly: null,
  onChange: () => {},
};

const Checkbox = ({
  required,
  id,
  checked,
  disabled,
  label,
  name,
  type,
  value,
  className,
  cssModule,
  indeterminate,
  readonly,
  onChange,
  innerRef,
  ...props
}) => {
  const classes = classNames(
    className,
    indeterminate && `${type}-indeterminate`
  );

  let targetObj = {
    id: id,
    checked: checked,
    disabled: disabled,
    label: label,
    name: name,
    type: type,
    value: value,
    indeterminate: indeterminate,
  };

  return (
    <div className="checkbox--custom">
      <label htmlFor={id} className="itemCol itemContent checkbox-label">
        <input
          id={id}
          name={name}
          required={required}
          type={type}
          className={classes}
          defaultChecked={checked}
          disabled={disabled}
          value={value}
          indeterminate={indeterminate}
          ref={innerRef}
          onChange={(e) => onChange({ ...e, targetObj })}
          {...props}
        />

        <span className="checkbox-label">{label}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
