import React from 'react';
import PropTypes from 'prop-types';
import { SearchableProvider } from './context/SearchableContext';
import SearchableList from './SearchableList';
import SearchableSearch from './SearchableSearch';

function Searchable({ children, ...searchProps }) {
  return <SearchableProvider>{children}</SearchableProvider>;
}

Searchable.List = SearchableList;
Searchable.Search = SearchableSearch;

export default Searchable;
