import SlideOut from './SlideOut';
import SlideOutContent from './SlideOutContent';
import SlideOutHeader from './SlideOutHeader';
import SlideOutTrigger from './SlideOutTrigger';
import SlideOutWrapper from './SlideOutWrapper';

SlideOut.Content = SlideOutContent;
SlideOut.Header = SlideOutHeader;
SlideOut.Trigger = SlideOutTrigger;
SlideOut.Wrapper = SlideOutWrapper;

export { SlideOutContent, SlideOutHeader, SlideOutTrigger, SlideOutWrapper };

export default SlideOut;
