import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NS } from '../toolkit';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  float: PropTypes.string,
  size: PropTypes.string,
  AriaLabel: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  href: PropTypes.string,
};

const defaultProps = {
  tag: 'a',
  float: 'left',
  children: null,
};

function Anchor(props) {
  const {
    children,
    className,
    icon,
    href,
    float,
    AriaLabel,
    size,
    color,
    innerRef,
    tag: Tag,
    ...attributes
  } = props;

  const posLeft = float && float === 'left';
  const posRight = float && float === 'right';

  const classes = classNames(
    className,
    `${NS}link`,
    size ? `${NS}link-${size}` : `${NS}link`,
    color ? `${NS}link-${color}` : null,
    icon ? `${NS}link__has-icon` : null
  );

  const iconClasses = classNames(
    className,
    'icon',
    `prism-icon-${icon}`,
    `icon__${float}`
  );

  return (
    <Tag {...attributes} href={href} ref={innerRef} className={classes}>
      {icon && posLeft ? <span className={iconClasses} /> : null}
      {children && typeof children === 'string' ? (
        <span className={`${NS}link-text`}>{children}</span>
      ) : (
        children
      )}
      {icon && posRight ? <span className={iconClasses} /> : null}
    </Tag>
  );
}

Anchor.propTypes = propTypes;
Anchor.defaultProps = defaultProps;
export default Anchor;
