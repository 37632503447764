import { createRef, useEffect, useState } from 'react';

export const initialState = {
  instance: {},
  zoomLevel: 1,
  zoomMin: 1,
  zoomMax: 3,
};

const useImageViewer = (defaultState = {}) => {
  const [instanceRef, setInstanceRef] = useState(createRef(null));

  const [state, setState] = useState({
    ...initialState,
    instance: instanceRef,
  });

  useEffect(() => {
    const { instance, zoomLevel } = state;
    if (instance.current) {
      const currentZoom = instance.current.getZoom();
      if (currentZoom !== zoomLevel) {
        instance.current.zoomTo(zoomLevel);
      }
    }
  }, [state]);

  const setInstance = (instance) => {
    state.instance.current = instance;
    setState({
      ...state,
      zoomLevel: instance.getZoom(),
    });
  };

  const setZoomLevel = (zoomLevel = 1) => {
    const { zoomMin, zoomMax } = state;

    const newZoomLevel = Math.min(Math.max(zoomMin, zoomLevel), zoomMax);

    setState({
      ...state,
      zoomLevel: newZoomLevel,
    });
  };

  return [
    state,
    {
      setInstance,
      setZoomLevel,
    },
  ];
};

export default useImageViewer;
