import React from 'react';
import PropTypes from 'prop-types';

const CNAME = 'table-row';

const propTypes = {
  isResponsive: PropTypes.bool,
};

const defaultProps = {};

const TableRow = ({ headerGroup, row, children }) => {
  const className = `${CNAME}`;
  const props =
    (headerGroup && headerGroup.getHeaderGroupProps()) ||
    (row && row.getRowProps()) ||
    {};

  return (
    <tr className={className} {...props}>
      {children}
    </tr>
  );
};

TableRow.propTypes = propTypes;
TableRow.defaultProps = defaultProps;
TableRow.displayName = 'TableRow';

export default TableRow;
