import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';
import Icon from '@prism/icon';
import { titleCase } from '../../Utilities/utils';

const CNAME = 'table-single-sort';

const propTypes = {
  label: PropTypes.string,
  columns: PropTypes.array,
  sortBy: PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool,
  }),
};

const defaultProps = {
  label: 'Sort by',
  columns: [],
  sortBy: {},
};

const SingleSort = ({ columns, label, sortBy, setSortBy }) => {
  const [sortOpen, setSortOpen] = React.useState(false);

  const sortableFields = columns
    .filter((col) => {
      return col.sortable && col.canSort;
    })
    .map((col) => ({
      id: col.id,
      label: col.Header,
    }));

  if (!sortableFields.length) {
    // nothing to see here
    return null;
  }

  const handleTriggerClick = (e) => {
    setSortOpen(true);
  };

  const handleOverlayClick = (e) => {
    setSortOpen(false);
  };

  const handleSortClick = (col, desc) => (e) => {
    setSortOpen(false);

    if (setSortBy) {
      setSortBy([{ id: col, desc: !!desc }]);
    }
  };

  const className = `${CNAME} sortControls popup-container`;

  const popupOverlayProps = {
    className: `popup-overlay${sortOpen ? ' open' : ''}`,
    onClick: handleOverlayClick,
  };

  const popupProps = {
    className: `popup${sortOpen ? ' open' : ''}`,
  };

  return (
    <div className={className}>
      <span className="mr-2">{label}</span>
      <Button actionLink onClick={handleTriggerClick}>
        {sortBy.label ||
          (columns.find((col) => col.id === sortBy.id) || {}).Header ||
          titleCase(sortBy.id)}
        <i
          className={`btn-icon__right icon prism-icon-arrow-${
            sortBy.desc ? 'down' : 'up'
          }`}
        />
      </Button>
      <div {...popupOverlayProps} />
      <div {...popupProps}>
        {sortableFields.map(({ id, label }) => {
          return (
            <div key={`table-sortby-${id}`} className={`${CNAME}-row`}>
              <span className={`${CNAME}-row-label`}>{label}</span>
              <ButtonGroup>
                <Button
                  icononly
                  outline
                  size="sm"
                  active={sortBy.id === id && !sortBy.desc}
                  onClick={handleSortClick(id)}
                >
                  <Icon glyph="arrow-up" />
                </Button>
                <Button
                  icononly
                  outline
                  size="sm"
                  active={sortBy.id === id && sortBy.desc}
                  onClick={handleSortClick(id, true)}
                >
                  <Icon glyph="arrow-down" />
                </Button>
              </ButtonGroup>
            </div>
          );
        })}
      </div>
    </div>
  );
};

SingleSort.propTypes = propTypes;
SingleSort.defaultProps = defaultProps;

export default SingleSort;
