import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, NavItem } from '@prism/navigation';
import ErrorBoundary from '../Utilities/ErrorBoundary';

const propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  active: false,
  disabled: false,
  href: null,
  id: null,
  icon: null,
  label: null,
  onClick: null,
};

class TabItem extends React.Component {
  constructor(props) {
    super(props);

    this.activeItem = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { onClick } = this.props;

    if (onClick) {
      onClick(e);
    }
  }

  componentDidUpdate() {
    if (this.activeItem.current) {
      const item = this.activeItem.current;
      let parentNode = item.parentNode;

      while (parentNode && !parentNode.classList.contains('tab-bar')) {
        parentNode = parentNode.parentNode;
      }

      const itemRect = item.getBoundingClientRect();
      const parentRect = parentNode.getBoundingClientRect();

      if (itemRect.left - parentRect.left < 0) {
        parentNode.scrollLeft += itemRect.left - parentRect.left;
      } else if (itemRect.right > parentRect.right) {
        parentNode.scrollLeft += itemRect.right - parentRect.right;
      }
    }
  }

  render() {
    const { id, label, icon, active, disabled, href } = this.props;

    if (!label && !icon) {
      throw new Error(
        "Error: TabItem requires at least one of the following properties: 'label', 'icon'."
      );
    }

    const ref = active ? this.activeItem : null;

    return (
      <ErrorBoundary>
        <NavItem disabled={!!disabled}>
          <NavLink
            id={id}
            disabled={!!disabled}
            href={href}
            className={classNames('nav-link', { active: active })}
            onClick={this.handleClick}
            innerRef={ref}
          >
            {icon ? <i className={`icon prism-icon-${icon}`} /> : ''}
            {label ? <span className="nav-link-label">{label}</span> : ''}
          </NavLink>
        </NavItem>
      </ErrorBoundary>
    );
  }
}

TabItem.propTypes = propTypes;
TabItem.defaultProps = defaultProps;

export default TabItem;
