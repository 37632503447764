import React from 'react';
import PropTypes from 'prop-types';

const COMPONENT_CNAME = 'prism-indicator--flag';

const TYPE_RIBBON = 'TYPE_RIBBON';
const TYPE_CORNER = 'TYPE_CORNER';

const LABEL_NEW = 'NEW';
const LABEL_BETA = 'BETA';
const LABEL_UPDATED = 'UPDATED';
const LABEL_CERTIFIED = 'CERTIFIED';
const LABEL_SPECIAL = 'SPECIAL';
const LABEL_REDUCED = 'REDUCED';
const LABEL_SALVAGE = 'SALVAGE';
const LABEL_AS_IS = 'AS-IS';
const LABEL_PRIOR = 'PRIOR';
const LABEL_CUSTOM = 'CUSTOM PLACEHOLDER';

const FLAG_CANADA =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAMAAADHqI+lAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABBVBMVEX+AAL+AQP/AAD4FBX86+v//////v78+Pj8+Pj//v786+v4FBX+AQP+AQP4FBX86+r8+fnxeHjxeHj8+fn86ur4FBX+AQP+AQP/AAD4FBb86Oj67Ozxw8P3Cwv3Cwvxw8P67Ov76Oj4FBb+AQP/AAD4FRb56envMDH1Fxj6ExX6ExX1FxjvMDH56en4FRb/AAD+AAL+AQP/AAD4ExT78O/zaWr8AAD/AgL/AgL9AADzaWr77+/4ExX/AAD4FBX86ur+///wX2D0IiP0IiPwX2D+///86ur4ExX4FBX86+v+//713Nz13Nz+//786+v4FBX4FBX86+v56ur66ur86+v4FBUAAABZmXChAAAAAWJLR0RWCg3piQAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAAd0SU1FB+MFHA0tKJNsxZ0AAACASURBVAjXY2BgZGJmYWVj5+Bk5eJm4mFgYOBl4uNnFRAUEmYVEWUSAwqIS0hKScvIyskrKCoxKQMFGFVU1dQ1NLW0dXT19IFaDAyNjE1MzcwtLK2sbWzFwGbY2Ts4Ojm7uLq5gw1lZPLwZPXy9vFl9fOHCgQEsrIGBbOyhoQCBQAYUA7fLifVRgAAAEF0RVh0Y29tbWVudABDUkVBVE9SOiBnZC1qcGVnIHYxLjAgKHVzaW5nIElKRyBKUEVHIHY4MCksIHF1YWxpdHkgPSA3MAqw+vGNAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA1LTI4VDEzOjQ1OjQwLTA0OjAw5vPQ8wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wNS0yOFQxMzo0NTo0MC0wNDowMJeuaE8AAAAASUVORK5CYII=';

const labels = [
  LABEL_NEW,
  LABEL_BETA,
  LABEL_UPDATED,
  LABEL_CERTIFIED,
  LABEL_SPECIAL,
  LABEL_REDUCED,
  LABEL_SALVAGE,
  LABEL_AS_IS,
  LABEL_PRIOR,
  LABEL_CUSTOM,
];

const propTypes = {
  type: PropTypes.oneOf([TYPE_RIBBON, TYPE_CORNER]),
  label: PropTypes.oneOf(labels),
  color: PropTypes.string,
  customText: PropTypes.string,
};

const defaultProps = {
  type: TYPE_RIBBON,
  label: null,
  color: null,
  customText: '',
};

const IndicatorFlag = ({ type, label, color, customText }) => {
  let className = COMPONENT_CNAME;
  let flag = null;

  if (type === TYPE_CORNER) {
    className += ` ${COMPONENT_CNAME}--corner`;
  } else {
    className += ` ${COMPONENT_CNAME}--ribbon`;
  }

  switch (label) {
    case LABEL_NEW:
    case LABEL_BETA:
    case LABEL_UPDATED:
    case LABEL_CERTIFIED:
    case LABEL_SPECIAL:
    case LABEL_REDUCED: // overflow intentional
      className += ' info';
      break;
    case LABEL_SALVAGE:
    case LABEL_AS_IS: // overflow intentional
      className += ' danger';
      break;
    case LABEL_PRIOR:
      className += ' info has-flag';
      flag = (
        <span className="flag">
          <img src={FLAG_CANADA} />
        </span>
      );
      break;
    case LABEL_CUSTOM:
      className += color ? ` ${color}` : ' info';
      label = customText;
      break;
    default:
      className += ` ${color}`;
      break;
  }

  return (
    <div className={`${COMPONENT_CNAME}--wrapper`}>
      <div className={className}>
        {label}
        {flag}
      </div>
    </div>
  );
};

IndicatorFlag.propTypes = propTypes;
IndicatorFlag.defaultProps = defaultProps;

IndicatorFlag.COMPONENT_CNAME = COMPONENT_CNAME;
IndicatorFlag.TYPE_RIBBON = TYPE_RIBBON;
IndicatorFlag.TYPE_CORNER = TYPE_CORNER;
IndicatorFlag.LABEL_NEW = LABEL_NEW;
IndicatorFlag.LABEL_BETA = LABEL_BETA;
IndicatorFlag.LABEL_UPDATED = LABEL_UPDATED;
IndicatorFlag.LABEL_CERTIFIED = LABEL_CERTIFIED;
IndicatorFlag.LABEL_SPECIAL = LABEL_SPECIAL;
IndicatorFlag.LABEL_REDUCED = LABEL_REDUCED;
IndicatorFlag.LABEL_SALVAGE = LABEL_SALVAGE;
IndicatorFlag.LABEL_AS_IS = LABEL_AS_IS;
IndicatorFlag.LABEL_PRIOR = LABEL_PRIOR;
IndicatorFlag.LABEL_CUSTOM = LABEL_CUSTOM;

export default IndicatorFlag;
