import React from 'react';
import PropTypes from 'prop-types';

import TableDataCell from './TableDataCell';
import TableRow from './TableRow';

const CNAME = 'table-body';

const propTypes = {
  isResponsive: PropTypes.bool,
};

const defaultProps = {};

const TableBody = ({ getTableBodyProps, rows, prepareRow }) => {
  const className = `${CNAME}`;

  const props = getTableBodyProps();

  return (
    <tbody className={className} {...props}>
      {rows.map((row, i) => {
        prepareRow(row);
        const props = row.getRowProps();

        return (
          <TableRow key={`${CNAME}-tr-${i}`} {...props}>
            {row.cells.map((cell, i) => (
              <TableDataCell
                key={`${CNAME}-tr-${i}`}
                className={cell.column.className}
                headerLabel={cell.column.render('Header')}
                {...cell.getCellProps()}
              >
                {cell.render('Cell')}
              </TableDataCell>
            ))}
          </TableRow>
        );
      })}
    </tbody>
  );
};

TableBody.propTypes = propTypes;
TableBody.defaultProps = defaultProps;
TableBody.displayName = 'TableBody';

export default TableBody;
