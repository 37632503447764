import React from 'react';
import { NEXT, RIGHT_ARROW } from '../../types/constants';

export default ({ disabled, selectedFilmstripSlide, updateCurrentSlide }) => (
  <button
    onClick={() => {
      updateCurrentSlide(selectedFilmstripSlide + NEXT);
    }}
    onKeyDown={(e) => {
      if (e.keyCode === RIGHT_ARROW) {
        updateCurrentSlide(selectedFilmstripSlide + NEXT);
      }
    }}
    disabled={disabled}
    className="filmstrip-next"
    role="nextbutton"
    aria-roledescription="Next Slide in filmstrip"
  >
    <span className="icon prism-icon-chevron-right-light" />
  </button>
);
