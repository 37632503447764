import React from 'react';
import PropTypes from 'prop-types';

const Step = ({
  index,
  title,
  children,
  active,
  complete,
  incomplete,
  optional,
  href,
  onClick,
}) => {
  let props = { onClick };
  let className = 'step';

  if (active) {
    className += ' active';
  }

  if (complete) {
    className += ' complete';
  } else if (incomplete) {
    className += ' incomplete';
  }

  const optionalText = optional ? ' (optional)' : '';

  const content = children || title;

  let stepNode = (
    <span className="title-text">
      {content}
      {optionalText}
    </span>
  );

  if (href) {
    stepNode = (
      <a href={href} className="title-text">
        {stepNode}
      </a>
    );
  }

  if (href || onClick) {
    className += ' interactive';
  }

  props.className = className;

  return (
    <li {...props} data-index={index} data-label={index + 1}>
      {stepNode}
    </li>
  );
};

Step.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  active: PropTypes.bool,
  optional: PropTypes.bool,
  incomplete: PropTypes.bool,
  complete: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

Step.defaultProps = {
  active: false,
  optional: false,
  incomplete: false,
  complete: false,
  href: '',
  onClick: null,
  title: '',
};

export default Step;
