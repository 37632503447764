import React from 'react';
import { MediaAssetsType } from '../../types';
import { v4 as uuid } from 'uuid';
import {
  CarouselProvider,
  Image,
  Slide,
  Slider,
  WithStore,
} from 'pure-react-carousel';
import {
  numberOf,
  stopControlsDisplayTimerWith,
  totalSlides,
  trayProps,
} from './utils';
import { MultiMediaViewerConsumer } from '@prism/multimediaviewer';
import { Provider as ImageViewerProvider } from './ImageViewerContext';
import BackButton from './BackButton';
import NextButton from './NextButton';
import MediaCountWithCarouselState from './MediaCount';
import OSDWrapper from './OSDWrapper';
import Fyusion from './Fyusion';
import VideoViewer from './VideoViewer';
import Audio from './Audio';
import MediaNotAvailable from './MediaNotAvailable';
import LoadingScreen from './LoadingScreen';

const propTypes = { mediaAssets: MediaAssetsType };

const ImageViewer = React.forwardRef((props, ref) => {
  const {
    mediaAssets: propMediaAssets,
    mediaAssetsExist,
    carouselStore,
    multipleMediaAssetsExist,
    fullScreenHidden,
    handleFullScreenClick,
    handleExitFullScreenClick,
    customButtons,
    zoomMax,
    showFullScreenControls,
  } = props;
  const mediaAssets = propMediaAssets.map((asset) => ({
    ...asset,
    id: asset.id || uuid(),
  }));
  const imageViewerCarouselStore = carouselStore;
  const [controlsHidden, setControlsHidden] = React.useState(true);
  const controlsDisplayTimer = React.useRef();

  const stopControlsDisplayTimer = stopControlsDisplayTimerWith(
    setControlsHidden,
    controlsDisplayTimer
  );

  const handleMouseEnter = stopControlsDisplayTimer;
  const handleMouseMove = stopControlsDisplayTimer;
  const handleClick = stopControlsDisplayTimer;

  React.useEffect(() => {
    const script = document.createElement('link');
    script.href = 'https://cdn.fyusion.com/0/sdk/web/mh/viewer-0.9.0.min.css';
    document.body.appendChild(script);
  }, []);

  const MediaNotAvailableSlider = () =>
    !mediaAssetsExist && (
      <>
        <Slider>
          <Slide className="imageviewer-slide" index={0}>
            <MediaNotAvailable
              classNamePrefix="imageviewer-media"
              mediaType="Media"
              iconType="image"
            />
          </Slide>
        </Slider>
      </>
    );

  const getMediaSlide = (mediaAsset, index) => {
    const currentSlide = carouselStore.getStoreState().currentSlide;
    const onCurrentSlide = index === currentSlide;

    if (onCurrentSlide) {
      if (
        !!mediaAsset.src.highRes &&
        !mediaAsset.src.audio &&
        !mediaAsset.src.video
      ) {
        return (
          <>
            <LoadingScreen classNamePrefix="imageviewer" text="Loading..." />
            <OSDWrapper
              className={`imageviewer-high-res`}
              dzi={true}
              src={mediaAsset.src.highRes}
              zoomMax={zoomMax || 2}
            />
          </>
        );
      } else if (
        !!mediaAsset.src.lowRes &&
        !mediaAsset.src.audio &&
        !mediaAsset.src.video
      ) {
        return (
          <>
            <LoadingScreen
              className="imageviewer-low-res"
              classNamePrefix="imageviewer"
              text="Loading..."
            />
            <OSDWrapper
              className={`imageviewer-high-res`}
              dzi={false}
              src={mediaAsset.src.lowRes}
              zoomMax={zoomMax || 2}
            />
          </>
        );
      } else if (!!mediaAsset.src[360]) {
        return (
          <>
            <LoadingScreen classNamePrefix="imageviewer" text="Loading..." />
            <Fyusion
              id={mediaAsset.src[360]}
              filmstripThumbnail={mediaAsset.src.thumbnail}
            />
          </>
        );
      } else if (!!mediaAsset.src.video) {
        return (
          <VideoViewer
            src={mediaAsset.src.video}
            currentSlide={currentSlide}
            totalSlides={mediaAssets.length}
            fullscreenHidden={fullScreenHidden}
            handleFullScreenClick={handleFullScreenClick}
          />
        );
      } else if (!!mediaAsset.src.audio) {
        return (
          <Audio
            mediaAsset={mediaAsset.src}
            currentSlide={currentSlide}
            totalSlides={mediaAssets.length}
            fullscreenHidden={fullScreenHidden}
            handleFullScreenClick={handleFullScreenClick}
          />
        );
      }
    } else {
      return null;
    }
  };

  const AvailablePhotos = React.useMemo(
    () => ({ updateCurrentSlide }) =>
      mediaAssetsExist && (
        <>
          <Slider
            disableKeyboard
            moveThreshold={1.5}
            trayProps={trayProps(updateCurrentSlide, imageViewerCarouselStore)}
          >
            {mediaAssets.map((mediaAsset, index) => (
              <Slide
                className="imageviewer-slide"
                index={index}
                key={`${mediaAsset.id}-imgv-${index}`}
              >
                {getMediaSlide(mediaAsset, index)}
              </Slide>
            ))}
          </Slider>
        </>
      ),
    mediaAssets
  );

  return (
    <MultiMediaViewerConsumer>
      {({ testLoad, updateCurrentSlide }) => (
        <div
          className={`imageviewer`}
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onLoad={testLoad({ imageViewerCarouselStore })}
          onClick={handleClick}
          onTouchStart={handleClick}
        >
          <div className="imageviewer-multimediaviewerarea">
            <AvailablePhotos updateCurrentSlide={updateCurrentSlide} />
            <MediaNotAvailableSlider />

            <BackButton
              onClick={stopControlsDisplayTimer}
              controlsHidden={controlsHidden}
              carouselStore={carouselStore}
              multipleMediaAssetsExist={multipleMediaAssetsExist}
            />
            <NextButton
              onClick={stopControlsDisplayTimer}
              controlsHidden={controlsHidden}
              carouselStore={carouselStore}
              multipleMediaAssetsExist={multipleMediaAssetsExist}
            />
            <MediaCountWithCarouselState
              mediaAssets={mediaAssets}
              controlsHidden={controlsHidden}
              mediaAssetsExist={mediaAssetsExist}
              fullScreenHidden={fullScreenHidden}
              handleFullScreenClick={handleFullScreenClick}
              handleExitFullScreenClick={handleExitFullScreenClick}
              customButtons={customButtons}
              showFullScreenControls={showFullScreenControls}
            />
          </div>
        </div>
      )}
    </MultiMediaViewerConsumer>
  );
});

const ImageViewerWithCarousel = (props) => {
  const { mediaAssets } = props;

  const mediaAssetsExist = Boolean(numberOf(mediaAssets));

  const multipleMediaAssetsExist = numberOf(mediaAssets) > 1;

  const imageViewerProps = {
    mediaAssets,
    mediaAssetsExist,
    multipleMediaAssetsExist,
    ...props,
  };

  const ImageViewerWithCarouselState = WithStore(ImageViewer);

  return (
    <ImageViewerProvider>
      <CarouselProvider
        totalSlides={totalSlides(props.mediaAssets)}
        naturalSlideWidth={96}
        naturalSlideHeight={72}
        visibleSlides={1}
        dragEnabled={false}
        infinite="true"
        className="imageviewer-carousel-provider"
      >
        <ImageViewerWithCarouselState {...imageViewerProps} />
      </CarouselProvider>
    </ImageViewerProvider>
  );
};

ImageViewerWithCarousel.propTypes = propTypes;
export default ImageViewerWithCarousel;
