import Refinements from './Refinements';
import RefinementsHeader from './RefinementsHeader';
import RefinementsPanel from './RefinementsPanel';

Refinements.Header = RefinementsHeader;
Refinements.Panel = RefinementsPanel;

export { RefinementsHeader, RefinementsPanel };

export default Refinements;
