import {
  NEXT,
  PREVIOUS,
  LEFT_ARROW,
  RIGHT_ARROW,
  LETTER_K,
  LETTER_M,
} from '../../types/constants';

export const naturalCountingNumber = (index) => index + 1; // Humans count starting from 1

export const numberOf = (mediaAssets) => mediaAssets.length;

export const calculateCurrentSlide = (carouselStore, direction = NEXT) => {
  const { currentSlide, totalSlides } = carouselStore.getStoreState();
  const newSlide = currentSlide + direction;
  if (newSlide < 0) {
    return totalSlides - 1;
  } else if (newSlide > totalSlides - 1) {
    return 0;
  }
  return newSlide;
};

export const mapCarouselStateToProps = ({ currentSlide, totalSlides }) => ({
  currentSlide,
  totalSlides,
});

const setControlsDisplayTimerFor = (controlsDisplayTimer) => (val) => {
  controlsDisplayTimer.current = val;
};

const startControlsDisplayTimerWith = (
  setControlsHidden,
  controlsDisplayTimer
) => {
  setControlsHidden(false);
  setControlsDisplayTimerFor(controlsDisplayTimer)(
    setTimeout(() => {
      setControlsHidden(true);
    }, 3000)
  );
};

export const stopControlsDisplayTimerWith = (
  setControlsHidden,
  controlsDisplayTimer
) => () => {
  setControlsDisplayTimerFor(controlsDisplayTimer)(
    clearTimeout(controlsDisplayTimer.current)
  );
  startControlsDisplayTimerWith(setControlsHidden, controlsDisplayTimer);
};

export const totalSlides = (mediaAssets) => mediaAssets.length || 1;

export const getMediaPlayer = (e, imageViewerCarouselStore) => {
  const currentSlideElement =
    e.currentTarget.children[
      imageViewerCarouselStore.getStoreState().currentSlide
    ];
  const isAudio = currentSlideElement.querySelector('#audio');
  const isVideo = currentSlideElement.querySelector('#video');
  return isAudio !== null ? isAudio : isVideo;
};

export const trayProps = (updateCurrentSlide, imageViewerCarouselStore) => ({
  onMouseUp: () => {
    updateCurrentSlide(imageViewerCarouselStore.getStoreState().currentSlide);
  },
  onTouchEnd: () => {
    updateCurrentSlide(imageViewerCarouselStore.getStoreState().currentSlide);
  },
  onKeyDown: (e) => {
    if (e.keyCode === LEFT_ARROW) {
      updateCurrentSlide(
        calculateCurrentSlide(imageViewerCarouselStore, PREVIOUS)
      );
    } else if (e.keyCode === RIGHT_ARROW) {
      updateCurrentSlide(calculateCurrentSlide(imageViewerCarouselStore, NEXT));
    }
  },
  onKeyUp: (e) => {
    if (e.keyCode === LETTER_K || e.keyCode === LETTER_M) {
      const event = new Event('keyup');
      event.key = e.keyCode;
      getMediaPlayer(e, imageViewerCarouselStore).dispatchEvent(event);
    }
  },
});

const addIconsToTags = (fyusionTags, id) => {
  fyusionTags.data.forEach((tag, index) => {
    const tagElem = document.querySelector(
      `#element_id-${id} .fy_tgs span:nth-child(${index + 1})`
    );
    if (tagElem !== null) {
      tagElem.classList.add(tag['Category'][0]);
    }
  });
};
const changeBackButtonBackgroundImage = (id, src) => {
  const selector = `#element_id-${id} .fy_lg.fy_bk`;
  const styleTag = document.createElement('style');
  styleTag.innerText = `${selector} { background: url(${src}) center / 144px 82px no-repeat; }`;
  document.body.appendChild(styleTag);
};

export const tagSetup = (id, src) => (viewer) => {
  const fyusionTags = viewer.tags;
  if (typeof fyusionTags !== 'undefined') {
    addIconsToTags(fyusionTags, id);
    changeBackButtonBackgroundImage(id, src);
  }
};
