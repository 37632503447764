import Searchable from './Searchable';
import {
  SearchableProvider,
  SearchableContext,
} from './context/SearchableContext';
import SearchableList from './SearchableList';
import SearchableSearch from './SearchableSearch';
import useListState from './hooks/useListState';
import useSearch from './hooks/useSearch';

Searchable.Context = SearchableContext;
Searchable.Provider = SearchableProvider;
Searchable.List = SearchableList;
Searchable.Search = SearchableSearch;

export {
  SearchableContext,
  SearchableProvider,
  SearchableList,
  SearchableSearch,
  useListState,
  useSearch,
};

export default Searchable;
