import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

import ActionsContext, { withProvider } from '../Context';
import Action from './Action';
import Panel from './Panel';
import Spacer from './Spacer';

import {
  CNAME,
  MORE_LABEL,
  RIGHT,
  SHOW_ALL,
} from '../CONSTANTS';

const propTypes = {
  action: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
  id: PropTypes.string,
  menuAlignment: PropTypes.string,
  menuLabel: PropTypes.string,
  textOnly: PropTypes.bool,
  show: PropTypes.oneOfType([
    PropTypes.oneOf([SHOW_ALL]),
    PropTypes.number,
  ]),
};

const defaultProps = {
  actions: null,
  id: uuid(),
  menuAlignment: RIGHT,
  menuLabel: MORE_LABEL,
  show: 2,
};

const Actions = ({
  action,
  actions,
  children,
  className: propClassName,
  id,
  menuAlignment,
  show,
}) => {
  const [{
    remainingActions,
    visibleActions,
  }, { setActions }] = useContext(ActionsContext);

  const updateActions = () => {
    if (!setActions) {
      return;
    }

    if (children) {
      setActions(React.Children.toArray(children));
    } else if (action) {
      setActions([action])
    } else {
      setActions(actions || []);
    }
  }

  useEffect(() => {
    updateActions()
  }, [action, actions, children])

  const className = classnames(CNAME, propClassName)

  const toAction = (action, index) => (
    action.label
      ? <Action {...action} key={`${id}-action-${index}`} actionIndex={index} />
      : action.spacer
        ? <Spacer {...action} key={`${id}-spacer-${index}`} />
        : null
  )

  return (
    <div className={className}>
      { visibleActions.map(toAction) }
      { remainingActions.length
        ? <Panel align={menuAlignment}>
            {remainingActions.map((action, index) => (
              toAction(action, Math.max(0, show) + index )
            ))}
          </Panel>
        : null
      }
    </div>
  );
}

Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;
Actions.displayName = 'Actions';

export default withProvider(Actions);
