import Tabs from './Tabs';
import TabBar from './TabBar';
import TabItem from './TabItem';
import TabPane from './TabPane';

Tabs.Bar = TabBar;
Tabs.Item = TabItem;
Tabs.Pane = TabPane;

export { TabBar, TabItem, TabPane };

export default Tabs;
