import React from 'react';
import PropTypes from 'prop-types';
import { LOCALE_STRING } from '../CONSTANTS';

const propTypes = {
  isMonthPicker: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  decreaseYear: PropTypes.func.isRequired,
  increaseYear: PropTypes.func.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  showMonthPicker: PropTypes.func.isRequired,
};

const defaultProps = {
  isMonthPicker: false,
  selectedDate: new Date(),
};

const generateHeaderTitle = (date) =>
  `${date.toLocaleString(LOCALE_STRING, {
    month: 'long',
  })} ${date.getFullYear()}`;

const Header = ({
  decreaseMonth,
  decreaseYear,
  increaseMonth,
  increaseYear,
  isMonthPicker,
  selectedDate,
  showMonthPicker,
}) => {
  const currentSelectedDate = new Date(selectedDate);

  if (isMonthPicker) {
    return (
      <div className="picker-header-navigation">
        <button onClick={decreaseYear} className="navigation-button link">
          <i className="icon prism-icon-chevron-left" />
        </button>
        <div className="current-month">{currentSelectedDate.getFullYear()}</div>
        <button onClick={increaseYear} className="navigation-button link">
          <i className="icon prism-icon-chevron-right" />
        </button>
      </div>
    );
  }
  return (
    <div className="picker-header-navigation">
      <button onClick={decreaseMonth} className="navigation-button link">
        <i className="icon prism-icon-chevron-left-light" />
      </button>
      <button onClick={showMonthPicker} className="current-month link">
        {generateHeaderTitle(currentSelectedDate)}
      </button>
      <button onClick={increaseMonth} className="navigation-button link">
        <i className="icon prism-icon-chevron-right-light" />
      </button>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
Header.displayName = 'DatePicker.Header';

export default Header;
