import Bowser from 'bowser';

const useAgentDetect = () => {
  const getUserAgent = () =>
    typeof window !== 'undefined' ? window.navigator.userAgent : '.';

  const browser = Bowser.getParser(getUserAgent());
  const IS_IOS = browser.getOS().name === 'iOS';
  const IS_ANDROID = browser.getOS().name === 'android';
  const IS_TOUCH = IS_IOS || IS_ANDROID;

  return {
    IS_IOS,
    IS_ANDROID,
    IS_TOUCH,
    browser,
  };
};

export default useAgentDetect;
