import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { default as IconSelection } from '../../styles/fonts/prism-icon-font/selection.json';

const propTypes = {
  glyph: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  xmlns: PropTypes.string,
  path: PropTypes.string,
  title: PropTypes.string,
  svgClassName: PropTypes.string,
};

const defaultProps = {
  height: '16px',
  viewBox: null,
  xmlns: 'http://www.w3.org/2000/svg',
  path: null,
  fill: '#000',
  className: '',
};

/// GATHER ICONS
const iconSet = {};
IconSelection.icons.forEach((iconObj) => {
  iconSet[iconObj.properties.name] = {
    paths: iconObj.icon.paths,
    width: iconObj.icon.width,
  };
});

const getColorAtIndex = (colors, index) => {
  if (!Array.isArray(colors)) {
    return colors;
  } else {
    return colors[index % colors.length];
  }
};

const getColorAttr = (colors, index) => {
  let attrs = {};
  let c = getColorAtIndex(colors, index);
  if (!c) {
    attrs.style = {
      fill: 'inherit',
      color: 'inherit',
    };
  } else if (c.indexOf('#') === 0) {
    attrs.style = {
      fill: c,
    };
  } else {
    attrs.className = `icon-${c}`;
  }

  return attrs;
};

//////
//

function IconSvg(props) {
  const unique = uuid();

  const {
    width,
    height,
    viewBox,
    xmlns,
    glyph,
    title,
    className,
    id,
    color,
    ...pathProps
  } = props;

  let svgProps = {
    width,
    height,
    viewBox,
    xmlns,
    className,
    id,
  };

  const titleTag = title ? <title>{title}</title> : null;

  svgProps.className = `prism-icon-svg ${svgProps.className}`.trim();

  let paths = null;

  if (glyph && iconSet[glyph]) {
    let i = 0;

    paths = (
      <React.Fragment>
        {iconSet[glyph].paths.map((d, index) => (
          <path
            d={d}
            key={`${unique}--${i++}`}
            {...getColorAttr(color, index)}
            {...pathProps}
          />
        ))}
      </React.Fragment>
    );

    if (!viewBox) {
      svgProps.viewBox = `0 0 ${iconSet[glyph].width || 1024} 1024`;
    }

    // ensure we maintain aspect ratio with the height
    if (!width) {
      const h = parseFloat(height, 10);
      const w = ((iconSet[glyph].width || 1024) / 1024) * h + 'px';
      svgProps.width = w;
    }
  } else {
    paths = <path {...pathProps} />;
  }

  return (
    <svg {...svgProps}>
      {titleTag}
      {paths}
    </svg>
  );
}

IconSvg.propTypes = propTypes;
IconSvg.defaultProps = defaultProps;

export default IconSvg;
