import { uuid } from '../Utilities/utils';

/**
 * Creates a new object consisting of keys in a `keep` list or a `remove` list.
 * If `keys.removeKeys` is present, `keys.keepKeys` will be ignored, otherwise
 * the `keys.keepKeys` are moved to the new object. If neither `keys.removeKeys`
 * nor `keys.keepKeys` are provided, a shallow clone is provided.
 *
 * @param {object} obj Working object which keys will be removed
 * @param {object} keys Array of key names to keep in the final object if present in the original
 * @param {array} keys.keepKeys Array of key names to keep in the final object if present in the original
 * @param {array} keys.removeKeys Array of key names to prevent in final object if present in the original
 * @returns New object containing any keys from the original object
 */
export const modifyKeys = (obj = {}, keys = {}) => {
  if (typeof obj !== 'object' && obj !== null) return {};

  const { keepKeys, removeKeys } = keys;

  if (!Array.isArray(keepKeys) && !Array.isArray(removeKeys)) {
    return { ...obj };
  }

  return Object.keys(obj)
    .filter((k) =>
      removeKeys ? !removeKeys.includes(k) : keepKeys.includes(k)
    )
    .reduce((acc, cur) => {
      acc[cur] = obj[cur];
      return acc;
    }, {});
};

const getType = (obj) =>
  Object.prototype.toString
    .call(obj) // `[object ###]`
    .substr('[object '.length) // `###]`
    .slice(0, -1) /// `###`
    .toLowerCase();

const isNumeric = (entry) => getType(entry) === 'number';
const isFunction = (entry) => getType(entry) === 'function';

/**
 * Determines the text alignment based on the
 * @param {any} entry
 */
export const getTextAlignment = (entry) =>
  isNumeric(entry) ? 'right' : 'left';

/**
 * Returns a union of two objects assigned to a third object. If the third
 * object is undefined, the second object will be modified in place.
 *
 * @param {object} risky Object's values to be used when key matches
 * @param {object} safe Object's key denote "safe" keys
 * @param {object} baseObject Base object to assign keys to, defaults to `safe`
 * @returns Object with keys from Union of `risky` and `safe`
 */
export const sanitizeObject = (risky, safe, baseObject = safe) => {
  const safeKeys = Object.keys(safe);

  return Object.keys(risky || {})
    .filter((key) => safeKeys.includes(key))
    .reduce((acc, key) => {
      acc[key] = risky[key];
      return acc;
    }, baseObject);
};

/**
 * Ensures an array is returned
 *
 * @param {array} risky Risky value object to be assigned
 * @param {array} safe Safe values
 */
export const sanitizeArray = (risky, safe = []) => {
  return risky ? (Array.isArray(risky) ? [...risky] : [risky]) : [...safe];
};

export const noop = () => {};

/**
 *
 */
export const columnFactory = (defaultColumnState = {}) => (column) => {
  let col =
    typeof column === 'string' ? { Header: column, accessor: column } : column;

  const __id = uuid();
  const childSize = col.columns ? col.columns.length : 0;
  const onBeforeSort =
    'onBeforeSort' in col
      ? col.onBeforeSort
      : defaultColumnState.onBeforeSort || null;
  const onAfterSort =
    'onAfterSort' in col
      ? col.onAfterSort
      : defaultColumnState.onAfterSort || null;

  let resultColumn = {
    ...defaultColumnState,
    id: col.accessor || __id,
    accessor: col.accessor || __id,
    Header: '',
    headers: childSize
      ? function () {
          return this.columns
            .map((child) => child.ref.current && child.ref.current.id)
            .join(' ');
        }
      : null,
    disableSortBy:
      'sortable' in col ? !col.sortable : !defaultColumnState.sortable,
    ...col,
    columns: Array.isArray(col.cols) ? col.columns.map(columnFactory) : null,
  };

  resultColumn.onBeforeSort =
    onBeforeSort && ((...args) => onBeforeSort(...args, resultColumn));
  resultColumn.onAfterSort =
    onAfterSort && ((...args) => onAfterSort(...args, resultColumn));

  return resultColumn;
};
