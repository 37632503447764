import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SortIcon from './SortIcon';

export const CNAME = 'table-head-cell';

const propTypes = {
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  headerStyle: PropTypes.oneOf(['light', 'dark', 'color']),
  onBeforeSort: PropTypes.func,
  onAfterSort: PropTypes.func,
};

const defaultProps = {
  textAlign: 'left',
  headerStyle: null,
};

const TableHeadCell = ({
  canSort,
  children,
  isSorted,
  isSortedDesc,
  onClick,
  sortByProps,
  className: propsClassName,
  textAlign, // destructure from props
  headerStyle, // destructure from props
  onBeforeSort,
  onAfterSort,
  toggleSortBy,
  ...props
}) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (!!canSort) {
      onBeforeSort && onBeforeSort(e);
      sortByProps && sortByProps.onClick && sortByProps.onClick(e);
      onAfterSort && onAfterSort(e);
    }

    onClick && onClick(e);
  };

  const className = classNames({
    [CNAME]: CNAME,
    [propsClassName]: !!propsClassName,
    'can-sort': !!canSort,
  });

  return (
    <th {...props} className={className} onClick={handleClick}>
      {children}
      {canSort && <SortIcon isSorted={isSorted} isSortedDesc={isSortedDesc} />}
    </th>
  );
};

TableHeadCell.propTypes = propTypes;
TableHeadCell.defaultProps = defaultProps;
TableHeadCell.displayName = 'TableHeadCell';

export default TableHeadCell;
