import { arrayOf, element, oneOf, oneOfType, shape, string } from "prop-types";

import { CATEGORIES_LIST } from './constants';


const urlString = string;

export const MediaAssetsType = arrayOf(
    shape({
        category: oneOf(CATEGORIES_LIST),
        src: shape({
            thumbnail: urlString.isRequired,
            lowRes: urlString,
            highRes: urlString,
            audio: urlString,
            video: urlString,
            360: urlString
        }),
        description: oneOfType([element, string])
    })
).isRequired;
