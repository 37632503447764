import { v4 as uuid } from 'uuid';

import { CATEGORIES_LIST } from '../../types/constants';

export const getCategoryCounts = (mediaAssets) => {
  const categoryCounts = {};
  categoryCounts['ALL'] = mediaAssets.length;
  CATEGORIES_LIST.forEach((c) => (categoryCounts[c] = 0));
  mediaAssets.forEach((asset) => categoryCounts[asset.category]++);
  return categoryCounts;
};

export const handleFullScreenClick = (
  mediaViewerRef,
  setFullScreenHidden
) => () => {
  setFullScreenHidden(false);
  const fullScreenEventListeners = [
    'fullscreenchange',
    'webkitfullscreenchange',
    'mozfullscreenchange',
    'MSFullscreenChange',
  ];
  if (typeof mediaViewerRef.current.requestFullscreen === 'undefined') return;

  const exitHandler = () => {
    const noFullScreenElements =
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement;
    if (noFullScreenElements) {
      setFullScreenHidden(true);
      fullScreenEventListeners.forEach((listener) => {
        mediaViewerRef.current.removeEventListener(listener, exitHandler);
      });
    }
  };

  fullScreenEventListeners.forEach((listener) => {
    mediaViewerRef.current.addEventListener(listener, exitHandler);
  });
};

export const handleExitFullScreenClick = (
  mediaViewerRef,
  setFullScreenHidden
) => () => {
  setFullScreenHidden(true);

  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement
  ) {
    document.exitFullscreen();
  }
};

export const handleZoomInClick = () => {};

export const handleZoomOutClick = () => {};

const fyusionCategoryFor = {
  misc: 'MISC',
  interior: 'INT',
  exterior: 'EXT',
  damages: 'DMG',
  features: 'FEATURES',
};

const fyusionVersion = '1.5';

export const handleFyuseIdsAnd = (
  setUnfilteredAssets,
  unfilteredAssets,
  setFilteredAssets
) => {
  // FIXME: this function expects an array with a single Fyusion ID in order to work properly. If two objects are included in the array, the second will overwrite the first
  const expandedAssets = unfilteredAssets.map((asset) => {
    const {
      src: { fyusionId },
    } = asset;
    if (!!fyusionId) {
      fetch(`https://api.fyuse.com/${fyusionVersion}/group/web/${fyusionId}`)
        .then((response) => response.json())
        .then((result) => {
          const rootPath = result.data.path;
          const newAssets = result.data.list
            .map((item) => {
              const { thumb, type, path } = item;
              const lowRes = `${rootPath}${path}`;
              const thumbnail = `${rootPath}${thumb}`;
              const category = fyusionCategoryFor[item.category[0]];
              const description = item.description || '';
              const toReturn = {
                src: {
                  lowRes,
                  thumbnail,
                },
                description,
                category,
              };

              if (type === 'fyuse') {
                toReturn.src['360'] = item.uid;
                toReturn.src.lowRes = null;
              } else if (type === 'video') {
                toReturn.src.video = `${rootPath}${path}`;
              } else if (type === 'audio') {
                toReturn.src.audio = `${rootPath}${path}`;
              }
              return toReturn;
            })
            .map(normalizeAsset);

          setUnfilteredAssets(newAssets);
          setFilteredAssets(newAssets);
        });
    } else {
      setUnfilteredAssets(unfilteredAssets);
      setFilteredAssets(unfilteredAssets);
    }
  });
};

export const normalizeAsset = (asset) => ({
  ...asset,
  id: asset.id || uuid(),
});
