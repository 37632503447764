import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SelectableContext } from './context/SelectableContext';
import { AccordionCtx } from './AccordionCtx';

const propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  id: PropTypes.string,
  tag: PropTypes.string,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string,
  onClear: PropTypes.func,
  clearable: PropTypes.bool,
};

const defaultProps = {
  open: true,
  toggle: () => {},
  variant: 'default',
  tag: 'div',
};

function AccordionTrigger({
  open,
  toggle,
  id,
  tag: Tag,
  className,
  defaultOpen,
  title,
  variant,
  onClear,
  clearable,
}) {
  const [isActive, setIsActive] = useState(defaultOpen);
  const selectableContext = useContext(SelectableContext);
  const accordionCtx = useContext(AccordionCtx);

  const isDefault = variant === 'default';
  const isFilterAccordion = variant === 'filter';
  const defaultSelectCtx = {
    ctxClear: null,
    setCtxClear: () => {},
  };

  const { ctxClear, setCtxClear } = selectableContext || defaultSelectCtx;

  const defaultAccordionCtx = {
    openItem: '',
    handleCtxOpen: () => {},
  };
  const { openItem, handleCtxOpen } = accordionCtx || defaultAccordionCtx;

  const triggerClasses = classNames(
    className,
    'accordion-trigger',
    isActive && `accordion-trigger--active`,
    isFilterAccordion && `accordion-trigger--filter`
  );

  const triggerCaratClasses = classNames(
    'accordion-trigger__carat',
    isActive && `accordion-trigger__carat--active`,
    isFilterAccordion && `accordion-trigger__carat--filter`
  );
  const rightIconClasses = classNames(
    isDefault && 'icon prism-icon-chevron-down accordion-carat',
    isFilterAccordion &&
      clearable &&
      'icon prism-icon-cancel-circle accordion-filter__cancel-icon'
  );
  const filterClasses = classNames(
    clearable && 'accordion-trigger__carat accordion-filter__cancel'
  );
  const handleTriggerClick = (e) => {
    toggle();
    setIsActive(!isActive);
    !isFilterAccordion && openItem !== id && handleCtxOpen(id);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let prevCtxClear = ctxClear;
    setCtxClear(true);
    onClear && onClear(true);
  };

  const handleCtxChange = () => {
    variant !== 'filter' && setIsActive(id === openItem);
  };
  useEffect(() => {
    handleCtxChange(openItem);
  }, [openItem]);

  return (
    <Tag className={triggerClasses} onClick={handleTriggerClick}>
      {isFilterAccordion && (
        <span className={triggerCaratClasses}>
          <i
            className={'icon prism-icon-chevron-right-light accordion-carat'}
          />
        </span>
      )}

      <span className="accordion-trigger-label">{title}</span>

      {!!rightIconClasses && (
        <span
          onClick={isFilterAccordion ? handleClear : handleTriggerClick}
          className={isDefault ? triggerCaratClasses : filterClasses}
        >
          <i className={rightIconClasses} />
        </span>
      )}
    </Tag>
  );
}

AccordionTrigger.propTypes = propTypes;
AccordionTrigger.defaultProps = defaultProps;

export default AccordionTrigger;
