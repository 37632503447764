import React, { useContext } from 'react';
import { SearchableContext } from '../context/SearchableContext';

function useSearch() {
  const [search, setSearch] = useContext(SearchableContext);

  const setIsSearching = (s) =>
    setSearch((search) => ({ ...search, isSearching: s }));

  const setSearchValue = (v) =>
    setSearch((search) => ({ ...search, value: v }));
  const handleBlur = () => {
    return setIsSearching(false);
  };
  const handleFocus = () => setIsSearching(true);

  const handleClear = (e) => {
    return e && setSearchValue('') && setIsSearching(false);
  };
  const handleSearchChange = (e) => {
    let { value } = e.target;
    const targetVal = value;
    targetVal.length > 0 ? setIsSearching(true) : setIsSearching(false);
    setSearchValue(value);
  };

  return {
    searchValue: search.value,
    isSearching: search.isSearching,
    setIsSearching,
    handleSearchChange,
    handleBlur,
    handleClear,
    handleFocus,
  };
}

export default useSearch;
