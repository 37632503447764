import React, { useLayoutEffect, useRef, useState } from 'react';
import { Image } from 'pure-react-carousel';
import classNames from 'classnames';
import Tooltip from '@prism/tooltip';
import { naturalCountingNumber } from './utils';
import { LETTER_K, LETTER_M, TIMEOUT_VALUE_IN_MS } from '../../types/constants';
import MediaNotAvailable from './MediaNotAvailable';
import FullScreenToggle from './FullScreenToggle';
import useAgentDetect from '../../hooks/useAgentDetect';

const { IS_IOS, IS_TOUCH } = useAgentDetect();

let audioLoaded = false;

const timeout = (setMaxLoadTimeExceeded) => {
  if (audioLoaded === false) {
    setMaxLoadTimeExceeded(true);
  }
};

const Audio = ({
  mediaAsset,
  currentSlide,
  totalSlides,
  fullscreenHidden,
  handleFullScreenClick,
}) => {
  const src = mediaAsset.audio;
  const thumbnail = mediaAsset.thumbnail;
  const progBarRef = useRef();
  const progressRef = useRef();
  const volSliderRef = useRef();
  const audRef = useRef();
  const ratioRef = useRef();
  const [play, setPlay] = useState(false);
  const [muted, setMuted] = useState(false);
  const [hideVol, setHideVol] = useState(true);
  const [playTooltipOpen, setPlayTooltip] = useState(false);
  const [muteTooltipOpen, setMuteTooltip] = useState(false);
  const [maxLoadTimeExceeded, setMaxLoadTimeExceeded] = useState(false);
  const [isAudioLoaded, setIsAudioLoaded] = useState(undefined);
  const [isAudioError, setIsAudioError] = useState(false);
  const [hasIosPlayed, setHasIosPlayed] = useState(false);
  const [hasAudioEnded, setHasAudioEnded] = useState(false);

  useLayoutEffect(() => {
    audioLoaded = false;
    setTimeout(() => timeout(setMaxLoadTimeExceeded), TIMEOUT_VALUE_IN_MS);

    const audio = audRef.current;
    const progressBar = progBarRef.current;
    const progress = progressRef.current;

    if (audio) {
      ratioRef.current.innerHTML = '00:00 / 00:00';

      audRef.current.addEventListener('timeupdate', () => {
        progress.setAttribute('max', audio.duration);
        progress.value = audio.currentTime;
        progressBar.style.width =
          Math.floor((audio.currentTime / audio.duration) * 100) + '%';
        getAudioRatio();
      });

      progressRef.current.addEventListener('click', function (e) {
        const pos =
          (e.offsetX - (this.offsetLeft + this.offsetParent.offsetLeft)) /
          this.offsetWidth;
        audio.currentTime = pos * audio.duration;
      });

      audRef.current.addEventListener('keyup', function (e) {
        handleKeyPress(e);
      });
    }
  }, []);

  const togglePlay = () => {
    const audio = audRef.current;
    if (audio.paused || audio.ended) {
      audio.play();
      setPlay(true);
      setHasAudioEnded(false);
    } else {
      audio.pause();
      setPlay(false);
    }
  };

  const setVolume = (newVol) => {
    audRef.current.volume = newVol;
    if (newVol <= 0) setMuted(true);
    else if (newVol > 0 && muted) setMuted(false);
  };

  const toggleMute = () => {
    const audio = audRef.current;
    setMuted(!muted);
    audio.muted = !muted;
  };

  const getAudioRatio = () => {
    if (audRef.current === null) {
      return;
    }
    const audio = audRef.current;
    const seconds = new Date(audio.currentTime * 1000);
    const currentProgress = seconds.toISOString().substr(14, 5);
    const durationMinutesSeconds = new Date(audio.duration * 1000)
      .toISOString()
      .substr(14, 5);
    ratioRef.current.innerHTML =
      currentProgress + ' / ' + durationMinutesSeconds;
  };

  const handleKeyPress = (e) => {
    if (e.key === LETTER_K) {
      togglePlay();
    } else if (e.key === LETTER_M) {
      toggleMute();
    }
  };

  const handleSlide = (e) => {
    setVolume(volSliderRef.current.value / 100);
  };

  const handleMuteMouseEnter = () => {
    setHideVol(false);
  };

  const handleMuteMouseLeave = () => {
    setHideVol(true);
  };

  const toggle = (buttonName) => {
    if (buttonName === 'play') setPlayTooltip(!playTooltipOpen);
    else if (buttonName === 'mute') setMuteTooltip(!muteTooltipOpen);
  };

  const handleAudioError = () => {
    setIsAudioError(true);
  };

  const handleAudioPlay = () => {
    if (IS_IOS) {
      audRef.current.controls = false;
      setHasIosPlayed(true);
    }
    setPlay(true);
  };

  const handleAudioEnded = () => {
    setHasAudioEnded(true);
  };

  const handleAudioLoadedData = () => {
    audioLoaded = true;
    setIsAudioLoaded(true);
  };

  const handleAudioLoadStart = (event) => {
    if (IS_IOS) {
      audRef.current.controls = true;
    }
  };

  const renderAudioComponent = () => {
    if (isAudioError) {
      return (
        <MediaNotAvailable
          classNamePrefix={'imageviewer-audio'}
          mediaType={'Audio'}
          iconType={'sound-off'}
        />
      );
    } else {
      var audioControlClasses = classNames({
        'audio-control-button-play': true,
        icon: true,
        'prism-icon-pause': play && !hasAudioEnded,
        'prism-icon-play': !play || hasAudioEnded,
      });

      return (
        <>
          <Image src={thumbnail} className="position-absolute" />
          <div id="audio-viewer" className={'audio-viewer'}>
            <div className="audio-viewer-wrapper">
              <audio
                id="audio"
                width="100%"
                ref={audRef}
                onPlay={handleAudioPlay}
                onError={handleAudioError}
                onEnded={handleAudioEnded}
                onLoadedData={handleAudioLoadedData}
                onLoadStart={handleAudioLoadStart}
              >
                <source src={src} type="audio/mpeg" />
                <source src={src} type="audio/ogg" />
                <source src={src} type="audio/wav" />
                Your browser does not support the audio element
              </audio>
              <div
                id="audio-controls"
                className={`
                  audio-controls
                  ${IS_IOS && !hasIosPlayed ? 'd-none' : ''}
                `}
                data-state="visible"
              >
                <div>
                  <div className="progress">
                    <progress id="progress" value="0" min="0" ref={progressRef}>
                      <span id="progress-bar" ref={progBarRef} />
                    </progress>
                  </div>
                </div>
                <div className="audio-control-buttons">
                  <div onClick={togglePlay} className="audio-player-control">
                    <i className={audioControlClasses} id="play-tooltip" />
                    {!window._sessionHistory && (
                      <Tooltip
                        size="sm"
                        target="play-tooltip"
                        toggle={() => toggle('play')}
                        isOpen={playTooltipOpen && !IS_TOUCH}
                      >
                        {!play ? 'Play (k)' : 'Pause (k)'}
                      </Tooltip>
                    )}
                  </div>
                  <div
                    onMouseEnter={handleMuteMouseEnter}
                    onMouseLeave={handleMuteMouseLeave}
                    className="audio-player-control"
                  >
                    <i
                      onClick={toggleMute}
                      className={`audio-control-button-mute icon prism-icon-${
                        muted ? 'sound-off' : 'sound-on'
                      }`}
                      id="mute-tooltip"
                    />
                    {!window._sessionHistory && (
                      <Tooltip
                        targetIsFunctional
                        size="sm"
                        target="mute-tooltip"
                        toggle={() => toggle('mute')}
                        isOpen={muteTooltipOpen}
                      >
                        {muted ? 'Unmute (m)' : 'Mute (m)'}
                      </Tooltip>
                    )}
                    <input
                      type="range"
                      min="0"
                      max="100"
                      ref={volSliderRef}
                      onChange={handleSlide}
                      className={`audio-player-volume ${
                        hideVol && 'audio-player-volume-hide'
                      }`}
                    />
                  </div>
                  <div
                    id="ratio"
                    ref={ratioRef}
                    className={'audio-player-timer'}
                  />
                  <span className="audio-mediacount-container">
                    <div className="audio-mediacount-text">{`${naturalCountingNumber(
                      currentSlide
                    )} of ${totalSlides}`}</div>
                    <FullScreenToggle
                      fullScreenHidden={fullscreenHidden}
                      handleFullScreenClick={handleFullScreenClick}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return renderAudioComponent();
};

export default Audio;
