import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typeahead,
  Menu,
  withItem,
  MenuItem,
  Highlighter,
} from 'react-bootstrap-typeahead';
import { v4 as uuid } from 'uuid';
import { useMedia } from 'react-use-media';

const propTypes = {
  bsSize: PropTypes.string,
  caseSensitive: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  defaultSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  ignoreDiacritics: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  labelKey: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  minLength: PropTypes.number,
  multiple: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInputChange: PropTypes.func,
  onMenuHide: PropTypes.func,
  onMenuShow: PropTypes.func,
  onMenuToggle: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
    PropTypes.array,
  ]),
  paginate: PropTypes.bool,
  placeholder: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
};

const defaultProps = {
  clearButton: true,
  disabled: false,
  id: uuid(),
  minLength: 3,
  paginate: false,
  shouldSelect: true,
};

function Autocomplete(props) {
  const [opts, setOpts] = useState(props.options);

  let { innerRef, renderMenu, options, ...attributes } = props;
  const TypeaheadMenuItem = withItem(MenuItem);

  const sortResults = (results, props) => {
    const { labelKey, text } = props;

    const toVal = (item) =>
      typeof item === 'string' ? item : item[labelKey].toString();

    return text
      ? results.sort((a, b) => {
          const aVal = toVal(a).toLowerCase();
          const bVal = toVal(b).toLowerCase();
          const matchA = aVal.indexOf(text.toLowerCase()) !== -1 ? aVal : -1;
          const matchB = bVal.indexOf(text.toLowerCase()) !== -1 ? bVal : -1;

          return matchA < matchB ? -1 : 1;
        })
      : results;
  };

  const isWide = useMedia({
    minWidth: 480,
  });

  const renderResults = (results, menuProps, props) => (
    <Menu {...menuProps}>
      {sortResults(results, props).map((result, index) => (
        <MenuItem key={index} option={result} position={index}>
          <Highlighter search={props.text}>
            {result[props.labelKey] || result}
          </Highlighter>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div className="row align-items-center">
      <div className=" autocomplete-input-container pr-5 flex-grow-1  ">
        <Typeahead
          maxResults={isWide ? 8 : 5}
          options={opts}
          ref={innerRef && innerRef}
          {...attributes}
          renderMenu={renderMenu || renderResults}
        />
      </div>
      <span className="autocomplete-search-icon icon prism-icon-search" />
    </div>
  );
}

Autocomplete.propTypes = propTypes;
Autocomplete.defaultProps = defaultProps;

export default Autocomplete;
