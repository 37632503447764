import React from 'react';
import PropTypes from 'prop-types';
import cname from 'classnames';

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  glyph: PropTypes.string,
  ariaTitle: PropTypes.string,
  tag: PropTypes.string,
};

const defaultProps = {
  tag: 'i',
};

const Icon = ({
  className: propsClassName,
  color,
  glyph,
  ariaTitle,
  tag: Tag,
  ...props
}) => {
  let className = cname('icon', {
    [propsClassName]: propsClassName,
    [glyph && `prism-icon-${glyph}`]: glyph,
    [color && `icon-${color}`]: color,
  });

  let attrs = {
    className,
    ['aria-hidden']: true,
  };

  if (ariaTitle) {
    attrs['aria-label'] = ariaTitle;
  }

  return <Tag {...props} {...attrs} />;
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
