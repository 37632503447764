import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const propTypes = {};
const defaultProps = {};

const Mount = ({ container, children }) => {
  const [modifiedPosition, setModifiedPosition] = useState(false);

  if (!container) {
    return children;
  }

  useEffect(() => {
    if (container.classList && getComputedStyle(container).position === 'static') {
      container.classList.add('position-relative')
      setModifiedPosition(true);
    }

    container.classList.add('interstitial-target')

    return () => {
      if (setModifiedPosition) {
        container.classList.remove('position-relative')
        setModifiedPosition(false);
      }
      container.classList.remove('interstitial-target')
    }
  })

  return ReactDOM.createPortal(children, container)
}

Mount.propTypes = propTypes
Mount.defaultProps = defaultProps
Mount.displayName = 'Mount'

export default Mount
