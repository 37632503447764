import React, { useState, useEffect } from 'react';

import { useList } from 'react-use';

const SelectableContext = React.createContext();

const SelectableProvider = (props) => {
  const [selectableCtxList, { set, push }] = useList();
  const [hasSelected, setHasSelected] = useState();
  const [ctxClear, setClear] = useState(false);

  const setCtxClear = (clearState) => {
    setClear(clearState);
  };

  const toggleSelected = (toggleState) => {
    setHasSelected(toggleState);
  };

  const setSelectedCtx = (list) => {
    set(list);
    let consumerListSelected = list.filter((item) => item.selected);
    let consumerHasSelected = consumerListSelected.length > 0;
  };
  const findById = (id) => {
    const searchItems = selected.filter((item) => item.id === id);
    return searchItems || null;
  };

  return (
    <SelectableContext.Provider
      value={{
        selectableCtxList,
        setSelectedCtx,
        findById,
        ctxClear,
        toggleSelected,
        hasSelected,
        setCtxClear,
      }}
    >
      {props.children}
    </SelectableContext.Provider>
  );
};

export { SelectableProvider, SelectableContext };
