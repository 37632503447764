// check for dead vars
export const CENTER = 'center';
export const LEFT = 'left';
export const RIGHT = 'right';

export const CNAME = 'actions';
export const CNAME_ACTION = `${CNAME}-action`;
export const CNAME_BUTTON = `btn`;
export const CNAME_ICON = `${CNAME}-icon`;
export const CNAME_ICON_ONLY = `${CNAME}-icon-only`;
export const CNAME_INLINE = `${CNAME}-inline`;
export const CNAME_LABEL = `${CNAME}-label`;
export const CNAME_MASK = `${CNAME}-mask`;
export const CNAME_MORE = `${CNAME}-more`;
export const CNAME_PANEL = `${CNAME}-panel`;
export const CNAME_SPACER = `${CNAME}-spacer`;
export const CNAME_TEXT_ONLY = `${CNAME}-text-only`;

export const MORE_LABEL = 'More';
export const SHOW_ALL = 'all';
