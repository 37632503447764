import React from 'react';
import PropTypes from 'prop-types';

const CNAME = 'table-container';

const propTypes = {
  contain: PropTypes.bool,
  headerStyle: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isResponsive: PropTypes.bool,
  isScrollable: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const defaultProps = {
  width: '100%',
};

const TableContainer = ({
  contain,
  headerStyle,
  width,
  height,
  isScrollable,
  key,
  children,
  ...args
}) => {
  const fragProps = { key, children };
  const shouldBeContained = contain || isScrollable;

  if (!shouldBeContained) {
    return <React.Fragment {...fragProps} />;
  }

  const props = {
    ...args,
    ...fragProps,
  };

  const styles = { width, height };

  return shouldBeContained ? (
    <div className={CNAME} style={styles} {...props} />
  ) : (
    <React.Fragment {...fragProps} />
  );
};

TableContainer.propTypes = propTypes;
TableContainer.defaultProps = defaultProps;
TableContainer.displayName = 'TableContainer';

export default TableContainer;
