import React from 'react';
import { IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS } from '../../types/constants';
import { WithStore } from 'pure-react-carousel';
import { mapCarouselStateToProps, naturalCountingNumber } from './utils';
import FullScreenToggle from './FullScreenToggle';
import ZoomControls from './ZoomControls';
import Description from './Description';
import MediaViewerButton from './MediaViewerButton';

const MediaCount = React.forwardRef(
  (
    {
      controlsHidden,
      currentSlide,
      mediaAssets,
      mediaAssetsExist,
      totalSlides,
      fullScreenHidden,
      handleFullScreenClick,
      handleExitFullScreenClick,
      customButtons,
      showFullScreenControls,
    },
    ref
  ) => {
    const [collapsed, setCollapsed] = React.useState(true);
    const isVideoOrAudio =
      !!mediaAssets[currentSlide] &&
      (!!mediaAssets[currentSlide].src.video ||
        !!mediaAssets[currentSlide].src.audio);

    const is360 =
      !!mediaAssets[currentSlide] && !!mediaAssets[currentSlide].src['360'];

    if (mediaAssetsExist && mediaAssets[currentSlide]) {
      const description = mediaAssets[currentSlide].description;
      return (
        <div className="imageviewer-controls">
          <div
            className={`imageviewer-mediacount
                    ${!collapsed && 'shift-up'}
                    ${
                      (controlsHidden || isVideoOrAudio || is360) &&
                      IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS
                    }`}
          >
            <div className="imageviewer-mediacount-content">
              <Description description={description} />

              <span className="imageviewer-mediacount-text">
                <span className="mr-2">{`${naturalCountingNumber(
                  currentSlide
                )} of ${totalSlides}`}</span>
                {customButtons &&
                  customButtons.map((buttonProps) => {
                    return (
                      <MediaViewerButton
                        {...buttonProps}
                        currentSlide={mediaAssets[currentSlide]}
                        index={currentSlide}
                      />
                    );
                  })}
                <ZoomControls zoomMax={3} />
                {showFullScreenControls && (
                  <FullScreenToggle
                    {...{
                      fullScreenHidden,
                      handleFullScreenClick,
                      handleExitFullScreenClick,
                    }}
                  />
                )}
              </span>
            </div>
            <div
              className={`mediacount-description
                                    ${
                                      !collapsed
                                        ? ''
                                        : 'mediacount-description-hidden'
                                    }`}
            >
              {description}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
);

const MediaCountWithCarouselState = WithStore(
  MediaCount,
  mapCarouselStateToProps
);

export { MediaCount };
export default MediaCountWithCarouselState;
