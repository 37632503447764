import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@prism/tooltip';

const COMPONENT_CNAME = 'prism-indicator--task';
const STATUS_COMPLETE = 'Complete';
const STATUS_INCOMPLETE = 'Incomplete';

const propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  complete: PropTypes.bool,
  iconOnly: PropTypes.bool,
};

const defaultProps = {
  name: '',
  complete: false,
  iconOnly: false,
};

const IndicatorTask = ({ name, icon, complete, iconOnly }) => {
  let className = COMPONENT_CNAME;
  const iconNode = React.createRef();

  if (complete === true) {
    className += ` ${COMPONENT_CNAME}--complete`;
  }

  const iconTag = <i ref={iconNode} className={`icon prism-icon-${icon}`} />;
  const status = complete ? STATUS_COMPLETE : STATUS_INCOMPLETE;

  const content = iconOnly ? (
    <Tooltip className={`${COMPONENT_CNAME}--tooltip`} target={iconNode}>
      {`${name} ${status}`.trim()}
    </Tooltip>
  ) : (
    <span className={`${COMPONENT_CNAME}--content`}>
      {`${status} ${name}`.trim()}
    </span>
  );

  return (
    <span className={className}>
      {iconTag}
      {content}
    </span>
  );
};

IndicatorTask.propTypes = propTypes;
IndicatorTask.defaultProps = defaultProps;

IndicatorTask.COMPONENT_CNAME = COMPONENT_CNAME;
IndicatorTask.STATUS_COMPLETE = STATUS_COMPLETE;
IndicatorTask.STATUS_INCOMPLETE = STATUS_INCOMPLETE;

export default IndicatorTask;
