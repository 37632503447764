import React from 'react';
import PropTypes from 'prop-types';

const COMPONENT_CNAME = 'prism-indicator--light';
const TYPE_AS_IS = 'TYPE_AS_IS';
const TYPE_CAUTION = 'TYPE_CAUTION';
const TYPE_GUARANTEE = 'TYPE_GUARANTEE';
const TYPE_TITLE_ABSENT = 'TYPE_TITLE_ABSENT';

const propTypes = {
  type: PropTypes.oneOf([
    TYPE_AS_IS,
    TYPE_CAUTION,
    TYPE_GUARANTEE,
    TYPE_TITLE_ABSENT,
  ]).isRequired,
  id: PropTypes.string,
  lit: PropTypes.bool,
};

const defaultProps = {
  id: null,
  lit: false,
};

const IndicatorAuctionLight = ({ type, lit, id }) => {
  const props = {};

  let className = COMPONENT_CNAME;

  switch (type) {
    case TYPE_AS_IS:
      className += ` ${COMPONENT_CNAME}--as-is`;
      props.tooltip = 'As Is';
      break;
    case TYPE_CAUTION:
      className += ` ${COMPONENT_CNAME}--caution`;
      props.tooltip = 'Caution';
      break;
    case TYPE_GUARANTEE:
      className += ` ${COMPONENT_CNAME}--guarantee`;
      props.tooltip = 'Guarantee';
      break;
    case TYPE_TITLE_ABSENT:
      className += ` ${COMPONENT_CNAME}--title-absent`;
      props.tooltip = 'Title Absent';
      break;
    default:
      break;
  }

  if (lit === true) {
    className += ` ${COMPONENT_CNAME}--illuminated`;
  }

  if (id !== null) {
    props.id = id;
  }

  return <div {...props} className={className} />;
};

IndicatorAuctionLight.propTypes = propTypes;
IndicatorAuctionLight.defaultProps = defaultProps;

IndicatorAuctionLight.COMPONENT_CNAME = COMPONENT_CNAME;
IndicatorAuctionLight.TYPE_AS_IS = TYPE_AS_IS;
IndicatorAuctionLight.TYPE_CAUTION = TYPE_CAUTION;
IndicatorAuctionLight.TYPE_GUARANTEE = TYPE_GUARANTEE;
IndicatorAuctionLight.TYPE_TITLE_ABSENT = TYPE_TITLE_ABSENT;

export default IndicatorAuctionLight;
