import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';

const CNAME = 'table-row-size';

const propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
};

const defaultProps = {
  label: 'Results per page',
  pageSize: 25,
  pageSizes: [25, 50, 100],
};

const PageSize = ({ label, pageSize, pageSizes, setPageSize }) => {
  const handlePageSizeClick = (size) => (e) => {
    if (setPageSize) {
      setPageSize(size);
    }
  };

  return (
    <div className={CNAME}>
      <span className="mr-2">{label}</span>
      <ButtonGroup>
        {pageSizes.map((size) => (
          <Button
            outline
            size="sm"
            key={CNAME + size}
            active={pageSize === size}
            onClick={handlePageSizeClick(size)}
          >
            {size}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

PageSize.propTypes = propTypes;
PageSize.defaultProps = defaultProps;
PageSize.displayName = 'PageSize';

export default PageSize;
