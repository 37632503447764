import React from 'react';
import PropTypes from 'prop-types';

const CNAME = 'table-foot';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
const defaultProps = {
  className: '',
};

const TableFoot = ({ className: propClassName, ...props }) => {
  const className = `${CNAME} ${propClassName}`.trim();

  return <tfoot className={className} {...props}></tfoot>;
};

TableFoot.propTypes = propTypes;
TableFoot.defaultProps = defaultProps;
TableFoot.displayName = 'TableFoot';

export default TableFoot;
