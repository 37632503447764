import React, { useState, useEffect, useCallback } from 'react';
// Hook
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        // Adds shadow dom support for when the ref is inside a shadow dom tree:
        if (event.composed && event.composedPath) {
          // shadow dom trees do event retargeting, which changes event.target to always be the shadow root
          // ... for events inside the tree
          const shadowDomEventPath = event.composedPath(); // we have to use this function to see the real
          // ... event bubbling path (an array from most specific element all the way up to the shadow root)
          // checks to see if the most specific event target (inside a shadow dom tree) was inside the ref or not
          if (
            shadowDomEventPath &&
            shadowDomEventPath.length > 0 &&
            ref.current.contains(shadowDomEventPath[0])
          ) {
            return;
          }
        }

        handler(event);

        if (event.type === 'touchstart') {
          event.preventDefault();
        }
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener, { passive: false });

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener, {
          passive: false,
        });
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export function useToggle(initialValue) {
  const [toggleValue, setToggleValue] = useState(initialValue);
  const toggler = useCallback(() => setToggleValue(!toggleValue));

  return [toggleValue, toggler];
}

export function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
