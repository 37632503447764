import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  CarouselProvider,
  Slide,
  Slider,
  WithStore,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { MediaAssetsType } from '../../types';
import { MultiMediaViewerConsumer } from '@prism/multimediaviewer';
import { FILMSTRIP, IMAGE_GRID } from '../../types/constants';
import Thumbnail from './Thumbnail';
import { getTabIndex, trayProps } from './utils';

const propTypes = {
  mediaAssets: MediaAssetsType,
};

const defaultProps = {
  mediaAssets: [],
};

const ImageGrid = React.forwardRef((props, ref) => {
  const { disabled, mediaAssets: propsMediaAssets } = props;
  const mediaAssets = propsMediaAssets.map((asset) => ({
    ...asset,
    id: asset.id || uuid(),
  }));
  const numberOfAssets = mediaAssets.length;
  const filmstripCarouselStore = props.carouselStore;

  const AvailablePhotos = ({
    updateCurrentSlide,
    testLoad,
    selectedFilmstripSlide,
  }) =>
    !!numberOfAssets && (
      <Slider
        className="image-grid-slider"
        classNameAnimation="image-grid-slider-no-animation"
        ref={ref}
        onLoad={testLoad({ filmstripCarouselStore })}
        disableKeyboard
        trayProps={trayProps(
          updateCurrentSlide,
          filmstripCarouselStore,
          selectedFilmstripSlide
        )}
      >
        {mediaAssets.map((mediaAsset, index) => (
          <Slide
            className="image-grid-slide"
            index={index}
            key={`${mediaAsset.id}-imgg-${index}`}
            tabIndex={getTabIndex(
              index,
              filmstripCarouselStore.getStoreState().currentSlide
            )}
          >
            <Thumbnail
              {...{
                updateCurrentSlide,
                testLoad,
                selectedFilmstripSlide,
                mediaAsset,
                disabled,
                filmstripCarouselStore,
                index,
              }}
              prefix={IMAGE_GRID}
            />
          </Slide>
        ))}
      </Slider>
    );

  return (
    <div className="image-grid">
      <MultiMediaViewerConsumer>
        {({ updateCurrentSlide, testLoad, selectedFilmstripSlide }) => (
          <>
            <AvailablePhotos
              {...{ updateCurrentSlide, testLoad, selectedFilmstripSlide }}
            />
          </>
        )}
      </MultiMediaViewerConsumer>
    </div>
  );
});

const ImageGridWithCarousel = React.forwardRef((props, ref) => {
  const mapCarouselStateToProps = (mediaAssets) => ({
    mediaAssets,
    currentSlide: 0,
    ...props,
  });

  const numberOfAssets = props.mediaAssets.length;

  const ImageGridWithCarouselState = WithStore(
    ImageGrid,
    mapCarouselStateToProps
  );

  return (
    <CarouselProvider
      className="image-grid-carousel-provider"
      naturalSlideWidth={4}
      naturalSlideHeight={3}
      visibleSlides={numberOfAssets}
      totalSlides={numberOfAssets}
      infinite="true"
      disableKeyboard={true}
      dragEnabled={false}
    >
      <ImageGridWithCarouselState />
    </CarouselProvider>
  );
});

ImageGridWithCarousel.propTypes = propTypes;
ImageGridWithCarousel.defaultProps = defaultProps;
export default ImageGridWithCarousel;
