import React from 'react';

const MediaNotAvailable = ({ classNamePrefix, mediaType, iconType }) => (
  <div className={`${classNamePrefix}-not-available`}>
    <div className="icon-wrapper">
      <i className={`icon prism-icon-${iconType}`} />
    </div>
    <div>
      <span>{`${mediaType} not available`}</span>
    </div>
  </div>
);

export default MediaNotAvailable;
