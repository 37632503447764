import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@prism/accordion';

const propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
};

function RefinementsPanel({
  children,
  id,
  title,
  onSubmit,
  heading,
  onClear,
  ...rest
}) {
  const accordionProps = { onSubmit, onClear, title, ...rest };

  return (
    <Accordion>
      <Accordion.Input {...accordionProps}>
        <div className="refinement-item__heading">{heading && heading}</div>
        {children}
      </Accordion.Input>
    </Accordion>
  );
}

RefinementsPanel.propTypes = propTypes;

export default RefinementsPanel;
