import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { CNAME_MORE } from '../CONSTANTS';
import Action from './Action';
import { useActionsContext } from '../Context';

const propTypes = {
  iconOnly: PropTypes.bool,
  onClick: PropTypes.func,
}

const defaultProps = {}

// `forwardRef` required below by Tippy.js as the `panelRef` is passed to a child
//   prop component. https://reactjs.org/docs/forwarding-refs.html

const PanelTrigger = forwardRef(({ iconOnly: propIconOnly, onClick }, ref) => {
  const [{
    iconOnly = propIconOnly,
    menuLabel,
    showPanel
  }, {
    setShowPanel
  }] = useActionsContext()

  const handlePanelTriggerClick = (e) => {
    const newShowPanel = !showPanel;

    setShowPanel && setShowPanel(newShowPanel);

    if (onClick) {
      onClick({ ...e, showPanel: newShowPanel })
    }
  }

  const DOTS = 'three-dots-vertical';
  const ARROW_UP = 'chevron-up';
  const ARROW_DOWN = 'chevron-down';

  const [showIcon, hideIcon] = iconOnly ? [DOTS, DOTS] : [ARROW_DOWN, ARROW_UP];

  return (
    <Action
      actionIndex={-1}
      className={CNAME_MORE}
      addOnAppend={showPanel ? hideIcon : showIcon}
      iconOnly={iconOnly}
      label={menuLabel}
      innerRef={ref}
      onClick={handlePanelTriggerClick}
    />
  )
})

PanelTrigger.propTypes = propTypes;
PanelTrigger.defaultProps = defaultProps;
PanelTrigger.displayName = 'PanelTrigger';

export default PanelTrigger;
