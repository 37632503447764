import React, { useState } from 'react';
import PropTypes from 'prop-types';

const YES_NO = 'YesNo';
const YES = 'YES';
const NO = 'NO';
const ON_OFF = 'OnOff';
const ON = 'ON';
const OFF = 'OFF';

const Switch = ({
  disabled,
  label,
  labelAlignment,
  onChange,
  statusText,
  ...props
}) => {
  const [status, setStatus] = useState(props.status === true);
  const [mouseOver, setMouseOver] = useState(false);
  let statusTextOptions = [];
  statusTextOptions[YES_NO] = [YES, NO];
  statusTextOptions[ON_OFF] = [ON, OFF];
  const statusTexts = statusTextOptions[statusText];
  const getStatusText = () => (status ? statusTexts[0] : statusTexts[1]);

  const labelAlign = () => {
    if (labelAlignment === 'left') return 'align-left';
    else if (labelAlignment === 'right') return 'align-right';
    else if (labelAlignment === 'top') return 'align-top';
  };

  const handleClick = () => {
    handleChange();
  };

  const handleChange = () => {
    if (disabled) return;
    setStatus(!status);
    setMouseOver(false);
    onChange && onChange();
    return status === true;
  };

  const handleOnMouseOver = () => {
    setMouseOver(true);
  };

  const handleOnMouseOut = () => {
    setMouseOver(false);
  };

  const statusTextClassNames = () => {
    let classes = '';
    if (disabled === true) classes = ' disabled ';
    else if (status === true) classes = 'bold';
    return classes;
  };

  const getMouseOverClass = () => {
    const classPrefix = 'icon prism-icon-checkmark-circle';
    return mouseOver ? classPrefix + ' is-mouse-over' : classPrefix;
  };

  return (
    <div className={`switch ${labelAlign()}`}>
      <div role="switch-label" className={`switch-label ${labelAlign()}`}>
        {label}
      </div>
      <div
        className="switch-clickable"
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      >
        <label
          role="switch-wrapper"
          className={`switch-wrapper ${labelAlign()}`}
        >
          <input
            aria-label="checkbox"
            disabled={disabled}
            type="checkbox"
            onChange={handleChange}
            checked={status}
          />
          <span className="switch-track" />
          <span className={`switch-thumb ${getMouseOverClass()}`} />
        </label>
        <label
          role="switch-status-text"
          className={`switch-status-text ${statusTextClassNames()} ${labelAlign()}`}
          onClick={handleClick}
        >
          {getStatusText()}
        </label>
      </div>
    </div>
  );
};

const propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelAlignment: PropTypes.oneOf(['top', 'left', 'right']).isRequired,
  onChange: PropTypes.func,
  status: PropTypes.bool,
  statusText: PropTypes.oneOf(['OnOff', 'YesNo']).isRequired,
};

const defaultProps = {
  labelAlignment: 'left',
  disabled: false,
  label: '',
  status: false,
  statusText: 'YesNo',
};

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;

Switch.YES_NO = YES_NO;
Switch.YES = YES;
Switch.NO = NO;
Switch.ON_OFF = ON_OFF;
Switch.ON = ON;
Switch.OFF = OFF;

export default Switch;
