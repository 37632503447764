import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Portal from '../Utilities/Portal';
import Fade from '@prism/fade';
import {
  getOriginalBodyPadding,
  conditionallyUpdateScrollbar,
  setScrollbarWidth,
  mapToCssModules,
  omit,
  focusableElements,
  TransitionTimeouts,
} from '../Utilities/utils';

const COMPONENT_CNAME = 'modal';
const BACKDROP_CNAME = `${COMPONENT_CNAME}-backdrop`;
const OPEN_CNAME = `${COMPONENT_CNAME}-open`;
const DIALOG_CNAME = `${COMPONENT_CNAME}-dialog`;
const CONTENT_CNAME = `${COMPONENT_CNAME}-content`;

function noop() {}

const FadePropTypes = PropTypes.shape(Fade.propTypes);

const keygen = (child, value) => {
  let key = child.props && child.props.key;
  if (!key) {
    key = `KEYGEN::${COMPONENT_CNAME}--${value}--${Date.now()}`;
  }
  return key;
};

const propTypes = {
  isOpen: PropTypes.bool,
  autoFocus: PropTypes.bool,
  centered: PropTypes.bool,
  size: PropTypes.string,
  toggle: PropTypes.func,
  keyboard: PropTypes.bool,
  role: PropTypes.string,
  labelledBy: PropTypes.string,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  wrapClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  backdropClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  external: PropTypes.node,
  fade: PropTypes.bool,
  cssModule: PropTypes.oneOfType([PropTypes.object]),
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backdropTransition: FadePropTypes,
  modalTransition: FadePropTypes,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
};

const propsToOmit = Object.keys(propTypes);

const defaultProps = {
  isOpen: false,
  autoFocus: true,
  centered: false,
  size: '',
  toggle: null,
  role: 'dialog',
  labelledBy: '',
  backdrop: true,
  onEnter: null,
  onExit: null,
  keyboard: true,
  zIndex: 10001,
  children: null,
  className: '',
  wrapClassName: '',
  modalClassName: '',
  backdropClassName: '',
  contentClassName: '',
  external: null,
  fade: true,
  cssModule: null,
  onOpened: noop,
  onClosed: noop,
  modalTransition: {
    timeout: TransitionTimeouts.Modal,
  },
  backdropTransition: {
    mountOnEnter: true,
    timeout: TransitionTimeouts.Fade, // uses standard fade transition
  },
  innerRef: null,
};

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.container = null;
    this.originalBodyPadding = null;
    this.getFocusableChildren = this.getFocusableChildren.bind(this);
    this.handleBackdropClick = this.handleBackdropClick.bind(this);
    this.handleBackdropMouseDown = this.handleBackdropMouseDown.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.onOpened = this.onOpened.bind(this);
    this.onClosed = this.onClosed.bind(this);

    this.state = {
      isOpen: props.isOpen,
    };

    if (props.isOpen) {
      this.init();
    }
  }

  componentDidMount() {
    const { onEnter, isOpen, autoFocus } = this.props;

    if (onEnter) {
      onEnter();
    }

    if (isOpen && autoFocus) {
      this.setFocus();
    }

    this.didMount = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props;
    if (nextProps.isOpen && !isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { isOpen } = this.state;

    if (nextState.isOpen && !isOpen) {
      this.init();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { autoFocus, zIndex } = this.props;
    const { isOpen } = this.state;

    if (autoFocus && isOpen && !prevState.isOpen) {
      this.setFocus();
    }

    if (this.container && prevProps.zIndex !== zIndex) {
      this.container.style.zIndex = zIndex;
    }
  }

  componentWillUnmount() {
    const { onExit } = this.props;
    const { isOpen } = this.state;

    if (onExit) {
      onExit();
    }

    if (isOpen) {
      this.destroy();
    }

    this.didMount = false;
  }

  onOpened(node, isAppearing) {
    const { onOpened, modalTransition } = this.props;

    onOpened();
    (modalTransition.onEntered || noop)(node, isAppearing);
  }

  onClosed(node) {
    const { onClosed, modalTransition } = this.props;

    // so all methods get called before it is unmounted
    onClosed();
    (modalTransition.onExited || noop)(node);
    this.destroy();

    if (this.didMount) {
      this.setState({ isOpen: false });
    }
  }

  setFocus() {
    if (
      this.dialog &&
      this.dialog.parentNode &&
      typeof this.dialog.parentNode.focus === 'function'
    ) {
      this.dialog.parentNode.focus();
    }
  }

  getFocusableChildren() {
    focusableElements.push(`[tabindex]:not(.${COMPONENT_CNAME})`);
    return this.container.querySelectorAll(focusableElements.join(', '));
  }

  getFocusedChild() {
    let currentFocus;
    const focusableChildren = this.getFocusableChildren();

    try {
      currentFocus = document.activeElement;
    } catch (err) {
      [currentFocus] = focusableChildren;
    }

    return currentFocus;
  }

  // not mouseUp because scrollbar fires it, shouldn't close when user scrolls
  handleBackdropClick(e) {
    const { isOpen, backdrop, toggle } = this.props;

    if (e.target === this.mouseDownElement) {
      e.stopPropagation();
      if (!isOpen || backdrop !== true) return;

      const container = this.dialog;

      if (e.target && !container.contains(e.target) && toggle) {
        toggle(e);
      }
    }
  }

  handleTab(e) {
    if (e.which !== 9) return;

    const focusableChildren = this.getFocusableChildren();
    const totalFocusable = focusableChildren.length;
    const currentFocus = this.getFocusedChild();

    let focusedIndex = -1;

    for (let i = 0; i < totalFocusable; i += 1) {
      if (focusableChildren[i] === currentFocus) {
        focusedIndex = i;
        break;
      }
    }

    focusedIndex += e.shiftKey ? focusableChildren.length - 1 : 1;

    focusedIndex %= focusableChildren.length;

    e.preventDefault();
    focusableChildren[focusedIndex].focus();
  }

  handleBackdropMouseDown(e) {
    this.mouseDownElement = e.target;
  }

  handleEscape(e) {
    const { isOpen, keyboard, toggle } = this.props;

    if (isOpen && keyboard && e.keyCode === 27 && toggle) {
      toggle(e);
    }
  }

  init() {
    try {
      this.triggeringElement = document.activeElement;
    } catch (err) {
      this.triggeringElement = null;
    }

    const { zIndex } = this.props;

    this.container = document.createElement('div');
    this.container.setAttribute('tabindex', '-1');
    this.container.style.position = 'relative';
    this.container.style.zIndex = zIndex;
    this.originalBodyPadding = getOriginalBodyPadding();

    conditionallyUpdateScrollbar();

    document.body.appendChild(this.container);

    if (Modal.openCount === 0) {
      const { cssModule } = this.props;

      document.body.className = classNames(
        document.body.className,
        mapToCssModules(OPEN_CNAME, cssModule)
      );
    }
    Modal.openCount += 1;
  }

  destroy() {
    if (this.container) {
      document.body.removeChild(this.container);
      this.container = null;
    }

    if (this.triggeringElement) {
      if (typeof this.triggeringElement.focus === 'function') {
        this.triggeringElement.focus();
      }

      this.triggeringElement = null;
    }

    if (Modal.openCount <= 1) {
      const { cssModule } = this.props;

      const modalOpenClassName = mapToCssModules(OPEN_CNAME, cssModule);

      // Use regex to prevent matching `modal-open` as part of a different
      // class, e.g. `my-modal-opened`
      const modalOpenClassNameRegex = new RegExp(
        `(^| )${modalOpenClassName}( |$)`
      );
      document.body.className = document.body.className
        .replace(modalOpenClassNameRegex, ' ')
        .trim();
    }
    Modal.openCount -= 1;

    setScrollbarWidth(this.originalBodyPadding);
  }

  renderModalDialog() {
    const {
      className,
      size,
      centered,
      cssModule,
      contentClassName,
      children,
    } = this.props;

    const attributes = omit(this.props, propsToOmit);
    const dialogBaseClass = DIALOG_CNAME;

    return (
      <div
        {...attributes}
        className={mapToCssModules(
          classNames(dialogBaseClass, className, {
            [`${dialogBaseClass}-centered`]: centered,
          }),
          cssModule
        )}
        role="document"
        ref={(c) => {
          this.dialog = c;
        }}
      >
        <div
          className={mapToCssModules(
            classNames(CONTENT_CNAME, contentClassName),
            cssModule
          )}
        >
          {children}
        </div>
      </div>
    );
  }

  render() {
    const { isOpen: stateIsOpen } = this.state;
    const { size } = this.props;

    if (stateIsOpen) {
      const {
        wrapClassName,
        modalClassName,
        backdropClassName,
        cssModule,
        isOpen,
        backdrop,
        role,
        labelledBy,
        external,
        innerRef,
        fade,
        modalTransition: propModalTransition,
        backdropTransition: propBackdropTransition,
      } = this.props;

      const modalAttributes = {
        onClick: this.handleBackdropClick,
        onMouseDown: this.handleBackdropMouseDown,
        onKeyUp: this.handleEscape,
        onKeyDown: this.handleTab,
        style: { display: 'block' },
        'aria-labelledby': labelledBy,
        role,
        tabIndex: '-1',
      };

      const hasTransition = fade;
      const modalTransition = {
        ...Fade.defaultProps,
        ...propModalTransition,
        baseClass: hasTransition ? propModalTransition.baseClass : '',
        timeout: hasTransition ? propModalTransition.timeout : 0,
      };
      const backdropTransition = {
        ...Fade.defaultProps,
        ...propBackdropTransition,
        baseClass: hasTransition ? propBackdropTransition.baseClass : '',
        timeout: hasTransition ? propBackdropTransition.timeout : 0,
      };

      const Backdrop =
        backdrop &&
        (hasTransition ? (
          <Fade
            {...backdropTransition}
            in={isOpen && !!backdrop}
            cssModule={cssModule}
            className={mapToCssModules(
              classNames(BACKDROP_CNAME, backdropClassName),
              cssModule
            )}
          />
        ) : (
          <div
            className={mapToCssModules(
              classNames(BACKDROP_CNAME, 'show', backdropClassName),
              cssModule
            )}
          />
        ));

      return (
        <Portal node={this.container}>
          <div className={mapToCssModules(wrapClassName)}>
            <Fade
              {...modalAttributes}
              {...modalTransition}
              in={isOpen}
              onEntered={this.onOpened}
              onExited={this.onClosed}
              cssModule={cssModule}
              className={mapToCssModules(
                classNames(COMPONENT_CNAME, modalClassName, {
                  [`${COMPONENT_CNAME}-${size}`]: size,
                }),
                cssModule
              )}
              innerRef={innerRef}
            >
              {external}
              {this.renderModalDialog()}
            </Fade>
            {Backdrop}
          </div>
        </Portal>
      );
    }

    return null;
  }
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
Modal.openCount = 0;

Modal.COMPONENT_CNAME = COMPONENT_CNAME;
Modal.BACKDROP_CNAME = BACKDROP_CNAME;
Modal.OPEN_CNAME = OPEN_CNAME;
Modal.DIALOG_CNAME = DIALOG_CNAME;
Modal.CONTENT_CNAME = CONTENT_CNAME;

export default Modal;
