import React from 'react';
import classNames from 'classnames';
import TooltipPopoverWrapper, { propTypes } from './TooltipPopoverWrapper';

const defaultProps = {
  placement: 'bottom',
  autohide: true,
  placementPrefix: 'bs-tooltip',
  trigger: 'hover focus',
  delay: { show: 150, hide: 75 },
  animation: true,
  size: 'sm',
  targetIsFunctional: false,
  sandbox: false,
};

const Tooltip = (props) => {
  const popperClasses = classNames('tooltip', 'show');

  const classes = classNames('tooltip-inner', props.innerClassName);

  const className =
    (props.className || '') + (props.size ? ` tooltip-${props.size}` : null);

  return (
    <TooltipPopoverWrapper
      {...props}
      className={className}
      popperClassName={popperClasses}
      innerClassName={classes}
    />
  );
};

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
