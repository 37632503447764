import React from 'react';
import { LEFT_ARROW, PREVIOUS } from '../../types/constants';

export default ({ disabled, selectedFilmstripSlide, updateCurrentSlide }) => (
  <button
    onClick={() => updateCurrentSlide(selectedFilmstripSlide + PREVIOUS)}
    onKeyDown={(e) => {
      if (e.keyCode === LEFT_ARROW) {
        updateCurrentSlide(selectedFilmstripSlide + PREVIOUS);
      }
    }}
    disabled={disabled}
    className="filmstrip-prev"
    role="backbutton"
    aria-roledescription="Previous Slide in filmstrip"
  >
    <span className="icon prism-icon-chevron-left-light" />
  </button>
);
