import { useState } from 'react';

function useListState(initialState) {
  const [list, setList] = useState({ items: initialState });
  const onMount = initialState.length > 0;

  const toggleAll = () => {
    const newList = list.map((l) => {
      l.checked = !l.checked;
      return l;
    });
    setList(newList);
  };

  const setSelectAll = () => {
    const allSelect = list.items.map((l) => {
      l.checked = true;
      return l;
    });
    return setList((list) => ({ ...list, items: allSelect }));
  };
  const deselectAll = () => {
    const allDeselect = list.items.map((l) => {
      l.checked = false;
      return l;
    });
    return setList((list) => ({ ...list, items: allDeselect }));
  };
  const setListItems = (items) => {
    setList((list) => ({ ...list, items: items }));
  };

  const handleCheckChange = (e) => {
    const { checked } = e.target;
    const { label } = e.targetObj;
    const newList = list.items.map((l) => {
      if (l.label === label) {
        l.checked = checked;
      }
      return l;
    });
    return setList((list) => ({ ...list, items: newList }));
  };

  return {
    listItems: list.items,
    deselectAll,
    setSelectAll,
    setListItems,
    handleCheckChange,
    onMount,
  };
}

export default useListState;
