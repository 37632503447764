import React from 'react';
import Icon from '@prism/icon';

export default ({
  fullScreenHidden,
  handleFullScreenClick,
  handleExitFullScreenClick,
}) => (
  <button
    className="no-button-styling media-button enlarge-button"
    onClick={
      fullScreenHidden ? handleFullScreenClick : handleExitFullScreenClick
    }
  >
    <Icon glyph={fullScreenHidden ? 'enlarge' : 'shrink'} />
  </button>
);
