import Accordion from './Accordion';
import AccordionInput from './AccordionInput';
import AccordionItem from './AccordionItem';
import AccordionItemProvider from './AccordionItemProvider';
import AccordionTrigger from './AccordionTrigger';

Accordion.Input = AccordionInput;
Accordion.Item = AccordionItem;
Accordion.ItemProvider = AccordionItemProvider;
Accordion.Trigger = AccordionTrigger;

export {
  AccordionInput,
  AccordionItem,
  AccordionItemProvider,
  AccordionTrigger,
};

export default Accordion;
