import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import Chip from '@prism/chip';
import SlideOut from '@prism/slideout';

const numberWithCommas = (x) => {
  if (!x || !x.toString) {
    return x;
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  labelIcon: PropTypes.string,
  selections: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  viewName: PropTypes.string,
  multiselect: PropTypes.bool,
  options: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  label: '',
  className: '',
  labelIcon: '',
  selections: [],
  required: false,
  disabled: false,
  viewName: '',
  multiselect: false,
  options: [],
  onChange: null,
};

function AdaptiveSelect({
  label,
  className,
  labelIcon,
  selections,
  required,
  disabled,
  viewName,
  multiselect,
  options,
  onChange,
}) {
  const [slideoutOpen, setSlideoutOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(
    Array.isArray(selections) ? selections : [selections]
  );
  const [hasError, setHasError] = React.useState(
    required && !selections.length ? true : false
  );
  const [sendValues, setSendValues] = React.useState(false);

  const toggleSlideout = () => setSlideoutOpen(!slideoutOpen);

  const toggleSelected = (value) => {
    let new_selected = selected.concat();

    if (!multiselect) {
      new_selected = [value];
    } else if (selected.indexOf(value) >= 0) {
      new_selected.splice(selected.indexOf(value), 1);
    } else {
      new_selected.push(value);
    }

    if (hasError && new_selected.length) {
      setHasError(false);
    }

    setSelected(new_selected);
  };

  const changeHandler = () => {
    setSendValues(true);
  };

  const getValues = React.useCallback(
    (selected) => {
      let values = [];

      selected.forEach((selected_option) => {
        let value = selected_option;
        let index = -1;

        options.forEach((option, i) => {
          if (option.label === selected_option) {
            index = i;
          }
        });

        if (index >= 0) {
          value = options[index].value || value;
        }

        values.push(value);
      });

      return values;
    },
    [options]
  );

  const clearSelected = () => {
    setSelected([]);
  };

  const selectAll = () => {
    let new_selected = [];

    options.forEach((option) => {
      new_selected.push(option.label);
    });

    setSelected(new_selected);
  };

  React.useEffect(() => {
    if (sendValues && typeof onChange === 'function') {
      onChange.call(null, getValues(selected));
      setSendValues(false);
    }
  }, [sendValues, onChange, selected, getValues]);

  return (
    <div
      className={`adaptive-select ${disabled ? 'disabled' : ''} ${
        className ? className : ''
      }`}
    >
      <div
        className="adaptive-select-actions d-flex justify-content-between align-items-center"
        onClick={() => {
          if (!disabled) {
            toggleSlideout();
          }
        }}
      >
        <span className="adaptive-select-label mr-3">
          {labelIcon ? (
            <i className={`icon prism-icon-${labelIcon} mr-3`} />
          ) : null}

          {label}
        </span>
        <div
          className={`d-flex align-items-center adaptive-select-selection-block ${
            hasError ? 'has-error' : ''
          }`}
        >
          {hasError ? (
            <React.Fragment>
              <i className="icon prism-icon-error-triangle mr-2" />
              <span className="adaptive-select-selection">Required</span>
            </React.Fragment>
          ) : null}

          <span className="adaptive-select-selection">
            {selected && selected.length === 1 ? selected[0] : null}

            {selected && selected.length > 1
              ? `${selected.length} selections`
              : null}
          </span>

          <i className="icon prism-icon-chevron-right-light ml-3" />
        </div>
      </div>

      <SlideOut
        actionable
        setToggle={toggleSlideout}
        open={slideoutOpen}
        placement="right"
      >
        <div className="d-flex p-3 bg-light justify-content-between align-items-center">
          <div className="flex-1 mr-2">
            <Button
              textonly
              size="sm"
              onClick={() => {
                toggleSlideout();
                changeHandler();
              }}
            >
              <i className="icon prism-icon-chevron-left-light mr-2" />
              {viewName && viewName.length <= 12 ? viewName : 'Back'}
            </Button>
          </div>

          <h5 className="text-center mb-0">{label}</h5>

          <div className="flex-1 ml-2 text-right">
            {multiselect ? (
              <Button
                textonly
                size="sm"
                onClick={() => {
                  toggleSlideout();
                  changeHandler();
                }}
              >
                Done
              </Button>
            ) : null}
          </div>
        </div>

        <div className="adaptive-select-options">
          {multiselect ? (
            <div>
              {selected.length === options.length ? (
                <div
                  className="adaptive-select-option d-flex justify-content-between align-items-center border-bottom-0"
                  onClick={() => {
                    clearSelected();
                  }}
                >
                  <span className="option-label">Clear All</span>
                </div>
              ) : (
                <div
                  className="adaptive-select-option d-flex justify-content-between border-bottom-0"
                  onClick={() => {
                    selectAll();
                  }}
                >
                  <span className="option-label">Select All</span>
                </div>
              )}
            </div>
          ) : null}

          {options.map((option, i) => (
            <div
              className="adaptive-select-option d-flex justify-content-between"
              key={i}
              onClick={() => {
                toggleSelected(option.label);
                if (!multiselect) {
                  toggleSlideout();
                  changeHandler();
                }
              }}
            >
              <span className="option-label">{option.label}</span>

              <div className="option-decorators">
                {!isNaN(option.count) ? (
                  <span className="ml-3">
                    <Chip
                      color="medium"
                      label={numberWithCommas(option.count)}
                    />
                  </span>
                ) : null}

                <i
                  className={`icon prism-icon-checkmark ml-3 option-check ${
                    selected.indexOf(option.label) >= 0 ? 'selected' : ''
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      </SlideOut>
    </div>
  );
}

AdaptiveSelect.propTypes = propTypes;
AdaptiveSelect.defaultProps = defaultProps;

export default AdaptiveSelect;
