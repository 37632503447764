export const CNAME = 'interstitial';

export const COLOR_LIGHT = 'light';
export const COLOR_DARK = 'dark';

export const STATE_STOPPED = 'STATE_STOPPED';
export const STATE_RUNNING = 'STATE_RUNNING';
export const STATE_TIMED_OUT = 'STATE_TIMED_OUT';
export const STATE_ANIMATED = 'STATE_ANIMATED';
export const STATE_STATIC = 'STATE_STATIC';

export const LABEL = 'Loading';
export const LABEL_ADA = 'One Moment';
