import {
  parse,
  set as setDate,
  isDate,
  min as dateMin,
  max as dateMax,
} from 'date-fns';
import { UNDEFINED } from './CONSTANTS';

/**
 * Array of strings used to parse the time string provided
 */
const formats = [
  'ha',
  'hha',
  'haaaa',
  'hhaaaa',
  'haaaaa',
  'hhaaaaa',
  'h:mma',
  'hh:mma',
  'h:mmaaaa',
  'hh:mmaaaa',
  'h:mmaaaaa',
  'hh:mmaaaaa',
  'h:mm a',
  'hh:mm a',
  'h:mm aaaa',
  'hh:mm aaaa',
  'h:mm aaaaa',
  'hh:mm aaaaa',
  'HH:mm',
  'H:m',
  'H:mm',
  'HH:m',
];

export const parseTime = (time) => {
  let parsed = null;

  formats.some((format) => {
    let d = new Date();

    try {
      parsed = parse(time, format, d);

      if (d instanceof Date && !isNaN(parsed)) {
        return true;
      }
    } catch (err) {}

    parsed = null;
    return false;
  });

  return parsed;
};

/**
 * Converts `time` to a Date object
 *
 * @param {Date | string} date
 * @returns Date
 */
export const toTime = (time) => {
  if (time === UNDEFINED) {
    return new Date();
  }

  if (time instanceof Date) {
    return new Date(time);
  }

  const parsed = parseTime(time);
  if (parsed) {
    return parsed;
  }

  const newTime = new Date(time);
  return newTime && !isNaN(newTime) ? newTime : new Date();
};

/**
 * Returns a new Date object from the initialTime modified by the targetTime
 *   values where config determines which values are available.
 * @param {Date} targetTime time to clone values from into the initialTime
 * @param {*} initialTime time to augment with target values
 * @param {object} config values to modify the initialTime with
 * @returns Date | null
 */
export const generateTime = (
  targetTime,
  initialTime,
  config = {
    hours: true,
    minutes: true,
    seconds: true,
  }
) => {
  if (!isDate(targetTime)) {
    if (!isDate(initialTime)) {
      return null;
    }
    return initialTime;
  }

  return setDate(toTime(initialTime), {
    hours: config.hours ? targetTime.getHours() : null,
    minutes: config.minutes ? targetTime.getMinutes() : null,
    seconds: config.seconds ? targetTime.getSeconds() : null,
  });
};

/**
 * Constrain the date between the min and maximum date
 * @param {Date} date
 * @param {object} options
 * @param {Date} options.min minimum date
 * @param {Date} options.max maximum date
 * @returns
 */
export const constrainTime = (
  time,
  { min = new Date('1/1/0100 0:0'), max = new Date('1/1/0100 23:59:59') }
) => {
  const madeTime = toTime(time);
  const minTime = generateTime(toTime(min), madeTime);
  const maxTime = generateTime(toTime(max), madeTime);

  return dateMin([dateMax([madeTime, minTime]), maxTime]);
};
