import React, { createContext, useContext } from 'react';
import useImageViewer, { initialState } from './useImageViewer';

const Context = createContext([initialState, {}]);
export const useImageViewerContext = () => useContext(Context);

export const Provider = ({ children, ...defaultState }) => {
  const [state, fns] = useImageViewer(defaultState);
  const props = { children };
  return <Context.Provider {...props} value={[state, fns]} />;
};

export default Context;
