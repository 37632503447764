import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  fade: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
};

const defaultProps = {
  tag: 'div',
  active: false,
  disabled: false,
  fade: true,
  id: null,
  label: null,
  className: null,
};

const TabPane = (props) => {
  const {
    className,
    active,
    disabled,
    fade,
    id,
    label,
    tag: Tag,
    ...attributes
  } = props;

  const classes = classNames('tab-pane', className, {
    active,
    show: !!active,
    disabled,
    fade,
  });

  return <Tag {...attributes} className={classes} id={id} />;
};

TabPane.propTypes = propTypes;
TabPane.defaultProps = defaultProps;

export default TabPane;
