import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Mount from './Mount';
import Panel from './Panel';

import { CNAME, COLOR_LIGHT, COLOR_DARK } from '../CONSTANTS';

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([COLOR_DARK, COLOR_LIGHT]),
  displayDelay: PropTypes.number,
  inline: PropTypes.bool,
  isActive: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  target: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
    PropTypes.node,
  ]),
};

const defaultProps = {
  className: null,
  color: COLOR_DARK,
  displayDelay: 2000,
  inline: false,
  isActive: true,
};

const Interstitial = ({
  className: propClassName,
  color,
  displayDelay,
  inline,
  isActive,
  size,
  target,
  ...props
}) => {
  const delayTimerRef = useRef(null);
  const targetRef = (target && !target.tagName) ? target : useRef(target || null);
  const [canDisplay, setCanDisplay] = useState(!displayDelay);
  const [container, setContainer] = useState(null);
  const [isContained, setIsContained] = useState(false);

  const clearTimers = () => {
    if (delayTimerRef.current) {
      clearTimeout(delayTimerRef.current);
      delayTimerRef.current = null;
    }
  }

  // delay displaying component
  useEffect(() => {
    if (!isActive) { return }

    if (displayDelay) {
      clearTimers();

      delayTimerRef.current = setTimeout(() => {
        setCanDisplay(true);
      }, displayDelay);
    }

    return () => {
      clearTimers();
      setCanDisplay(!displayDelay);
    };
  }, [setCanDisplay, isActive, color, size])

  const unmount = () => {
    setIsContained(false);
    setContainer(null);
  }

  const mount = () => {
    if (!target || !targetRef.current) return;

    if (isContained) {
      unmount();
    }

    setContainer(targetRef.current);
    setIsContained(true);
  }

  // mount and unmount, only when target available
  useEffect(() => {
    if (!target) { return }

    if (isActive) {
      mount();
    } else {
      unmount();
    }

    return () => {
      unmount();
    };
  }, [mount, unmount, target, targetRef, isActive])

  const getSize = () => {
    if (size) {
      return size;
    }

    if (target) {
      if (
        (typeof document !== 'undefined' && target === document.body) ||
        (typeof document !== 'undefined' && target === document.querySelector('html'))
      ) {
        return 'xl';
      }

      return 'lg';
    }

    if (inline) {
      return 'xs';
    }

    return 'md';
  }

  const contentProps = {
    ...props,
    color,
    inline,
    size: getSize(),
  };

  const className = classnames(propClassName, {
    [CNAME]: CNAME,
    [`${CNAME}-dark`]: color === COLOR_DARK,
    [`${CNAME}-inline`] : inline,
    [`${CNAME}-${contentProps.size}`]: size,
  });

  return isActive && canDisplay
    ? container
      ?
        <Mount container={container}>
          <Panel {...contentProps} className={className} showMask />
        </Mount>
      :
        <Panel {...contentProps} className={className} showMask={false} />
    : null;
}

Interstitial.propTypes = propTypes;
Interstitial.defaultProps = defaultProps;
Interstitial.displayName = 'Interstitial';

export default Interstitial;
