import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import TabBar from './TabBar';
import TabItem from './TabItem';
import TabPane from './TabPane';

export const COLOR_LIGHT = 'light';
export const COLOR_DARK = 'dark';

const POSITION_TOP = 'top';
export const POSITION_BOTTOM = 'bottom';

const SIZE_MD = 'md';
const SIZE_SM = 'sm';

const DISPLAY_TEXT = 'text';
const DISPLAY_ICON = 'icon';

export const positionNames = {
  [POSITION_TOP]: 'above',
  [POSITION_BOTTOM]: 'below',
};

const propTypes = {
  color: PropTypes.oneOf([COLOR_LIGHT, COLOR_DARK]),
  position: PropTypes.oneOf([POSITION_TOP, POSITION_BOTTOM]),
  activeIndex: PropTypes.number,
  size: PropTypes.oneOf([SIZE_MD, SIZE_SM]),
  display: PropTypes.oneOf([DISPLAY_TEXT, DISPLAY_ICON]),
  onChange: PropTypes.func,
  maximizeTabWidth: PropTypes.bool,
  transparentBackground: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
};

const defaultProps = {
  color: COLOR_LIGHT,
  position: POSITION_TOP,
  activeIndex: 0,
  size: SIZE_MD,
  display: DISPLAY_TEXT,
  onChange: null,
  maximizeTabWidth: false,
  transparentBackground: false,
  className: null,
  id: null,
};

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.drop = this.drop.bind(this);
    this.createChildrenFromData = this.createChildrenFromData.bind(this);
    this.normalizeChildren = this.normalizeChildren.bind(this);

    this.state = {
      activeIndex: this.props.activeIndex,
      activeIndexFromProps: this.props.activeIndex,
      isDrop: false,
    };

    if (props.data) {
      this.content = this.createChildrenFromData(props.data);
    } else {
      this.content = this.normalizeChildren(this.props.children);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeIndex: activeIndexFromProps, onChange } = this.props;
    const { activeIndex: activeIndexFromState } = this.state;

    if (prevProps.activeIndex !== activeIndexFromProps) {
      this.setState({ activeIndex: activeIndexFromProps });
    }
    if (onChange && prevState.activeIndex !== activeIndexFromState) {
      onChange({
        prevActiveIndex: prevState.activeIndex,
        activeIndex: activeIndexFromState,
      });
    }
  }

  handleClick(e) {
    const { activeIndex } = this.state;

    const index = this.getIndex(e.target, '.nav-item');

    if (index !== null && activeIndex !== index) {
      this.setState({
        activeIndex: index,
      });
    }
  }

  getIndex(node, selector) {
    let workingNode = node;

    while (workingNode && !workingNode.matches(selector)) {
      workingNode = workingNode.parentNode;
    }

    if (workingNode && workingNode.matches(selector)) {
      let index = 0;

      while (workingNode.previousSibling) {
        index++;
        workingNode = workingNode.previousSibling;
      }

      return index;
    }

    return null;
  }

  drop() {
    this.setState({
      isDrop: !this.state.isDrop,
    });
  }

  createChildrenFromData(data) {
    const { activeIndex } = this.state;

    const tabs = data.map(
      (
        {
          tabId,
          isDisabled,
          label,
          id,
          className,
          tabClassName,
          ...attributes
        },
        index
      ) => (
        <TabItem
          {...attributes}
          id={tabId && tabId.toString()}
          className={tabClassName}
          onClick={this.handleClick}
          disabled={isDisabled}
          active={activeIndex === index}
          label={typeof label === 'object' ? label.text : label}
          icon={label.icon || null}
          key={uuid()}
        />
      )
    );

    const panels = data.map(
      ({ id, content, tag, className, disabled }, index) => (
        <TabPane
          id={id && id.toString()}
          active={activeIndex === index}
          key={uuid()}
          tag={tag}
          className={className}
          disabled={disabled}
        >
          {content}
        </TabPane>
      )
    );

    return {
      tabs,
      panels,
    };
  }

  normalizeChildren(children) {
    const { activeIndex } = this.props;

    if (children && children.length) {
      let tabs = [];
      let panels = [];

      React.Children.map(children, (child) => {
        if (child.type === TabPane) {
          const tabIndex = tabs.length;
          const tabProps = Object.assign({}, child.props, {
            children: null,
            active: activeIndex === tabIndex,
            onClick: this.handleClick,
          });

          tabs.push(<TabItem {...tabProps} key={uuid()} />);
          panels.push(React.cloneElement(child, { key: uuid() }));
        }
      });

      return { tabs, panels };
    }

    return children;
  }

  render() {
    const {
      color,
      position,
      size,
      display,
      maximizeTabWidth,
      transparentBackground,
      className: classNameFromProps,
      id,
    } = this.props;
    const { activeIndex } = this.state;

    const { tabs: contentTabs = [], panels: contentPanels = [] } =
      this.content || {};

    const tabs = contentTabs.map((tab, index) =>
      React.cloneElement(tab, { active: activeIndex === index })
    );

    const panels = contentPanels.map((panel, index) =>
      React.cloneElement(panel, { active: activeIndex === index })
    );

    const tabBar = <TabBar display={display}>{tabs}</TabBar>;
    const tabPanes = <div className="tab-content">{panels}</div>;

    const tabsTop = (
      <React.Fragment>
        {tabBar}
        {tabPanes}
      </React.Fragment>
    );
    const tabsBottom = (
      <React.Fragment>
        {tabPanes}
        {tabBar}
      </React.Fragment>
    );

    const className = [
      classNameFromProps,
      'tabs',
      `tabs-${color}`,
      `tabs-${positionNames[position]}`,
      `tabs-${size}`,
      `tabs-${display}`,
      maximizeTabWidth ? 'tabs-maximize-tab-width' : '',
      transparentBackground ? 'tabs-transparent-bg' : '',
    ]
      .join(' ')
      .trim();

    return (
      <div className={className} id={id}>
        {position === POSITION_BOTTOM ? tabsBottom : tabsTop}
      </div>
    );
  }
}

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

Object.defineProperty(Tabs, 'COLOR_LIGHT', {
  value: COLOR_LIGHT,
  writable: false,
});
Object.defineProperty(Tabs, 'COLOR_DARK', {
  value: COLOR_DARK,
  writable: false,
});
Object.defineProperty(Tabs, 'POSITION_TOP', {
  value: POSITION_TOP,
  writable: false,
});
Object.defineProperty(Tabs, 'POSITION_BOTTOM', {
  value: POSITION_BOTTOM,
  writable: false,
});
Object.defineProperty(Tabs, 'SIZE_MD', {
  value: SIZE_MD,
  writable: false,
});
Object.defineProperty(Tabs, 'SIZE_SM', {
  value: SIZE_SM,
  writable: false,
});

export default Tabs;
