import React from 'react';
import Tooltip from '@prism/tooltip';

class TooltipWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.triggerRef = React.createRef();

    this.state = {
      tooltipShown: false,
    };

    this.toggleTooltip = () => {
      this.setState({ tooltipShown: !this.state.tooltipShown });
    };
  }

  render() {
    return (
      <div
        className="TooltipWrapper"
        onMouseEnter={this.toggleTooltip}
        onMouseLeave={this.toggleTooltip}
        ref={this.triggerRef}
      >
        {this.props.children}
        <Tooltip
          size="lg"
          target={this.triggerRef.current}
          isOpen={this.state.tooltipShown}
        >
          {this.props.children}
        </Tooltip>
      </div>
    );
  }
}

export default TooltipWrapper;
