import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';

const propTypes = {
  sortFields: PropTypes.array,
  activeSortField: PropTypes.object,
  dataRows: PropTypes.array,
  sortedCallback: PropTypes.func,
  sortOrder: PropTypes.string,
};

const defaultProps = {
  sortFields: [],
  activeSortField: null,
  dataRows: [],
  sortedCallback: null,
  sortOrder: 'ASC',
};

function SingleSort({
  sortFields,
  activeSortField,
  dataRows,
  sortedCallback,
  sortOrder,
}) {
  const [sortOpen, setSortOpen] = React.useState(false);
  const [currentSortOrder, setSortOrder] = React.useState(sortOrder);
  const [sortBy, setSortBy] = React.useState(
    activeSortField || (sortFields.length ? sortFields[0] : null)
  );

  const onToggleSortPopup = (e) => {
    e && e.preventDefault();

    setSortOpen(!sortOpen);
  };

  const setSortOptions = (index, direction) => {
    setSortBy(sortFields[index]);
    setSortOrder(direction);

    if (typeof sortedCallback === 'function') {
      sortedCallback(sortRows(dataRows, sortFields[index], direction));
    }
  };

  const sortRows = (rows, sortBy, currentSortOrder) => {
    if (!sortBy || !rows.length) {
      return rows;
    }

    let sortedRows;

    if (sortBy.sorttype && sortBy.sorttype === 'number') {
      sortedRows = rows.sort((a, b) => {
        let rawA =
          a.props.children[sortBy.index].props.sortvalue ||
          a.props.children[sortBy.index].props.children;
        let valueA = Number.parseFloat(rawA);
        if (Number.isNaN(valueA)) {
          valueA = 0;
        }

        let rawB =
          b.props.children[sortBy.index].props.sortvalue ||
          b.props.children[sortBy.index].props.children;
        let valueB = Number.parseFloat(rawB);
        if (Number.isNaN(valueB)) {
          valueB = 0;
        }

        if (valueA - valueB && currentSortOrder === 'ASC') {
          return valueA - valueB;
        }

        if (valueB - valueA && currentSortOrder === 'DESC') {
          return valueA - valueB;
        }

        return 0;
      });
    } else {
      sortedRows = rows.sort((a, b) => {
        let rawA =
          a.props.children[sortBy.index].props.sortvalue ||
          a.props.children[sortBy.index].props.children;
        let valueA = rawA.toUpperCase ? rawA.toUpperCase() : '';

        let rawB =
          b.props.children[sortBy.index].props.sortvalue ||
          b.props.children[sortBy.index].props.children;
        let valueB = rawB.toUpperCase ? rawB.toUpperCase() : '';

        if (valueA < valueB && currentSortOrder === 'ASC') {
          return -1;
        }
        if (valueA > valueB && currentSortOrder === 'ASC') {
          return 1;
        }

        if (valueA > valueB && currentSortOrder === 'DESC') {
          return -1;
        }
        if (valueA < valueB && currentSortOrder === 'DESC') {
          return 1;
        }
        return 0;
      });
    }

    return sortedRows;
  };

  return (
    <div className="text-sm sortControls popup-container mb-0 single-sort">
      <span className="mr-2">Sort by</span>
      {sortBy ? (
        <Button actionLink onClick={onToggleSortPopup}>
          {sortBy.label}
          <i
            className={`mr-2 ml-2 icon prism-icon-arrow-${
              currentSortOrder === 'ASC' ? 'up' : 'down'
            }`}
          />
        </Button>
      ) : null}

      <div
        className={`popup-overlay ${sortOpen ? 'open' : ''}`}
        onClick={onToggleSortPopup}
      ></div>

      <div className={`popup ${sortOpen ? 'open' : ''}`}>
        {sortFields.map((field, i) => {
          return (
            <div
              key={i}
              className={`d-flex flex-row justify-content-between align-items-center ${
                i > 0 ? 'mt-3' : ''
              }`}
            >
              <p className="para-md m-0 pr-5 text-nowrap">{field.label}</p>
              <div>
                <ButtonGroup className="m-0">
                  <Button
                    icononly
                    outline
                    size="sm"
                    active={
                      sortBy.index === field.index && currentSortOrder === 'ASC'
                    }
                    onClick={(e) => {
                      onToggleSortPopup(e);
                      setSortOptions(field.index, 'ASC');
                    }}
                  >
                    <i className="mr-2 ml-2 icon prism-icon-arrow-up" />
                  </Button>

                  <Button
                    icononly
                    outline
                    size="sm"
                    active={
                      sortBy.index === field.index &&
                      currentSortOrder === 'DESC'
                    }
                    onClick={(e) => {
                      onToggleSortPopup(e);
                      setSortOptions(field.index, 'DESC');
                    }}
                  >
                    <i className="mr-2 ml-2 icon prism-icon-arrow-down" />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

SingleSort.propTypes = propTypes;
SingleSort.defaultProps = defaultProps;

export default SingleSort;
