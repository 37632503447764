import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import AccordionItem from './AccordionItem';
import AccordionTrigger from './AccordionTrigger';
import AccordionInput from './AccordionInput';
import { AccordionProvider } from './AccordionCtx';

const propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
};

const defaultProps = {
  open: false,
};

function Accordion({ children, open }) {
  const [openIndex, setOpenIndex] = useState();

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        defaultOpen: open || child.props.defaultOpen,
        id: uuid(),
      });
    });
  };

  return <AccordionProvider>{renderChildren()}</AccordionProvider>;
}

Accordion.Item = AccordionItem;
Accordion.Input = AccordionInput;
Accordion.Trigger = AccordionTrigger;
Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export default Accordion;
