import {
  parse,
  set as setDate,
  isDate,
  min as dateMin,
  max as dateMax,
} from 'date-fns';
import { UNDEFINED } from './CONSTANTS';
/**
 * Array of strings used to parse the date string provided
 */
const formats = [
  'MM/dd/yy',
  'MM/dd/yyyy',
  'dd/MM/yy',
  'dd/MM/yyyy',
  'MMM d, yyyy',
  'MMM dd, yyyy',
  'MMMM d, yyyy',
  'MMMM dd, yyyy',
  'MMM d',
  'MMM dd',
  'MMMM d',
  'MMMM dd',
];

/**
 * Transforms the provided `date` to a best possible Date object
 * @param {Date | string} date
 * @returns Date | null
 */
const parseDate = (date) => {
  const d = new Date();

  let parsed = null;

  formats.some((format) => {
    try {
      parsed = parse(date, format, d);
      if (d instanceof Date && !isNaN(parsed)) {
        return true;
      }
    } catch (err) {}

    parsed = null;
    return false;
  });

  return parsed;
};

/**
 * Converts `date` to a Date object
 *
 * @param {Date | string} date
 * @returns Date
 */
export const toDate = (date) => {
  if (date === UNDEFINED) {
    return new Date();
  }

  if (date instanceof Date) {
    return new Date(date);
  }

  return parseDate(date) || date ? new Date(date) : new Date();
};

/**
 * Constrain the date between the min and maximum date
 * @param {Date} date
 * @param {object} options
 * @param {Date} options.min minimum date
 * @param {Date} options.max maximum date
 * @returns
 */
export const constrainDate = (
  date,
  { min = new Date(-8640000000000000), max = new Date(8640000000000000) }
) => {
  return dateMin([dateMax([date, min]), max]);
};

/**
 * Fixes the date to the proper value
 * @param {object} options
 * @param {Date} options.date
 * @param {bool} options.isMonthPicker
 * @param {Date} options.lastSelectedDate
 * @param {Date} options.minSelectableDate
 * @returns
 */
export const normalizeDate = ({
  date,
  isMonthPicker,
  lastSelectedDate,
  minSelectableDate,
}) => {
  let newDate;

  if (isMonthPicker) {
    newDate = generateDate(date, lastSelectedDate);
    return constrainDate(newDate, { min: minSelectableDate });
  }

  return toDate(date);
};

/**
 * Returns a new Date object from the initialDate modified by the targetDate
 *   values where config determines which values are available.
 * @param {Date} targetDate date to clone values from into the initialDate
 * @param {*} initialDate date to augment with target values
 * @param {object} config values to modify the initialDate with
 * @returns Date | null
 */
export const generateDate = (
  targetDate,
  initialDate,
  config = { month: true, year: true }
) => {
  if (!isDate(targetDate)) {
    if (!isDate(initialDate)) {
      return null;
    }
    return initialDate;
  }

  return setDate(new Date(initialDate), {
    day: config.day ? targetDate.getDay() : null,
    month: config.month ? targetDate.getMonth() : null,
    year: config.year ? targetDate.getFullYear() : null,
    hours: config.hours ? targetTime.getHours() : null,
    minutes: config.minutes ? targetTime.getMinutes() : null,
    seconds: config.seconds ? targetTime.getSeconds() : null,
  });
};
