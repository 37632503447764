import React from 'react';

import Button from '@prism/button';
import ButtonGroup from '@prism/buttongroup';
import Pagination from '@prism/pagination';
import SingleSort from '@prism/single-sort';
import TooltipWrapper from './TooltipWrapper';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHead: props.tableHead || [],
      tableRows: props.tableRows || [],
      tableFoot: props.tableFoot || [],
      tableControls: props.tableControls || null,
      bodyChildren: [],
      headerChildren: [],
      currentPage: 1,
      totalPages: 0,
      visibleRows: [],
      paginated: props.paginated || false,
      perPageOptions: props.perPageOptions || [10, 25, 50],
      sortFields: [],
      rowsPerPage:
        props.perPageOptions && props.perPageOptions.length
          ? props.perPageOptions[0]
          : 10,
    };

    // callback for setting current pagination
    this.onSetPage = this.setPage.bind(this);

    // callback for setting rowsperpage
    this.onSetRowsPerPage = this.setRowsPerPage.bind(this);
  }

  componentDidMount() {
    this.composeTable();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.composeTable();
    }
  }

  composeTable() {
    let currentState = Object.assign({}, this.state);
    currentState = this.parseTableHeader(currentState);
    currentState = this.parseTableRows(currentState);
    currentState = this.setUpPagination(currentState);
    currentState = this.setVisibleRows(currentState);

    this.setState(currentState);
  }

  setUpPagination(currentState) {
    let totalPages = this.state.paginated
      ? Math.ceil(currentState.bodyChildren.length / this.state.rowsPerPage)
      : 0;
    return Object.assign({}, currentState, {
      totalPages: totalPages,
    });
  }

  onSortRows(sorted_rows) {
    this.setState(
      this.setVisibleRows({
        ...this.state,
        bodyChildren: sorted_rows,
        currentPage: 1,
      })
    );
  }

  setRowsPerPage(rows) {
    this.setState(
      {
        rowsPerPage: rows,
        currentPage: 1,
      },
      () => {
        this.composeTable();
      }
    );
  }

  setPage(page) {
    if (this.state.bodyChildren[page]) {
      this.setState(
        this.setVisibleRows(
          Object.assign({}, this.state, {
            currentPage: page,
          })
        )
      );
    }
  }

  setVisibleRows(currentState) {
    if (currentState.tableRows && currentState.tableRows.length) {
      return Object.assign({}, currentState, {
        visibleRows: currentState.tableRows,
      });
    } else {
      const { totalPages, bodyChildren, currentPage } = currentState;

      return Object.assign({}, currentState, {
        visibleRows: totalPages
          ? bodyChildren.slice(
              (currentPage - 1) * this.state.rowsPerPage,
              (currentPage - 1) * this.state.rowsPerPage +
                this.state.rowsPerPage
            )
          : bodyChildren,
      });
    }
  }

  parseTableHeader(currentState) {
    let tableHead = currentState.tableHead.slice();
    let headerChildren;
    let sortFields = [];

    React.Children.map(this.props.children, (child, i) => {
      if (child.key === 'tableHeader' || child.type === 'thead') {
        headerChildren = child.props.children;

        React.Children.map(headerChildren, (th, th_i) => {
          tableHead[th_i] = Object.assign({}, th[th_i] || {}, {
            label: th.props.children,
            align: th.props.className || '',
            nowrap: th.props.nowrap,
          });

          if (th.props.sort) {
            sortFields.push({
              label: th.props.children,
              index: th_i,
              sorttype: th.props.sorttype || 'alpha',
            });
          }
        });
      }
    });

    return Object.assign({}, currentState, {
      sortFields: sortFields,
      tableHead: tableHead,
    });
  }

  parseTableRows(currentState) {
    let bodyChildren = [];

    React.Children.map(this.props.children, (child, i) => {
      if (child.key === 'tableBody' || child.type === 'tbody') {
        bodyChildren = child.props.children;

        let row_clones = [];
        React.Children.map(bodyChildren, (tr, tr_i) => {
          let cell_clones = [];

          React.Children.map(tr.props.children, (td, td_i) => {
            let cell_children = td.props.children;
            let props = {};

            if (currentState.tableHead[td_i].nowrap) {
              props.sortvalue = cell_children;
              cell_children = <TooltipWrapper children={cell_children} />;
            }

            cell_clones[td_i] = React.cloneElement(
              td,
              Object.assign(
                {
                  className: `${currentState.tableHead[td_i].align} ${
                    currentState.tableHead[td_i].nowrap ? 'nowrap' : ''
                  }`,
                  'data-heading': currentState.tableHead[td_i].label,
                },
                props
              ),
              cell_children
            );
          });

          row_clones[tr_i] = React.cloneElement(tr, { key: tr_i }, cell_clones);
        });

        bodyChildren = row_clones;
      }
    });

    return Object.assign({}, currentState, {
      bodyChildren: bodyChildren,
    });
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between align-item-md-center table-per-page">
          {this.state.tableControls}

          {this.state.totalPages ? (
            <div>
              <small>Results Per Page</small>
              <ButtonGroup>
                {this.state.perPageOptions.map((value, i) => {
                  return (
                    <Button
                      key={i}
                      active={this.state.rowsPerPage === value}
                      size="sm"
                      outline
                      onClick={() => {
                        this.onSetRowsPerPage(value);
                      }}
                    >
                      {value}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </div>
          ) : (
            <div></div>
          )}
          {this.state.sortFields.length ? (
            <div className="form-group d-flex align-items-center">
              <SingleSort
                sortFields={this.state.sortFields}
                dataRows={this.state.bodyChildren}
                sortedCallback={this.onSortRows.bind(this)}
              />
            </div>
          ) : null}
        </div>

        <div className="table-scroller">
          <table
            className={`
            table
            ${this.props.lightHeader ? 'light-header' : ''}
            ${this.props.colorHeader ? 'color-header' : ''}
            ${this.props.darkHeader ? 'dark-header' : ''}
            ${this.props.headerSticky ? 'header-sticky' : ''}
            ${this.props.condensed ? 'condensed' : ''}
          `}
          >
            {this.state.tableHead.length ? (
              <thead>
                <tr>
                  {this.state.tableHead.map((head, i) => {
                    return (
                      <th key={i} className={head.align ? head.align : ''}>
                        {head.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            ) : null}

            {this.state.visibleRows && this.state.tableRows.length ? (
              <tbody>
                {this.state.visibleRows.map((row, i) => {
                  return (
                    <tr key={i}>
                      {row.map((cell, i_cell) => {
                        return (
                          <td
                            data-heading={this.state.tableHead[i_cell].label}
                            key={i_cell}
                            className={
                              this.state.tableHead[i_cell].align
                                ? this.state.tableHead[i_cell].align
                                : ''
                            }
                          >
                            {cell}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            ) : null}

            {this.state.visibleRows && !this.state.tableRows.length ? (
              <tbody>{this.state.visibleRows}</tbody>
            ) : null}

            {this.state.tableFoot.length ? (
              <tfoot>
                <tr>
                  {this.state.tableFoot.map((foot, i) => {
                    return (
                      <td
                        data-heading={this.state.tableHead[i].label}
                        key={i}
                        className={
                          this.state.tableHead[i].align
                            ? this.state.tableHead[i].align
                            : ''
                        }
                      >
                        {foot}
                      </td>
                    );
                  })}
                </tr>
              </tfoot>
            ) : null}
          </table>
        </div>

        {this.state.totalPages ? (
          <Pagination
            currentPage={this.state.currentPage}
            totalPages={this.state.totalPages}
            onChange={(pageData) => {
              this.setPage(pageData.index);
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default Table;
