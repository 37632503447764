import React from 'react';
import PropTypes from 'prop-types';

import Nav from '@prism/navigation';

const propTypes = {
  display: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

const defaultProps = {
  display: null,
  className: null,
  id: null,
};

const TabBar = ({ children, display, className: classNameFromProps, id }) => {
  const className = [
    classNameFromProps,
    'tab-bar',
    display ? `tab-display-${display}` : '',
  ]
    .join(' ')
    .trim();

  return (
    <div id={id} className={className}>
      <Nav tabs scroll>
        {children}
      </Nav>
    </div>
  );
};

TabBar.propTypes = propTypes;
TabBar.defaultProps = defaultProps;

export default TabBar;
