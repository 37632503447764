import React from 'react';
import {
  CATEGORIES,
  LEFT_ARROW,
  NEXT,
  NOT_TAB_FOCUSABLE,
  PREVIOUS,
  RIGHT_ARROW,
  TAB_FOCUSABLE,
  VISIBLE_FILMSTRIP_SLIDES,
  FILMSTRIP_THUMBNAIL_WIDTH,
  GRID_THUMBNAIL_WIDTH,
  DEFAULT_CAROUSEL_WIDTH,
} from '../../types/constants';

export const isSelected = (selectedFilmstripSlide, index) => {
  return selectedFilmstripSlide === index ? 'filmstrip-selected' : '';
};

export const textOffSet = (selectedSlide, filmstripCarouselStore, prefix) => {
  if (isSelected) {
    if (selectedSlide === filmstripCarouselStore.getStoreState().currentSlide) {
      return `${prefix}-selected-text-offset-back-button`;
    }
  }
  return '';
};
export const trayProps = (
  updateCurrentSlide,
  filmstripCarouselStore,
  selectedFilmstripSlide
) => ({
  onKeyDown: (e) => {
    if (e.keyCode === LEFT_ARROW) {
      updateCurrentSlide(selectedFilmstripSlide + PREVIOUS);
    } else if (e.keyCode === RIGHT_ARROW) {
      updateCurrentSlide(selectedFilmstripSlide + NEXT);
    }
  },
});

export const getTabIndex = (index, currentSlide) => {
  const slideIsVisible =
    index >= currentSlide &&
    index <= currentSlide + visibleFilmstripSlides() - 1;

  return slideIsVisible ? TAB_FOCUSABLE : NOT_TAB_FOCUSABLE;
};

export const visibleFilmstripSlides = (carouselType) => {
  const filmstripThumbnailTotalWidth =
    carouselType === 'filmstrip'
      ? FILMSTRIP_THUMBNAIL_WIDTH
      : GRID_THUMBNAIL_WIDTH;
  if (typeof document !== 'undefined') {
    const carouselProvider = document.querySelector(
      `.${carouselType}-carousel-provider`
    );
    const filmstripWidth = !!carouselProvider
      ? carouselProvider.clientWidth
      : DEFAULT_CAROUSEL_WIDTH;
    const roundedUpVisibleFilmstripSlides =
      filmstripWidth / filmstripThumbnailTotalWidth;
    return Math.floor(roundedUpVisibleFilmstripSlides);
  }

  return filmstripThumbnailTotalWidth * VISIBLE_FILMSTRIP_SLIDES;
};

export const getTextOverlay = (category) => {
  if (category === CATEGORIES.VIDEO || category === CATEGORIES.AUDIO) {
    return 'PLAYING';
  } else {
    return 'VIEWING';
  }
};
