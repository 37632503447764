import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CNAME = 'table-data-cell';

const propTypes = {
  className: PropTypes.string,
  headerLabel: PropTypes.node,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

const defaultProps = {};

const TableDataCell = ({ className, headerLabel, textAlign, ...props }) => {
  const cName = classNames({
    [CNAME]: !!CNAME,
    [className]: !!className,
    [`text-${textAlign}`]: !!textAlign,
  });

  return <td className={cName} data-heading={headerLabel} {...props} />;
};

TableDataCell.propTypes = propTypes;
TableDataCell.defaultProps = defaultProps;
TableDataCell.displayName = 'TableDataCell';

export default TableDataCell;
