import React from 'react';
import {
  IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS,
  NEXT,
} from '../../types/constants';
import { MultiMediaViewerConsumer } from '@prism/multimediaviewer';
import { ButtonNext } from 'pure-react-carousel';
import { calculateCurrentSlide } from './utils';

const NextButton = React.forwardRef(
  (
    { carouselStore, controlsHidden, multipleMediaAssetsExist, onClick },
    ref
  ) => (
    <div
      className={`imageviewer-next ${
        controlsHidden && IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS
      }`}
      onClick={onClick}
    >
      {multipleMediaAssetsExist && (
        <MultiMediaViewerConsumer>
          {({ updateCurrentSlide, currentSlide }) => (
            <ButtonNext
              className="no-button-styling"
              onClick={() =>
                updateCurrentSlide(calculateCurrentSlide(carouselStore, NEXT))
              }
            >
              <span className="icon prism-icon-chevron-right-light" />
            </ButtonNext>
          )}
        </MultiMediaViewerConsumer>
      )}
    </div>
  )
);

export default NextButton;
