import React, { useState } from 'react';

const AccordionCtx = React.createContext();

const AccordionProvider = (props) => {
  const [openItem, setOpenItem] = useState('');
  const handleCtxOpen = (id) => {
    setOpenItem(id);
  };

  return (
    <AccordionCtx.Provider value={{ openItem, handleCtxOpen }}>
      {props.children}
    </AccordionCtx.Provider>
  );
};

export { AccordionProvider, AccordionCtx };
