import React from 'react';
import PropTypes from 'prop-types';
import { useIsFocusVisible } from '../Utilities/focusVisible';
import {
  clamp,
  percentToValue,
  roundValueToStep,
  valueToPercent,
  hasTypeArrayOfLength2,
  setValueIndex,
  findClosest,
  asc,
} from './utils';
import { useForkRef } from '../Utilities/refs';
import { useEventCallback } from '../Utilities/events';
import classNames from 'classnames';

const propTypes = {
  label: PropTypes.string,
  labelAlignment: PropTypes.string,
  labelUnitOfMeasure: PropTypes.string,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.number, hasTypeArrayOfLength2]),
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  invertedTrack: PropTypes.bool,
};

const defaultTypes = {
  disabled: false,
  labelUnitOfMeasure: '',
  max: 100,
  min: 0,
};

const trackFinger = (event, touchId) => {
  if (touchId.current !== undefined && event.changedTouches) {
    for (let i = 0; i < event.changedTouches.length; i += 1) {
      const touch = event.changedTouches[i];
      if (touch.identifier === touchId.current) {
        return {
          x: touch.clientX,
          y: touch.clientY,
        };
      }
    }

    return false;
  }

  return {
    x: event.clientX,
    y: event.clientY,
  };
};

const focusThumb = ({ sliderRef, activeIndex, setActive }) => {
  if (
    !sliderRef.current.contains(document.activeElement) ||
    Number(document.activeElement.getAttribute('data-index')) !== activeIndex
  ) {
    sliderRef.current.querySelector(`[data-index="${activeIndex}"]`).focus();
  }

  if (setActive) {
    setActive(activeIndex);
  }
};

const Slider = (props) => {
  const {
    onChange,
    onChangeCommitted,
    disabled = false,
    defaultValue,
    max = 100,
    min = 0,
    step = 1,
    labelUnitOfMeasure = '',
    labelAlignment,
    label,
    invertedTrack,
  } = props;

  const axis = 'horizontal';

  const [active, setActive] = React.useState(-1);
  const {
    isFocusVisible,
    onBlurVisible,
    ref: focusVisibleRef,
  } = useIsFocusVisible();
  const [focusVisible, setFocusVisible] = React.useState(-1);
  const [valueState, setValueState] = React.useState(defaultValue);
  const valueDerived = valueState;
  const range = Array.isArray(valueDerived);
  let values = range ? [...valueDerived].sort(asc) : [valueDerived];
  values = values.map((value) => clamp(value, min, max));

  const touchId = React.useRef();
  const instanceRef = React.useRef();
  instanceRef.current = {
    source: valueDerived, // Keep track of the input value to leverage immutable state comparison.
  };
  const previousIndex = React.useRef();
  const sliderRef = React.useRef(null);
  const handleRef = useForkRef(focusVisibleRef, sliderRef);

  const handleFocus = useEventCallback((event) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    if (isFocusVisible(event)) {
      setFocusVisible(index);
    }
  });
  const handleBlur = useEventCallback(() => {
    if (focusVisible !== -1) {
      setFocusVisible(-1);
      onBlurVisible();
    }
  });
  const handleKeyDown = useEventCallback((event) => {
    const index = Number(event.currentTarget.getAttribute('data-index'));
    const value = values[index];
    const tenPercents = (max - min) / 10;

    let newValue;
    switch (event.key) {
      case 'Home':
        newValue = min;
        break;
      case 'End':
        newValue = max;
        break;
      case 'PageUp':
        newValue = value + tenPercents;
        break;
      case 'PageDown':
        newValue = value - tenPercents;
        break;
      case 'ArrowRight':
      case 'ArrowUp':
        newValue = value + step;
        break;
      case 'ArrowLeft':
      case 'ArrowDown':
        newValue = value - step;
        break;
      default:
        return;
    }

    event.preventDefault();

    newValue = roundValueToStep(newValue, step);
    newValue = clamp(newValue, min, max);

    if (range) {
      const previousValue = newValue;
      newValue = setValueIndex({
        values,
        source: valueDerived,
        newValue,
        index,
      }).sort(asc);
      focusThumb({ sliderRef, activeIndex: newValue.indexOf(previousValue) });
    }
    setValueState(newValue);
    setFocusVisible(index);

    if (onChange) {
      onChange(newValue);
    }
    if (onChangeCommitted) {
      onChangeCommitted(newValue);
    }
  });

  const handleTouchStart = useEventCallback((event) => {
    event.preventDefault();
    const touch = event.changedTouches[0];
    if (touch != null) {
      touchId.current = touch.identifier;
    }
    const finger = trackFinger(event, touchId);
    const { newValue, activeIndex } = getFingerNewValue({
      finger,
      values,
      source: valueDerived,
    });
    focusThumb({ sliderRef, activeIndex, setActive });

    setValueState(newValue);

    if (onChange) {
      onChange(newValue);
    }

    document.body.addEventListener('touchmove', handleTouchMove);
    document.body.addEventListener('touchend', handleTouchEnd);
  });
  const handleTouchMove = useEventCallback((event) => {
    const finger = trackFinger(event, touchId);

    if (!finger) {
      return;
    }

    const { newValue, activeIndex } = getFingerNewValue({
      finger,
      isMoving: true,
      values,
      source: valueDerived,
    });

    focusThumb({ sliderRef, activeIndex, setActive });

    setValueState(newValue);

    if (onChange) {
      onChange(newValue);
    }
  });
  const handleTouchEnd = useEventCallback((event) => {
    const finger = trackFinger(event, touchId);
    if (!finger) {
      return;
    }

    const { newValue } = getFingerNewValue({
      finger,
      values,
      source: valueDerived,
    });

    setActive(-1);
    if (event.type === 'touchend') {
      setOpen(-1);
    }

    if (onChangeCommitted) {
      onChangeCommitted(newValue);
    }

    touchId.current = undefined;
    document.body.removeEventListener('mousemove', handleTouchMove);
    document.body.removeEventListener('mouseup', handleTouchEnd);
    // eslint-disable-next-line no-use-before-define
    document.body.removeEventListener('mouseenter', handleMouseEnter);
    document.body.removeEventListener('touchmove', handleTouchMove);
    document.body.removeEventListener('touchend', handleTouchEnd);
  });
  const handleMouseEnter = useEventCallback((event) => {
    // If the slider was being interacted with but the mouse went off the window
    // and then re-entered while unclicked then end the interaction.
    if (event.buttons === 0) {
      handleTouchEnd(event);
    }
  });
  const handleMouseDown = useEventCallback((event) => {
    if (disabled) return;
    event.preventDefault();
    const finger = trackFinger(event, touchId);
    const { newValue, activeIndex } = getFingerNewValue({
      finger,
      values,
      source: valueDerived,
    });
    focusThumb({ sliderRef, activeIndex, setActive });

    if (onChange) {
      onChange(newValue);
    }

    setValueState(newValue);

    document.body.addEventListener('mousemove', handleTouchMove);
    document.body.addEventListener('mouseenter', handleMouseEnter);
    document.body.addEventListener('mouseup', handleTouchEnd);
  });

  React.useEffect(() => {
    const { current: slider } = sliderRef;
    slider.addEventListener('touchstart', handleTouchStart);

    return () => {
      slider.removeEventListener('touchstart', handleTouchStart);
      document.body.removeEventListener('mousemove', handleTouchMove);
      document.body.removeEventListener('mouseup', handleTouchEnd);
      document.body.removeEventListener('mouseenter', handleMouseEnter);
      document.body.removeEventListener('touchmove', handleTouchMove);
      document.body.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleMouseEnter, handleTouchEnd, handleTouchMove, handleTouchStart]);

  const getFingerNewValue = React.useCallback(
    ({ finger, isMoving = false, values: values2, source }) => {
      const { current: slider } = sliderRef;
      const { width, left } = slider.getBoundingClientRect();

      let percent, newValue;
      percent = (finger.x - left) / width;
      newValue = percentToValue(percent, min, max);
      newValue = roundValueToStep(newValue, step);
      newValue = clamp(newValue, min, max);

      let activeIndex = 0;
      if (range) {
        if (!isMoving) {
          activeIndex = findClosest(values2, newValue);
        } else {
          activeIndex = previousIndex.current;
        }

        const previousValue = newValue;
        newValue = setValueIndex({
          values: values2,
          source,
          newValue,
          index: activeIndex,
        }).sort(asc);
        activeIndex = newValue.indexOf(previousValue);
        previousIndex.current = activeIndex;
      }

      return { newValue, activeIndex };
    },
    [max, min, axis, range, step]
  );

  const axisProps = {
    horizontal: {
      offset: (percent) => ({ left: `${percent}%` }),
      leap: (percent) => ({ width: `${percent}%` }),
    },
  };
  const trackOffset = valueToPercent(range ? values[0] : min, min, max);
  const trackLeap =
    valueToPercent(values[values.length - 1], min, max) - trackOffset;
  const trackStyle = {
    ...axisProps['horizontal'].offset(trackOffset),
    ...axisProps['horizontal'].leap(trackLeap),
  };

  return (
    <>
      <div
        className={classNames('slider-labelcontainer', {
          disabled: disabled,
        })}
      >
        <DescriptionLabel labelAlignment={labelAlignment} label={label} />
        <ValueLabel
          range={range}
          values={values}
          labelUnitOfMeasure={labelUnitOfMeasure}
        />
      </div>
      <div
        className={classNames('slider', {
          invertedTrack: invertedTrack && !disabled,
          disabled: disabled,
        })}
        ref={handleRef}
        onMouseDown={(event) => handleMouseDown(event)}
      >
        <span className="slider-rail" />
        <span className="slider-track" style={trackStyle} />
        <input type="hidden" value={values.join(',')} />
        {values.map((value, index) => {
          const percent = valueToPercent(value, min, max);
          const style = axisProps[axis].offset(percent);
          return (
            <span
              key={index}
              className={classNames(`slider-thumb`, {
                active: active === index,
                focused: focusVisible === index,
              })}
              style={style}
              tabIndex={disabled ? null : 0}
              role="slider"
              aria-valuemax={max}
              aria-valuemin={min}
              aria-valuenow={value}
              data-index={index}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          );
        })}
      </div>
    </>
  );
};

Slider.propTypes = propTypes;
Slider.defaultTypes = defaultTypes;

export default Slider;

const ValueLabel = ({ labelUnitOfMeasure, values, range }) => {
  values = values.map((value) => Number(value).toLocaleString());

  const valueLabel = range ? `${values[0]} - ${values[1]}` : `${values[0]}`;

  return (
    <div className={`slider-valuelabel`}>
      <span>
        {valueLabel} {labelUnitOfMeasure}
      </span>
    </div>
  );
};

const DescriptionLabel = ({ label, labelAlignment }) => (
  <div className={`slider-descriptionlabel ${labelAlignment}`}>
    <span>{label}</span>
  </div>
);
