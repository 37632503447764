import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InteractiveInput } from '@prism/input';
import useSearch from './hooks/useSearch';

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  onChange: () => {},
  onClear: () => {},
  disabled: false,
};

function SearchableSearch({ ...props }) {
  const {
    searchValue,
    isSearching,
    setIsSearching,
    handleSearchChange,
    handleBlur,
    handleClear,
    handleFocus,
  } = useSearch();

  const searchClass = classnames(
    isSearching &&
      'icon prism-icon-cancel-circle searchable-search__input-clear'
  );
  let { onChange, onClear, ...attributes } = props;

  function handleChange(e) {
    handleSearchChange && handleSearchChange(e);
    onChange && onChange(e);
  }
  function handleClearClick(e) {
    handleClear && handleClear(e);
    onClear && onClear(e);
  }
  return (
    <div className="searchable-search ">
      <InteractiveInput
        placeholder="Search the list below"
        className="searchable-search__input"
        onClickRight={handleClearClick}
        onChange={handleChange}
        onFocus={handleFocus}
        iconRight={searchClass}
        iconAfter="prism-icon-search icon"
        value={searchValue}
        {...attributes}
      />
    </div>
  );
}
SearchableSearch.propTypes = propTypes;
SearchableSearch.defaultProps = defaultProps;

export default SearchableSearch;
