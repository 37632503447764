import React, { useRef, useState, useEffect } from 'react';
import { bool, array, oneOf } from 'prop-types';
import {
  handleExitFullScreenClick,
  handleFullScreenClick,
  handleZoomInClick,
  handleZoomOutClick,
  handleFyuseIdsAnd,
  getCategoryCounts,
  normalizeAsset,
} from './utils';
import { MediaAssetsType } from '../../types/index';
import ImageViewer from '@prism/imageviewer';
import { FilmstripWithCarousel } from '@prism/filmstrip';
import { ImageGridWithCarousel } from '@prism/filmstrip';
import Filters from './Filters';
import FullScreen from './FullScreen';
import { MultimediaViewerProvider } from './MultimediaViewerCtx';
import { IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS } from '../../types/constants';
import classNames from 'classnames';

const MultimediaViewer = ({
  mediaAssets,
  disableFilters,
  hideAddOns,
  hideFilters,
  showGrid,
  customButtons,
  showFilters,
  showFullScreenControls,
}) => {
  const [unfilteredAssets, setUnfilteredAssets] = useState(
    mediaAssets.map(normalizeAsset)
  );
  const [filteredAssets, setFilteredAssets] = useState(unfilteredAssets);
  const [activeFilter, setActiveFilter] = useState('ALL');

  useEffect(() => {
    handleFyuseIdsAnd(setUnfilteredAssets, mediaAssets, setFilteredAssets);
  }, [mediaAssets]);

  const [fullScreenHidden, setFullScreenHidden] = useState(true);
  const multiMediaViewerRef = useRef();

  const constructedFilters = (props) => {
    return <Filters {...props} />;
  };

  const filterMediaAssets = (category) => {
    const originalAssets =
      unfilteredAssets.length === 0 ? mediaAssets : unfilteredAssets;
    return category === 'ALL'
      ? setFilteredAssets(originalAssets)
      : setFilteredAssets(
          unfilteredAssets.filter((asset) => asset.category === category)
        );
  };

  const filtersCommonProps = {
    filterMediaAssets,
    disabled: disableFilters,
    hidden: hideFilters,
    activeFilter,
    showFilters,
    setActiveFilter,
    categoryCounts: getCategoryCounts(unfilteredAssets),
  };

  const addOnClassNames = classNames({
    'multimediaviewer-add-ons': true,
    'hide-add-ons': hideAddOns,
  });

  const fullScreenClassNames = classNames({
    'imageviewer-fullscreen': true,
    [`${IMAGE_VIEWER_CONTROLS_HIDDEN_CLASS}`]: fullScreenHidden,
  });

  const multiMediaClassNames = classNames({
    multimediaviewer: true,
    'left-mounted-filters': showFilters === 'left',
  });

  const multiMediaContainerClassNames = classNames({
    'multimediaviewer-main-container': true,
    'multimediaviewer-width-66': showFilters === 'left',
  });

  const wrapperClassNames = classNames({
    'multimediaviewer-wrapper': true,
    fullscreen: !fullScreenHidden,
  });

  return (
    <div ref={multiMediaViewerRef} className={wrapperClassNames}>
      <MultimediaViewerProvider
        carouselType={showGrid ? 'image-grid' : 'filmstrip'}
      >
        <div className={multiMediaClassNames}>
          {showFilters === 'left' && (
            <div className="left-mounted-filters-container">
              {constructedFilters({ ...filtersCommonProps, showFilters })}
            </div>
          )}
          <div className={multiMediaContainerClassNames}>
            <ImageViewer
              mediaAssets={filteredAssets}
              handleFullScreenClick={handleFullScreenClick(
                multiMediaViewerRef,
                setFullScreenHidden
              )}
              handleExitFullScreenClick={handleExitFullScreenClick(
                multiMediaViewerRef,
                setFullScreenHidden
              )}
              handleZoomInClick={() => {
                handleZoomInClick(multiMediaViewerRef);
              }}
              handleZoomOutClick={() => {
                handleZoomOutClick(multiMediaViewerRef);
              }}
              fullScreenHidden={fullScreenHidden}
              customButtons={customButtons}
              showFullScreenControls={showFullScreenControls}
            />
            <div className={addOnClassNames}>
              {showGrid ? (
                <ImageGridWithCarousel mediaAssets={filteredAssets} />
              ) : (
                <FilmstripWithCarousel mediaAssets={filteredAssets} />
              )}
              {showFilters && constructedFilters({ ...filtersCommonProps })}
            </div>
            <FullScreen className={fullScreenClassNames} />
          </div>
        </div>
      </MultimediaViewerProvider>
    </div>
  );
};

const defaultProps = {
  mediaAssets: [],
  hideAddOns: false,
  hideFilters: false,
  showGrid: false,
  customButtons: [],
  showFilters: true,
  showFullScreenControls: true,
};

MultimediaViewer.propTypes = {
  mediaAssets: MediaAssetsType,
  hideAddOns: bool,
  hideFilters: bool,
  showGrid: bool,
  customButtons: array,
  showFilters: oneOf([true, false, 'left']),
  showFullScreenControls: bool,
};

MultimediaViewer.defaultProps = defaultProps;

export default MultimediaViewer;
