import React from 'react';
import PropTypes from 'prop-types';
const propTypes = {
  classNamePrefix: PropTypes.string,
  text: PropTypes.string,
};
const defaultProps = {
  text: '',
};

const LoadingScreen = (props) => (
  <div className={`${props.classNamePrefix}-loading`}>
    <div className="icon prism-icon-spinner"></div>
    <div className={`${props.classNamePrefix}-loading-text`}>{props.text}</div>
  </div>
);

LoadingScreen.propTypes = propTypes;
LoadingScreen.defaultProps = defaultProps;
export default LoadingScreen;
