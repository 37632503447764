import React from 'react';
import PropTypes from 'prop-types';
import Button from '@prism/button';

const propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  className: '',
};

const MediaViewerButton = (props) => {
  const { icon, onClick, className, currentSlide, index } = props;

  const handleClick = (e) => {
    if (!onClick) {
      return;
    }
    e.data = Object.assign({}, currentSlide, { index });
    onClick(e);
  };

  return (
    <Button
      icononly
      textonly
      {...props}
      className={`btn-media ${className}`}
      onClick={handleClick}
    >
      <i className={`icon prism-icon-${icon}`} />
    </Button>
  );
};

MediaViewerButton.propTypes = propTypes;
MediaViewerButton.defaultProps = defaultProps;

export default MediaViewerButton;
