import React from 'react';
import PropTypes from 'prop-types';
import PageSize from './PageSize';
import SingleSort from './SingleSort';

const CNAME = 'table-top';

const propTypes = {
  singleSort: PropTypes.bool,
  pagination: PropTypes.shape({
    label: PropTypes.string,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  setSortBy: PropTypes.func,
  setPageSize: PropTypes.func,
};

const defaultProps = {
  singleSort: false,
};

const TableTop = ({
  singleSort,
  pagination,
  columns,
  sortBy,
  setSortBy,
  setPageSize,
}) => {
  if (!singleSort && !pagination) {
    return null;
  }

  return (
    <div className={CNAME}>
      {pagination ? (
        <PageSize {...pagination} setPageSize={setPageSize} />
      ) : null}
      <span style={{ flexGrow: 2 }} />
      {singleSort ? (
        <SingleSort columns={columns} sortBy={sortBy} setSortBy={setSortBy} />
      ) : null}
    </div>
  );
};

TableTop.propTypes = propTypes;
TableTop.defaultProps = defaultProps;
TableTop.displayName = 'TableTop';

export default TableTop;
