import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Checkbox from '@prism/checkbox';
import Chip from '@prism/chip';
import useSearch from './hooks/useSearch';
import useListState from './hooks/useListState';

const propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  heading: PropTypes.string,
  icon: PropTypes.string,
  selectAll: PropTypes.bool,
  disabled: PropTypes.bool,
  chipColor: PropTypes.oneOf([
    Chip.COLOR_LIGHT,
    Chip.COLOR_MEDIUM,
    Chip.COLOR_DARK,
  ]),
};

const defaultProps = {
  onChange: () => {},
  data: [],
  heading: null,
  icon: null,
  selectAll: null,
  disabled: false,
  chipColor: Chip.COLOR_MEDIUM,
};

function SearchableList({
  data,
  selectAll,
  heading,
  icon,
  onChange,
  disabled,
  chipColor,
  ...attributes
}) {
  const { searchValue, isSearching } = useSearch();
  const {
    listItems,
    setSelectAll,
    handleCheckChange,
    onMount,
    setListItems,
  } = useListState(data);

  const dataString = JSON.stringify(data);

  useEffect(() => {
    setListItems(data);
  }, [dataString]);

  const filteredData = () => {
    const sV = searchValue.toLowerCase();
    const filtered = listItems.filter(
      (item) => item.label.toLowerCase().indexOf(sV) > -1
    );
    return filtered;
  };

  useEffect(() => {
    selectAll && setSelectAll();
  }, [selectAll]);

  function handleChange(e) {
    onChange && onChange(e);
    handleCheckChange && handleCheckChange(e);
  }

  const listWithSearch =
    isSearching && searchValue.length > 0 ? filteredData() : listItems;
  return (
    <div className="searchable-list__container">
      <div className="searchable-list__heading">
        {icon && !searchValue.length > 0 && (
          <span
            className={`searchable-list__heading-icon icon prism-icon-${icon}`}
          />
        )}
        {heading && !searchValue.length > 0 && heading}
      </div>
      <div className="check-list">
        {onMount &&
          listWithSearch.map((item, i) => (
            <div key={uuid()} className="check-list__item">
              <Checkbox
                disabled={disabled || item.disabled}
                checked={item.checked || false}
                name={item.name || item.label || ''}
                count={item.count}
                label={item.label || item.name || ''}
                value={item.value || ''}
                id={item.id || uuid()}
                onChange={handleChange}
                className="check-list__item"
              />
              {item.count && item.count > 0 && (
                <Chip color={chipColor} className="filter-count">
                  {item.count}
                </Chip>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
SearchableList.propTypes = propTypes;
SearchableList.defaultProps = defaultProps;

export default SearchableList;
