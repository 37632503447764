import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const propTypes = {
  isOpen: PropTypes.bool,
  placement: PropTypes.string,
};

const defaultProps = {
  isOpen: false,
  placement: 'left',
};

function SlideOutWrapper({ isOpen, placement, children, headerText }) {
  return (
    <CSSTransition
      in={isOpen}
      appear={true}
      timeout={300}
      classNames="slide-out-wrapper"
      unmountOnExit={true}
    >
      <div className="slide-out-wrapper" />
    </CSSTransition>
  );
}

SlideOutWrapper.propTypes;
SlideOutWrapper.defaultProps;

export default SlideOutWrapper;
