import React from 'react';
import PropTypes from 'prop-types';

import Anchor from '@prism/anchor';

const COMPONENT_CNAME = 'avatar';

const propTypes = {
  size: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  initials: PropTypes.string,
  image: PropTypes.string,
  href: PropTypes.string,
  inlineText: PropTypes.string,
};

const defaultProps = {
  firstName: null,
  lastName: null,
  name: null,
  initials: null,
  image: null,
  href: null,
  size: 'sm',
  inlineText: null,
};

const getName = ({ firstName, lastName, name }) => {
  return name || `${firstName} ${lastName}`.trim();
};

const getInitials = ({ initials, firstName, lastName, name }) => {
  if (initials) {
    return initials;
  }

  if (firstName || lastName) {
    return `${(firstName || '').substr(0, 1)}${(lastName || '').substr(
      0,
      1
    )}`.trim();
  }

  let words = (name || '').split(' ');

  return words
    .map((word) => word.substr(0, 1))
    .filter((letter) => letter.length && letter.length === 1)
    .join('');
};

const Avatar = ({
  size,
  firstName,
  lastName,
  name,
  initials,
  image,
  href,
  target,
  inlineText,
}) => {
  let className = `${COMPONENT_CNAME}`;
  const CONTENTS_CNAME = `${COMPONENT_CNAME}-contents`;

  const titleName = getName({ firstName, lastName, name });
  const letters = getInitials({ initials, firstName, lastName, name });
  let contents = null;

  if (image) {
    className += ` ${COMPONENT_CNAME}-image`;
    className += ' avatar-' + size;
    contents = (
      <span className={CONTENTS_CNAME}>
        <img src={image} alt={titleName} />
      </span>
    );
  } else if (letters) {
    className += ` ${COMPONENT_CNAME}-initials`;
    className += ' avatar-' + size;
    contents = <span className={CONTENTS_CNAME}>{letters}</span>;
  } else if (className === COMPONENT_CNAME) {
    className += ` ${COMPONENT_CNAME}-unidentified`;
    className += ' avatar-' + size;
    contents = (
      <span className={CONTENTS_CNAME}>
        <i className="icon prism-icon-user" />
      </span>
    );
  }

  const avatarContentContainer = <span className={className}>{contents}</span>;

  const avatarContentInlineText = (
    <span className={`${COMPONENT_CNAME}-inline-text`}>{inlineText}</span>
  );

  return href ? (
    <Anchor
      title="View account information"
      target={target}
      href={href}
      size={size}
    >
      {avatarContentContainer}
      {inlineText && avatarContentInlineText}
    </Anchor>
  ) : (
    <div>
      {avatarContentContainer}
      {inlineText && avatarContentInlineText}
    </div>
  );
};

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

Avatar.COMPONENT_CNAME = COMPONENT_CNAME;

export default Avatar;
