import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from '@prism/collapse';
import AccordionTrigger from './AccordionTrigger';
import { SelectableContext } from './context/SelectableContext';
import { AccordionCtx } from './AccordionCtx';

const propTypes = {
  isOpen: PropTypes.bool,
  id: PropTypes.string,
  triggerTag: PropTypes.string,
  triggerClassName: PropTypes.string,
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
};

const defaultProps = {
  isOpen: true,
  triggerTag: 'div',
};

function AccordionItem({
  children,
  triggerTag,
  triggerClassName,
  defaultOpen,
  title,
  variant,
  onClear,
  clearable,
  id,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const { hasSelected, toggleSelected } = useContext(SelectableContext) || {};

  const { openItem, handleCtxOpen } = useContext(AccordionCtx) || {};

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  let itemWrapperClass = classNames(
    'accordion-item__wrapper',
    variant == 'filter' && 'accordion-item__wrapper--filter'
  );

  const handleClear = (e) => {
    onClear && onClear(e);
    toggleSelected(false);
  };

  const handleDefault = () => {
    variant !== 'filter' && !openItem && defaultOpen && handleCtxOpen(id);
  };

  const handleCtxChange = () => {
    variant !== 'filter' && setIsOpen(id === openItem);
  };

  useEffect(() => {
    handleDefault(defaultOpen);
    handleCtxChange(openItem);
  }, [openItem, defaultOpen]);
  return (
    <div className={itemWrapperClass}>
      <AccordionTrigger
        tag={triggerTag}
        title={title}
        className={triggerClassName || ''}
        toggle={toggle}
        defaultOpen={isOpen}
        variant={variant}
        onClear={handleClear}
        clearable={hasSelected || clearable}
        id={id && id}
      />
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
}

AccordionItem.propTypes = propTypes;
AccordionItem.defaultProps = defaultProps;

export default AccordionItem;
