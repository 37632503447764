import React, { useState, useEffect } from 'react';

const SearchableContext = React.createContext([{}, () => {}]);

const INITIAL_STATE = {
  value: '',
  isSearching: false,
};
const SearchableProvider = (props) => {
  const [search, setSearch] = useState(INITIAL_STATE);

  return (
    <SearchableContext.Provider value={[search, setSearch]}>
      {props.children}
    </SearchableContext.Provider>
  );
};

export { SearchableProvider, SearchableContext };
