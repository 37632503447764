import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  CarouselProvider,
  Slide,
  Slider,
  WithStore,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { MediaAssetsType } from '../../types';
import { MultiMediaViewerConsumer } from '@prism/multimediaviewer';
import { VISIBLE_FILMSTRIP_SLIDES, FILMSTRIP } from '../../types/constants';
import NextButton from './NextButton';
import BackButton from './BackButton';
import Thumbnail from './Thumbnail';
import { getTabIndex, trayProps } from './utils';

const propTypes = {
  mediaAssets: MediaAssetsType,
};

const defaultProps = {
  mediaAssets: [],
};

const Filmstrip = React.forwardRef((props, ref) => {
  const { disabled, mediaAssets: propsMediaAssets } = props;
  const mediaAssets = propsMediaAssets.map((asset) => ({
    ...asset,
    id: asset.id || uuid(),
  }));
  const numberOfAssets = mediaAssets.length;
  const filmstripCarouselStore = props.carouselStore;

  const AvailablePhotos = ({
    updateCurrentSlide,
    testLoad,
    selectedFilmstripSlide,
  }) =>
    !!numberOfAssets && (
      <Slider
        className="filmstrip-slider"
        ref={ref}
        onLoad={testLoad({ filmstripCarouselStore })}
        disableKeyboard
        trayProps={trayProps(
          updateCurrentSlide,
          filmstripCarouselStore,
          selectedFilmstripSlide
        )}
      >
        {mediaAssets.map((mediaAsset, index) => (
          <Slide
            className="filmstrip-slide"
            index={index}
            key={`${mediaAsset.id}-flmsp-${index}`}
            tabIndex={getTabIndex(
              index,
              filmstripCarouselStore.getStoreState().currentSlide
            )}
          >
            <Thumbnail
              {...{
                updateCurrentSlide,
                testLoad,
                selectedFilmstripSlide,
                mediaAsset,
                disabled,
                filmstripCarouselStore,
                index,
              }}
              prefix={FILMSTRIP}
            />
          </Slide>
        ))}
      </Slider>
    );

  return (
    <div className="filmstrip">
      <MultiMediaViewerConsumer>
        {({ updateCurrentSlide, testLoad, selectedFilmstripSlide }) => (
          <>
            <BackButton
              disabled={disabled}
              {...{ updateCurrentSlide, selectedFilmstripSlide }}
            />
            <AvailablePhotos
              {...{ updateCurrentSlide, testLoad, selectedFilmstripSlide }}
            />
            <NextButton
              disabled={disabled}
              {...{ updateCurrentSlide, selectedFilmstripSlide }}
            />
          </>
        )}
      </MultiMediaViewerConsumer>
    </div>
  );
});

const FilmstripWithCarousel = React.forwardRef((props, ref) => {
  const mapCarouselStateToProps = (mediaAssets) => ({
    mediaAssets,
    currentSlide: 0,
    ...props,
  });

  const numberOfAssets = props.mediaAssets.length;

  const FilmstripWithCarouselState = WithStore(
    Filmstrip,
    mapCarouselStateToProps
  );

  return (
    <CarouselProvider
      className="filmstrip-carousel-provider"
      naturalSlideWidth={102}
      naturalSlideHeight={72}
      visibleSlides={VISIBLE_FILMSTRIP_SLIDES}
      totalSlides={numberOfAssets}
      infinite="true"
      disableKeyboard={true}
    >
      <FilmstripWithCarouselState />
    </CarouselProvider>
  );
});

FilmstripWithCarousel.propTypes = propTypes;
FilmstripWithCarousel.defaultProps = defaultProps;
export default FilmstripWithCarousel;
