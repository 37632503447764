import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import classnames from 'classnames';
import { FormGroup, FormFeedback } from '@prism/form';
import Icon from '@prism/icon';
import { Label } from '@prism/input'
import InputGroup from '@prism/inputgroup';
import Header from './Header';
import { UNDEFINED } from '../CONSTANTS';
import { toDate, constrainDate, normalizeDate } from '../utils'

const CNAME = 'date-picker';
const SIZE_SM = 'sm';
const SIZE_LG = 'lg';

const propTypes = {
  afterClickOutside: PropTypes.func,
  afterCalendarClose: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf([SIZE_SM, SIZE_LG]),
  triggerClassName: PropTypes.string,
  valid: PropTypes.bool,
};

const defaultProps = {
  afterClickOutside: null,
  afterCalendarClose: null,
  defaultValue: UNDEFINED,
  disabled: false,
  invalid: false,
  minDate: new Date(null),
  maxDate: new Date('12/31/9999'),
  placeholder: 'mm/dd/yyyy',
  size: SIZE_LG,
  valid: false,
};

const DatePicker = ({
  className: propClassName,
  afterCalendarClose,
  afterClickOutside,
  defaultValue: propDefaultValue,
  disabled,
  inputClassName: propInputClassName,
  invalid,
  label,
  minDate: propMinDate,
  maxDate: propMaxDate,
  onChange,
  placeholder,
  size,
  triggerClassName: propTriggerClassName,
  valid,
  ...attrs
}) => {
  const minDate = toDate(propMinDate)
  const maxDate = toDate(propMaxDate)
  const dateValue = constrainDate(toDate(propDefaultValue), {
    min: minDate,
    max: maxDate
  })

  const [showDate, setShowDate] = useState(!!propDefaultValue);
  const [isMonthPicker, setIsMonthPicker] = useState(false);
  const [lastSelectedDate, setLastSelectedDate] = useState(dateValue);
  const [selectedDate, setSelectedDate] = useState(dateValue);

  const minSelectableDate = minDate;
  const tempMinDate = new Date(minDate);
  const minSelectableMonth = new Date(tempMinDate.setDate(1));
  const datePickerRef = useRef(null);

  const hasMessage = typeof invalid === 'string';

  useEffect(() => {
    if (dateValue !== selectedDate) {
      setSelectedDate(dateValue);
    }
  }, [propDefaultValue]);

  const handleClickOutside = () => {
    setIsMonthPicker(false);
    if (afterClickOutside) {
      afterClickOutside();
    }
  };

  const handleCalendarClose = () => {
    setIsMonthPicker(false);
    if (afterCalendarClose) {
      afterCalendarClose();
    }
  };

  const handleDateChange = (date) => {
    const newDate = normalizeDate({
      date,
      isMonthPicker,
      lastSelectedDate,
      minSelectableDate,
    });

    if (!date || !newDate) {
      setSelectedDate(null);
      setShowDate(false);
      return;
    }

    setIsMonthPicker(false);
    setLastSelectedDate(newDate);
    setSelectedDate(newDate);
    setShowDate(true);

    const { activeElement } = document;

    if (!activeElement || !activeElement.classList.contains(CNAME + '-input')) {
      focusOnSelectedDay();
    }

    if (onChange) {
      onChange(new Date(newDate));
    }
  };

  const showMonthPicker = () => {
    setIsMonthPicker(true);
  };

  const focusOnSelectedDay = () => {
    const cname = '.react-datepicker__day--selected';
    requestAnimationFrame && requestAnimationFrame(() => {
      const { componentNode } =
        (datePickerRef.current && datePickerRef.current.calendar) || {};
      const selectedDay = componentNode && componentNode.querySelector(cname);
      selectedDay ? selectedDay.focus() : datePickerRef.current?.setFocus();
    });
  };

  const handleClickIcon = (e) => {
    e.preventDefault();
    openDatePicker();
    focusOnSelectedDay();
  };

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    decreaseYear,
    increaseMonth,
    increaseYear,
  }) => (
    <Header
      selectedDate={date}
      isMonthPicker={isMonthPicker}
      decreaseYear={decreaseYear}
      increaseYear={increaseYear}
      decreaseMonth={decreaseMonth}
      increaseMonth={increaseMonth}
      showMonthPicker={showMonthPicker}
    />
  );

  const className = classnames({
    [CNAME]: true,
    picker: true,
    [`picker-${size}`]: size === SIZE_SM,
    [propClassName]: propClassName,
    'has-message': !!hasMessage,
    'is-valid': !!valid,
    'is-invalid': !!invalid,
  })

  const inputClassName = classnames({
    [`${CNAME}-input`]: true,
    'form-control': true,
    'inset-right': true,
    [`form-control-${size}`]: size === SIZE_SM,
    [propInputClassName]: propInputClassName,
    'is-valid': valid,
    'is-invalid': invalid,
    'has-validation': !!hasMessage,
  });

  const triggerClassName = classnames({
    [`${CNAME}-trigger`]: true,
    btn: true,
    'input-group-action': true,
    [`btn-${size}`]: size === SIZE_SM,
    [propTriggerClassName]: propTriggerClassName,
  })

  return (
    <>
      <FormGroup className={className}>
        {label ? <Label>{label}</Label>: null}
        <InputGroup>
          <ReactDatePicker
            {...attrs}
            className={inputClassName}
            disabled={disabled}
            formatWeekDay={(dayOfWeekLabel) => dayOfWeekLabel.substring(0, 1)}
            maxDate={maxDate}
            minDate={isMonthPicker ? minSelectableMonth : minSelectableDate}
            onClickOutside={handleClickOutside}
            onCalendarClose={handleCalendarClose}
            onChange={handleDateChange}
            placeholderText={placeholder}
            ref={datePickerRef}
            renderCustomHeader={renderCustomHeader}
            selected={showDate ? selectedDate : null}
            shouldCloseOnSelect={!isMonthPicker}
            showMonthYearPicker={isMonthPicker}
          />
          <button
            className={triggerClassName}
            onClick={handleClickIcon}
            disabled={disabled}
          >
            <Icon glyph="calendar" />
          </button>
          { invalid && hasMessage ? (
            <FormFeedback>
              <Icon glyph="notification-circle" className="small mr-1" />
              { invalid }
            </FormFeedback>
          ) : null}
        </InputGroup>
      </FormGroup>
    </>
  );
};

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;
DatePicker.displayName = 'DatePicker';

export default DatePicker;
