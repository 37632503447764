import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@prism/icon';

export const CNAME = 'table--sort-icon';

const propTypes = {
  isSorted: PropTypes.bool,
  isSortedDesc: PropTypes.bool,
};

const defaultProps = {
  isSorted: false,
  isSortedDesc: false,
};

const SortIcon = ({ isSorted, isSortedDesc }) => {
  const dir = !isSorted ? 0 : isSortedDesc ? -1 : 1;

  const glyph =
    dir === 0
      ? 'arrows-up-down' // off
      : dir === 1
      ? 'arrow-up' // asc
      : 'arrow-down'; // desc

  const className = classNames({
    [CNAME]: CNAME,
    [`${CNAME}--active`]: dir !== 0,
    [`${CNAME}--asc`]: dir === 1,
    [`${CNAME}--desc`]: dir === -1,
  });

  return (
    <span className={className}>
      <Icon glyph={glyph} />
    </span>
  );
};

SortIcon.propTypes = propTypes;
SortIcon.defaultProps = defaultProps;
SortIcon.displayName = 'SortIcon';

export default SortIcon;
