import React from 'react';
import PropTypes from 'prop-types';
import { IconSvg } from '@prism/icon';

const propTypes = Object.freeze({
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const defaultProps = Object.freeze({
  color: '#000000',
  size: 'md',
});

const knownValues = Object.freeze({
  xs: 15,
  sm: 19,
  md: 25,
  lg: 33,
  xl: 47,
  xxl: 47,
});

export const oddifyAndStringifySize = (s) => {
  if (!isNaN(+s)) {
    // this is necessary to find a center point of the spinner.
    return s % 2 === 1 ? `${s}px` : `${s - 1}px`;
  } else {
    return '25px';
  }
};

export const checkKnownValues = (s) => {
  let sLower = s.toString().toLowerCase();
  return knownValues[sLower] || parseInt(s);
};

export const processSize = (s) => {
  return typeof s === 'number'
    ? oddifyAndStringifySize(s)
    : oddifyAndStringifySize(checkKnownValues(s));
};

export const Spinner = ({ color, size }) => {
  return (
    <span className="spinner-wrapper">
      <IconSvg
        className="spinner"
        glyph="spinner"
        color={color}
        height={processSize(size)}
      />
    </span>
  );
};

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
