import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  href: PropTypes.string,
  toggle: PropTypes.func,
  open: PropTypes.bool,
};

const defaultProps = {
  className: 'btn btn-secondary',
};

function SlideOutTrigger({
  className,
  tag: Tag,
  children,
  onClick,
  text,
  href,
}) {
  return (
    <Tag className={className} href={href} onClick={onClick}>
      {text}
    </Tag>
  );
}

SlideOutTrigger.defaultProps = defaultProps;
SlideOutTrigger.propTypes = propTypes;

export default SlideOutTrigger;
