import { useEffect, useRef, useState } from 'react';

import {
  nextFrame,
  sanitize,
  bindOutsideClick,
  unbindOutsideClick,
} from './utils';

import { MORE_LABEL, SHOW_ALL } from './CONSTANTS';

export const initialState = {
  action: null,
  actions: [],
  iconOnly: false,
  menuLabel: MORE_LABEL,
  remainingActions: [],
  show: 2,
  showPanel: false,
  textOnly: false,
  visibleActions: [],
};

const toActionOrSpacer = (action) => {
  if (
    action.spacer ||
    (action.type &&
      action.type.name &&
      action.type.name.toString() === 'Spacer')
  ) {
    return { spacer: true };
  } else if (action.type) {
    return {
      ...(action.props || {}),
    };
  } else if (action.label) {
    return { ...action };
  } else {
    null;
  }
};

const useActions = (defaultState = {}) => {
  const panelRef = useRef(null);
  const [state, setState] = useState(sanitize(defaultState, initialState));
  const [allActions, setAllActions] = useState([]);
  const [showPanel, setShowPanel] = useState(state.showPanel || false);

  const handleOutsideClick = (e) => {
    setShowPanel(false);
  };

  useEffect(() => {
    if (showPanel) {
      nextFrame(() => {
        if (panelRef.current) {
          panelRef.current.focus();
        }
        bindOutsideClick(handleOutsideClick);
      });
    } else {
      unbindOutsideClick(handleOutsideClick);
    }

    return () => {
      unbindOutsideClick(handleOutsideClick);
    };
  }, [showPanel]);

  /**
   * Return the number of visible items that will be shown
   * @param {number} actionSize total number of actions to be counted
   * @returns the number of items to be shown
   */
  const getShowCount = (actionSize) => {
    const { show } = state;
    const maxShowCount =
      typeof actionSize !== 'undefined'
        ? actionSize
        : (allActions || []).length;

    if (show === SHOW_ALL) {
      return maxShowCount;
    } else if (typeof show === 'number') {
      return Math.min(Math.max(0, show), maxShowCount);
    }

    return 0;
  };

  /**
   * Splits the provided actions array into two arrays based on the size
   * provided ignoring Action spacers in the process.
   * @param {array} actions array of actions to split
   * @param {number} size max size of the visible array
   * @returns array of visible and hidden actions
   */
  const splitActions = (actions = [], size = 0) => {
    return actions.reduce(
      (acc, action) => {
        if (acc[0].length < size) {
          if (!action.spacer) {
            acc[0].push(action);
          }
        } else {
          if (acc[1].length || !action.spacer) {
            acc[1].push(action);
          }
        }

        return acc;
      },
      [
        [
          /*visible*/
        ],
        [
          /*hidden*/
        ],
      ]
    );
  };

  const setActions = (actions = []) => {
    const allActions = actions.map(toActionOrSpacer).filter((a) => !!a);
    const show = getShowCount(allActions.length);

    const [visible, remaining] = splitActions(allActions, show);

    setState({
      ...state,
      visibleActions: visible,
      remainingActions: remaining,
    });

    setAllActions(allActions);
  };

  const getActionState = (actionIndex) => {
    const show = getShowCount(allActions.length);

    if (actionIndex < 0) {
      return {};
    }

    const props = allActions[actionIndex] || {};
    const blendedState = Object.assign({}, state, props);

    const actionState = sanitize(
      blendedState,
      {
        addOnAppend: 0,
        addOnPrepend: 0,
        className: 0,
        iconOnly: 0,
        inline: 0,
        label: 0,
        onClick: 0,
        textOnly: 0,
      },
      {}
    );

    return Object.assign(
      {},
      actionState,
      actionIndex < show ? {} : { iconOnly: false, textOnly: false }
    );
  };

  return [
    {
      ...state,
      show: getShowCount(),
      panelRef,
      showPanel,
    },
    {
      getActionState,
      setActions,
      setShowPanel,
    },
  ];
};

export default useActions;
