import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@prism/pagination';

const CNAME = 'table-bottom';

const propTypes = {
  pagination: PropTypes.shape({
    pageIndex: PropTypes.number,
    pageCount: PropTypes.number,
  }),
  gotoPage: PropTypes.func,
};

const defaultProps = {};

const TableBottom = ({ pagination, gotoPage }) => {
  if (!pagination || pagination.pageCount < 2) {
    return null;
  }

  const { pageIndex = 0, pageCount } = pagination;

  const handlePaginationChange = (pageData) => {
    if (gotoPage) {
      gotoPage(pageData.index - 1 || 0);
    }
  };

  const Spacer = () => <span style={{ flexGrow: 1, height: 0 }} />;

  return (
    <div className={CNAME}>
      <Spacer />
      <Pagination
        currentPage={pageIndex + 1}
        totalPages={pageCount}
        onChange={handlePaginationChange}
      />
      <Spacer />
    </div>
  );
};

TableBottom.propTypes = propTypes;
TableBottom.defaultProps = defaultProps;
TableBottom.displayName = 'TableBottom';

export default TableBottom;
